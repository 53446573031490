<template>
    <div
        class="gst-add-hotel-reservation-hotel-list-card d-flex flex-row px-2 py-4 pa-md-0"
        :class="{
            'gst-add-hotel-reservation-hotel-list-card--highlighted': isHighlighted && $vuetify.breakpoint.mdAndUp
        }"
        tabindex="0"
        @click="openItemDetails()"
        @keypress.enter="openItemDetails()"
        @mouseenter="onItemMouseEnterDo()"
        @mouseleave="onItemMouseLeaveDo()">
        <div
            v-if="isDesktopOrUniformLayout"
            :style="{width: $vuetify.breakpoint.mdAndUp? '100px' : '80px' }"
            class="align-self-stretch u-position-relative">
            <BaseImage
                :src="image.src"
                :fallback-src="image.fallBack"
                class="u-position-absolute"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                height="100%"
                width="100%" />
        </div>
        <div class="py-md-4" :style="{ flex: 1 }">
            <div class="d-flex flex-row">
                <div v-if="!isDesktopOrUniformLayout" class="flex-shrink-1">
                    <BaseImage :src="image.src" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="56" width="100" />
                </div>
                <div class="d-flex flex-column justify-center pl-2 px-md-2">
                    <div class="gst-add-hotel-reservation-hotel-list-card__rating-wrapper">
                        <StarRating :key="stars" :value="stars" class="gst-add-hotel-reservation-hotel-list-card__rating" />
                        <VClamp v-if="isDesktopOrUniformLayout && !isCityCentric" autoresize :max-lines="2" :title="distance" tag="span" class="gst-add-hotel-reservation-hotel-list-card__distance pl-1">
                            • {{ distance }}
                        </VClamp>
                    </div>
                    <div>
                        <VClamp autoresize :max-lines="1" :title="item.name" tag="h6" class="gst-add-hotel-reservation-hotel-list-card__room">
                            {{ item.name }}
                        </VClamp>
                    </div>
                    <div
                        v-if="showGiftCard || showDiscountAmount"
                        class="gst-add-hotel-reservation-hotel-list-card__gifts d-flex flex-wrap mt-2">
                        <SaveAmountCardChip v-if="showDiscountAmount" :amount="savings" :currency="currency" />
                        <GiftCardChip v-if="showGiftCard"
                            :amount="maxGiftCard ? maxGiftCard.amount : giftCard.amount"
                            :currency="maxGiftCard ? maxGiftCard.currency : giftCard.currency"
                            :up-to-label="!!maxGiftCard" />
                    </div>
                    <VClamp v-if="!isDesktopOrUniformLayout && !isCityCentric" autoresize :max-lines="2" :title="distance" tag="span" class="gst-add-hotel-reservation-hotel-list-card__distance">
                        {{ distance }}
                    </VClamp>
                </div>
            </div>
            <div class="d-flex flex-row mt-2 px-md-2"
                :class="{
                    'pl-2': uniformLayout
                }">
                <div class="flex-grow-1">
                    <span class="gst-add-hotel-reservation-hotel-list-card__room-name d-inline-block">
                        {{ room | roomName }}
                    </span>
                    <div class="d-flex">
                        <span v-if="showBasePriceWithoutDiscount" class="gst-add-hotel-reservation-hotel-list-card__price-with-discount">
                            {{ roundedUpBasePriceWithoutDiscountFormatted }}
                        </span>
                        <span class="gst-add-hotel-reservation-hotel-list-card__price">
                            <span v-if="!showBasePriceWithoutDiscount" class="text-lowercase">{{ $t('_common:terms.from' ) }}</span>
                            <span>
                                {{ roundedUpBasePriceFormatted }}
                            </span>
                        </span>
                    </div>
                    <VClamp autoresize :max-lines="1" :title="bundleProducts | bundleProductsQuantity" class="gst-add-hotel-reservation-hotel-list-card__bundle-products pb-1">
                        {{ bundleProducts | bundleProductsQuantity }}
                    </VClamp>
                </div>
                <div class="d-flex align-center">
                    <BaseButton
                        :placeholder="addButtonLabel"
                        class="gst-add-hotel-reservation-hotel-list-card__btn"
                        color="primary"
                        @click="addItem" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import VClamp from 'vue-clamp';
    import StarRating from '@core/shared/components/misc/StarRating.vue';
    import BaseButton from '@core/shared/components/buttons/BaseButton.vue';
    import { create as CreateBundleProductsModel } from '@tenant/app/modelsV2/BundleProductsModel';
    import BaseImage from '@tenants/ticketmaster/app/components/BaseImage';
    import { getImageByWidth } from '@tenants/ticketmaster/app/utils/imageModelUtils';
    import { IMAGE_SIZE } from '@tenants/ticketmaster/app/utils/constants/imageData';
    import GiftCardChip from '@tenant/app/components/chips/GiftCardChip';
    import SaveAmountCardChip from '@tenant/app/components/chips/SaveAmountCardChip';
    import locationsConstants from '@tenants/ticketmaster/app/utils/constants/locations';

    export default {
        name: 'HotelsListCard',
        components: {
            VClamp,
            BaseImage,
            StarRating,
            BaseButton,
            GiftCardChip,
            SaveAmountCardChip
        },
        props: {
            item: {
                type: Object,
                required: true
            },
            distanceUnitOfMeasure: {
                type: String,
                required: true
            },
            distanceFrom: {
                type: Boolean,
                default: false
            },
            inLocation: {
                type: Object,
                required: true
            },
            bundleProducts: {
                type: Object,
                default: function ( ) {
                    return CreateBundleProductsModel( );
                }
            },
            busEventsParent: {
                type: Object,
                required: true
            },
            addButtonLabel: {
                type: String,
                required: true
            },
            isHighlighted: {
                type: Boolean,
                default: false
            },
            // when true the hotel card layout is the same for desktop and mobile
            uniformLayout: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            stars( ) {
                const { awards } = this.item;

                if ( awards.length && awards[ 0 ].rating ) {
                    return awards[ 0 ].rating;
                }

                return 0;
            },
            image( ) {
                if ( this.$vuetify.breakpoint.mdAndUp ) {
                    return {
                        src: getImageByWidth( IMAGE_SIZE.LARGE, this.item.image ),
                        fallBack: getImageByWidth( IMAGE_SIZE.ORIGINAL, this.item.image )
                    };
                }
                return {
                    src: getImageByWidth( IMAGE_SIZE.THUMBNAIL, this.item.image )
                };
            },
            distance( ) {
                if ( this.distanceFrom ) {
                    return this.$options.filters.distanceFrom(
                        this.item.distance,
                        this.distanceUnitOfMeasure,
                        this.$t( '_common:terms.venue' )
                    );
                }
                return this.$options.filters.distanceToVenue( this.item.distance, this.distanceUnitOfMeasure );
            },
            room( ) {
                return this.item.selectedRoom;
            },
            rooms( ) {
                return this.item.rooms;
            },
            bundleProductsTotals( ) {
                return this.bundleProducts.getTotals( );
            },
            showBasePriceWithoutDiscount( ) {
                return this.bundleProductsTotals.basePrice !== this.bundleProductsTotals.basePriceWithoutDiscount;
            },
            giftCard( ) {
                return this.room.selectedRate.giftCard;
            },
            maxGiftCard( ) {
                return this.item.maxGiftCard;
            },
            showGiftCard( ) {
                return this.giftCard?.amount || this.maxGiftCard?.amount;
            },
            showDiscountAmount( ) {
                return this.showBasePriceWithoutDiscount && this.bundleProductsTotals.discountedAmount;
            },
            currency( ) {
                return this.room.selectedRate.currency;
            },
            roundedUpBasePriceFormatted( ) {
                return this.$options.filters.currencyFilter( Math.ceil( this.bundleProductsTotals.basePrice ), this.currency, { digits: 0 } );
            },
            roundedUpBasePriceWithoutDiscountFormatted() {
                return this.$options.filters.currencyFilter( Math.ceil( this.bundleProductsTotals.basePriceWithoutDiscount ), this.currency, { digits: 0 } );
            },
            savings() {
                return  this.bundleProductsTotals.discountedAmount;
            },
            isCityCentric() {
                return this.inLocation.type === locationsConstants.TYPES.CITY;
            },
            isDesktopOrUniformLayout() {
                return this.$vuetify.breakpoint.mdAndUp || this.uniformLayout;
            }
        },
        methods: {
            ...mapActions( {
                focusHotel: `addHotelReservationState/hotels/focusHotel`,
            } ),
            openItemDetails( focusedRoom ) {
                this.busEventsParent.$emit( 'open-item-details', this.item, focusedRoom );
            },
            addItem( event ) {
                event.stopPropagation( );
                this.busEventsParent.$emit( 'add-item', this.item );
            },
            onItemMouseEnterDo( ) {
                this.focusHotel( this.item );
            },
            onItemMouseLeaveDo( ) {
                this.focusHotel( null );
            }
        }
    };
</script>


<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";
    $hoverBackgroundColor: #EBF5FF;

    .gst-add-hotel-reservation-hotel-list-card {
        @include font-size( 'm' );
        @include font-weight( 'regular' );

        border: 1px solid theme-color-hex( 'senary-darken-2' );
        border-radius: border-radius( 'm' );
        overflow: hidden;

        &:hover {
            border: 1px solid #EBF5FF;
            background-color: $hoverBackgroundColor;
        }

        &.gst-add-hotel-reservation-hotel-list-card--highlighted {
            border: 1px solid theme-color-hex( 'primary' );
            background-color: $hoverBackgroundColor;

            &:hover {
                border: 1px solid theme-color-hex( 'primary' );
            }
        }

        .v-image {
            border-radius: 2px;
        }

        .gst-add-hotel-reservation-hotel-list-card__rating {
            height: theme-spacing( 5 );
            margin-top: 2px;
            float: left;
        }

        .gst-add-hotel-reservation-hotel-list-card__distance {
            @include font-size( 'xxs' );
            @include line-height( 'm' );

            color: theme-color( 'tertiary' );
        }

        .gst-add-hotel-reservation-hotel-list-card__name {
            @include font-size( 'l' );
            @include font-weight( 'large' );
            @include line-height( 'xxxl' );

            color: theme-color( 'quaternary' );
        }

        .gst-add-hotel-reservation-hotel-list-card__room {
            @include font-size( 's' );
            @include line-height( 'xxxl' );

            position: relative;
        }

        .gst-add-hotel-reservation-hotel-list-card__room-name {
            color: theme-color( 'tertiary' );
            font-size: font-size( 'xxs' );
            font-weight: font-weight( 'regular' );
        }

        .gst-add-hotel-reservation-hotel-list-card__bundle-products {
            @include font-size( 'xxs' );
            @include line-height( 'm' );

            margin: theme-spacing( 1 ) 0;
            color: theme-color( 'tertiary' );
        }

        .gst-add-hotel-reservation-hotel-list-card__gifts {
            gap: theme-spacing( 1 );
        }

        .gst-add-hotel-reservation-hotel-list-card__price {
            @include font-size( 's' );
            @include font-weight( 'large' );
            @include line-height( 'xxxl' );

            margin-right: theme-spacing( 2 );
            color: theme-color( 'primary' );
        }

        .gst-add-hotel-reservation-hotel-list-card__price-with-discount {
            line-height: line-height( 'xxxl' );
            margin-right: theme-spacing( 1 );
            color: theme-color( 'tertiary' );
            font-size: font-size( 's' );
            text-decoration: line-through;
        }

        .gst-add-hotel-reservation-hotel-list-card__price-info__hotel-only {
            @include font-size( 's' );
            @include line-height( 'xxl' );

            margin-right: theme-spacing( 1 );
            color: theme-color( 'tertiary' );
        }

        .gst-add-hotel-reservation-hotel-list-card__btn {
            @include font-weight( 'large' );

            letter-spacing: 0;
            text-transform: capitalize;
        }

        .gst-add-hotel-reservation-hotel-list-card__icon {
            path {
                fill: theme-color( 'tertiary' );
            }
        }

        .gst-add-hotel-reservation-hotel-list-card__icon:hover {
            path {
                fill: theme-color( 'primary' );
            }
        }
    }

    @include mobile-only {
        .gst-add-hotel-reservation-hotel-list-card {
            border: unset;
            border-bottom: 1px solid theme-color-hex( 'senary-darken-2' );
            border-radius: border-radius( 'none' );

            &:hover {
                border: unset;
                background-color: #EBF5FF;
                border-bottom: 1px solid #EBF5FF;
            }

            .gst-add-hotel-reservation-hotel-list-card__distance {
                margin-top: theme-spacing( 1 );
            }

            .gst-add-hotel-reservation-hotel-list-card__rating-wrapper .gst-add-hotel-reservation-hotel-list-card__distance {
                position: relative;
                bottom: theme-spacing( 1 );
                margin-top: 0;
            }

            .gst-add-hotel-reservation-hotel-list-card__room {
                top: 2px;
            }

            .gst-add-hotel-reservation-hotel-list-card__rating {
                position: relative;
                bottom: theme-spacing( 1 );
            }
        }
    }

    .gst-add-hotel-reservation-hotel-list-card__tooltip.v-tooltip__content {
        line-height: font-size( 'l' );
        padding: theme-spacing( 4 );
        background: theme-color( 'white' ) !important;
        color: theme-color( 'quaternary' );
        font-size: font-size( 's' );
        max-width: 350px;
        box-shadow: 0 2px 6px rgba( theme-color-hex( 'black' ), 0.25 );
        opacity: 1 !important;
    }
</style>
