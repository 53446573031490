<template>
    <div class="gst-hotels-map__wrapper d-flex">
        <template v-if="!resultsNotFound">
            <BaseButton
                v-show="showReset"
                outlined
                class="gst-hotels-map__refresh-button u-mouse-pointer"
                alt="Reset map to initial search"
                @click="resetToInitialSearch( )">
                <BaseIcon symbol-id="icons--reset" />
            </BaseButton>
            <BaseButton
                v-if="isNewSearchArea"
                :loading="loadingHotels"
                :placeholder="$t( '_common:buttons.searchThisArea' )"
                outlined
                color="primary"
                class="gst-hotels-map__load-more-button mx-auto d-flex"
                @click="updateFilterLocationWithMapCenter" />
            <BaseButton
                v-else-if="!allDataLoaded"
                :loading="loadingHotels"
                :placeholder="$t( '_common:buttons.loadMoreHotels' )"
                outlined
                color="primary"
                class="gst-hotels-map__load-more-button"
                @click="loadNextPage" />
        </template>
    </div>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex';
    import BaseButton from '@core/shared/components/buttons/BaseButton.vue';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        name: 'HotelsMapActions',
        components: {
            BaseButton,
            BaseIcon
        },
        props: {
            map: {
                type: Object,
                required: true
            },
        },
        computed: {
            ...mapState( {
                loadingHotels: state => state.addHotelReservationState.hotels.loading,
                allDataLoaded: state => state.addHotelReservationState.hotels.allItemsLoaded,
                filters: state => state.addHotelReservationState.filters,
                limit: state => state.addHotelReservationState.limit,
                location: state => state.addHotelReservationState.hotels.filters.location,
                initialLoad: state => state.addHotelReservationState.hotels.initialLoad,
                mapCenter: state => state.addHotelReservationState.map.mapCenter,
                searchCenter: state => state.addHotelReservationState.map.searchCenter,
                resultsNotFound: state => state.addHotelReservationState.hotels.resultsNotFound
            } ),
            ...mapGetters( {
                didHotelFiltersChangeFromContext: 'addHotelReservationState/didHotelFiltersChangeFromContext',
                isNewSearchArea:  'addHotelReservationState/map/isNewSearchArea'
            } ),
            showReset( ) {
                return !this.loadingHotels && ( this.isNewSearchArea || !this.initialLoad || this.didHotelFiltersChangeFromContext );
            }
        },
        watch: {
            loadingHotels: {
                handler: function ( newVal, oldVal ) {
                    if ( !newVal && oldVal ) {
                        const unwatchMapCenter = this.$watch( 'mapCenter', () => {
                            if ( this.map ) {
                                const center = this.map.getCenter( );

                                this.setSearchCenter( { latitude: center.lat, longitude: center.lng } );
                            }
                            unwatchMapCenter( );
                        }, { deep: true } );
                    }
                }
            }
        },
        methods: {
            ...mapActions( {
                loadPage: 'addHotelReservationState/hotels/loadPage',
                setInitialLoad: 'addHotelReservationState/hotels/setInitialLoad',
                updateFilters: 'addHotelReservationState/filters/update',
                resetFilters: 'addHotelReservationState/resetFilters',
                resetHotels: 'addHotelReservationState/resetHotels',
                setSearchCenter: 'addHotelReservationState/map/setSearchCenter',
                setMapCenter: 'addHotelReservationState/map/setMapCenter',
                updateFilterLocationWithMapCenter: 'addHotelReservationState/updateFilterLocationWithMapCenter',
                setResultsNotFound: 'addHotelReservationState/hotels/setResultsNotFound'
            } ),
            loadNextPage( ) {
                this.loadPage( {
                    filters: this.filters,
                    limit: this.limit
                } );
                this.setInitialLoad( false );
            },
            onMapMoveEndDo( ) {
                const center = this.map.getCenter();

                this.setResultsNotFound( false );
                this.setMapCenter( { latitude: center.lat, longitude: center.lng } );
            },
            resetToInitialSearch( ) {
                this.resetHotels( );
                this.resetFilters( );
            }
        },
        mounted( ) {
            const center = this.map.getCenter();
            this.setMapCenter( { latitude: center.lat, longitude: center.lng } );
            this.setSearchCenter( { latitude: center.lat, longitude: center.lng } );
            this.map.on( 'moveend', this.onMapMoveEndDo );
        },
        destroyed( ) {
            this.map.off( 'moveend', this.onMapMoveEndDo );
        }
    };
</script>

<style lang="scss" scoped>
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-hotels-map__load-more-button {
        background: white;
        box-shadow: 4px 6px 12px 0 rgba( 0, 0, 0, 0.12 ) !important;
        text-transform: capitalize;
    }

    .gst-hotels-map__refresh-button {
        padding: theme-spacing( 0 ) !important;
        border: 1px solid theme-color( 'primary-darken-2' );
        margin-right: theme-spacing( 2 );
        background: theme-color( 'primary-darken-2' );
        min-width: 38px !important;
    }
</style>
