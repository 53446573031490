var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gst-selected-hotel-card d-flex flex-row",class:{
        'gst-selected-hotel-card--has-image': _vm.showImage,
    }},[(_vm.showImage)?_c('div',{staticClass:"gst-selected-hotel-card__image flex-shrink-1"},[_c('BaseImage',{attrs:{"src":_vm.image,"gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)","height":"100%","width":_vm.$vuetify.breakpoint.mdAndUp ? 124 : 98}})],1):_vm._e(),_c('div',{staticClass:"gst-selected-hotel-card__content d-flex flex-column flex-grow-1"},[_c('div',{staticClass:"d-flex gst-selected-hotel-card__title ",class:{
                'flex-column-reverse': _vm.$vuetify.breakpoint.smAndDown
            }},[_c('span',{staticClass:"gst-selected-hotel-card__title-name",class:{
                    'text-truncate': _vm.$vuetify.breakpoint.smAndDown
                }},[_vm._v(" "+_vm._s(_vm.selectedHotelReservation.item.name)+" ")]),_c('StarRating',{staticClass:"gst-selected-hotel-card__title-star-rating",attrs:{"value":_vm.rating}})],1),(_vm.showGiftCard)?_c('div',{staticClass:"d-flex mt-2"},[_c('GiftCardChip',{attrs:{"amount":_vm.giftCard.amount,"currency":_vm.giftCard.currency}})],1):_vm._e(),(_vm.showImage || _vm.$vuetify.breakpoint.mdAndUp)?_c('span',{staticClass:"gst-selected-hotel-card__venue-distance"},[_vm._v(_vm._s(_vm.distance))]):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"d-flex align-center gst-selected-hotel-card__stay-details"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('BedIcon',{staticClass:"mr-2 flex-shrink-0"}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.roomInfo)+" • "+_vm._s(_vm.stayPeriod))])],1):_c('div',{staticClass:"d-flex gst-selected-hotel-card__stay-details",class:{
                'flex-column': _vm.showImage
            }},[_c('span',{staticClass:"flex-shrink-1 ",class:{
                    'text-truncate': !_vm.showImage
                }},[_vm._v(_vm._s(_vm.roomInfo))]),(!_vm.showImage)?_c('span',{staticClass:"flex-shrink-0 mx-1"},[_vm._v(" • ")]):_vm._e(),_c('span',{staticClass:"flex-shrink-0"},[_vm._v(_vm._s(_vm.stayPeriod))])])]),_c('div',{staticClass:"gst-selected-hotel-card__actions d-flex flex-grow-0",class:{
            'align-center': _vm.showImage,
            'flex-column justify-center': !_vm.$vuetify.breakpoint.mdAndUp
        }},[(_vm.hasRemoveButton)?_c('BaseButton',{staticClass:"gst-selected-hotel-card__actions-remove-hotel-btn",on:{"click":_vm.onRemoveBtnClickDo}},[_vm._v(" "+_vm._s(_vm.$t( '_common:buttons.remove' ))+" ")]):_vm._e(),_c('BaseButton',{staticClass:"gst-selected-hotel-card__actions-change-hotel-btn",on:{"click":_vm.onChangeBtnClickDo}},[_vm._v(" "+_vm._s(_vm.$t( '_common:buttons.change' ))+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }