<template>
    <div class="hotel-details-rooms-tab pa-0 pa-md-4">
        <RoomCardVariant2
            v-for="room in item.rooms"
            :key="room.id"
            class="mt-4 mt-md-0"
            :item="room"
            :bundle-products="bundleProductsFinal"
            :is-room-selected="isRoomSelected(room)"
            :is-room-focused="isRoomFocused(room)"
            @click="select"
            @focusedRoomMounted="onFocusedRoomMountedDo" />
    </div>
</template>

<script>

    import { mapActions, mapState } from 'vuex';
    import isEqual from 'lodash/isEqual';
    import omit from 'lodash/omit';
    import HotelRoom from '@/tenants/ticketmaster/app/models/HotelReservationRoomModel';
    import { create as CreateBundleProductsHotelReservationModel } from '@tenant/app/modelsV2/BundleProductsHotelReservationModel';
    import RoomCardVariant2 from '../../../cards/RoomCardVariant2';

    const STORE_NAME = 'addHotelReservationState';

    export default {
        name: 'HotelDetailsRoomsTab',
        components: {
            RoomCardVariant2,
        },
        props: {
            item: {
                type: Object,
                default: function ( ) {
                    return new HotelRoom( );
                }
            },
            value: {
                type: Object,
                default: function ( ) {
                    return new HotelRoom( );
                }
            },
            bundleProducts: {
                type: Object,
                default: null
            },
            busEventsParent: {
                type: Object,
                default: null
            },
            hasValue: {
                type: Boolean,
                default: false
            },
            focusedRoom: {
                type: Object,
                default: null
            },
        },
        data() {
            return {
                selecting: false,
                focusedRoomRef: null,
            };
        },
        computed: {
            ...mapState( {
                filters: state => state[STORE_NAME].filters
            } ),
            showNoData( ) {
                return !this.loading &&  !this.item.rooms.length;
            },
            bundleProductsFinal( ) {
                const ret = this.bundleProducts._clone( );

                ret.setHotelReservation(
                    CreateBundleProductsHotelReservationModel (
                        this.item
                    )
                );

                return ret;
            }
        },
        methods: {
            ...mapActions( {
                'resetFilters': `${STORE_NAME}/resetFilters`
            } ),
            select( room ) {
                if ( this.selecting ) {
                    return;
                }
                this.selecting = true;

                this.busEventsParent.$emit(
                    'add-item',
                    {
                        ...this.item,
                        selectedRoom: room
                    },
                    {
                        startDateTime: this.filters.startDateTime,
                        endDateTime: this.filters.endDateTime,
                        guests: this.filters.guests,
                        roomsCount: this.filters.roomsCount
                    }
                );

                setTimeout( () => {
                    this.$emit( 'close' );
                    this.selecting = false;
                }, 250 );
            },
            areRoomEqual( first, second ) {
                const firstFiltered = omit( first, [ 'selectedRate', 'rates' ] );
                const secondFiltered = omit( second, [ 'selectedRate', 'rates' ] );

                return isEqual( firstFiltered, secondFiltered );
            },
            isRoomSelected( room ) {
                if( !this.bundleProducts.hotelReservation.hasValue( ) ) {
                    return false;
                }

                return this.areRoomEqual( room, this.bundleProducts.hotelReservation.hotel.selectedRoom );
            },
            isRoomFocused( room ) {
                if( !this.focusedRoom ) {
                    return false;
                }
                return this.focusedRoom.id === room.id;
            },
            onFocusedRoomMountedDo( elem ) {
                if( elem ) {
                    this.focusedRoomRef = elem;
                }
            },
            emitScrollToRoom() {
                if( !this.focusedRoomRef ) {
                    return;
                }

                const roomOffset = this.focusedRoomRef.offsetHeight + this.focusedRoomRef.offsetTop;
                const roomFiltersOffset = this.$refs['room-filers'].$el.clientHeight;

                this.busEventsParent.$emit( 'scrollToRoom', roomOffset + roomFiltersOffset );
            }
        },
        updated() {
            this.$nextTick( () =>  this.emitScrollToRoom() );

        },
        mounted() {
            this.$nextTick( () =>  this.emitScrollToRoom() );
        }
    };
</script>
