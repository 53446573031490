<template>
    <v-carousel v-model="activeSlide"
        class="gst-room-carousel"
        :height="height"
        :show-arrows="false"
        :hide-delimiters="imagesToDisplay.length <= 1"
        v-bind="$attrs"
        v-on="$listeners"
        @mouseenter.native="onCarouselMouseEnterDo"
        @mouseleave.native="onCarouselMouseLeaveDo">
        <template>
            <BaseButton v-if="showNavigationButtons"
                class="gst-room-card-variant-3__gallery__control-prev d-inline"
                :class="{'disabled': activeSlide === 0}"
                color="white"
                :width="24"
                :height="24"
                fab
                @click="onCarouselClickPrevDo">
                <IconChevronLeft />
            </BaseButton>
            <BaseButton v-if="showNavigationButtons"
                class="gst-room-card-variant-3__gallery__control-next"
                :class="{'disabled': activeSlide === imagesToDisplay.length - 1}"
                color="white"
                :width="24"
                :height="24"
                fab
                @click="onCarouselClickNextDo">
                <IconChevronRight />
            </BaseButton>
        </template>

        <v-carousel-item v-for="(image, index) in imagesToDisplay"
            :key="index">
            <BaseImage :src="image.src" :fallback-src="image.fallBack" :height="height" />
        </v-carousel-item>
        <v-carousel-item v-if="!imagesToDisplay.length">
            <BaseImage class="gst-room-card-variant-3__gallery__fallback" />
        </v-carousel-item>
    </v-carousel>
</template>

<script>
    import debounce from 'lodash/debounce';
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import IconChevronRight from '@core/shared/components/icons/IconChevronRight.vue';
    import IconChevronLeft from '@core/shared/components/icons/IconChevronLeft.vue';
    import BaseImage from '@tenants/ticketmaster/app/components/BaseImage';
    import { getImageByWidth } from '@tenants/ticketmaster/app/utils/imageModelUtils';
    import { IMAGE_SIZE } from '@tenants/ticketmaster/app/utils/constants/imageData';

    export default {
        name: 'RoomCarousel',
        components: {
            BaseButton,
            BaseImage,
            IconChevronLeft,
            IconChevronRight
        },
        props: {
            images: {
                type: Array,
                default: new Array()
            },
            height: {
                type: Number,
                default: 160
            }
        },
        data() {
            return {
                activeSlide: 0,
                displayNavigation: false
            };
        },
        computed: {
            imagesToDisplay ( ) {
                return this.images.map( ( image ) => {
                    return {
                        src: getImageByWidth( IMAGE_SIZE.LARGE, image ),
                        fallBack: getImageByWidth( IMAGE_SIZE.ORIGINAL, image )
                    };
                } );
            },
            showNavigationButtons() {
                if( this.imagesToDisplay.length > 1 ) {
                    return this.$vuetify.breakpoint.smAndDown ? false : this.displayNavigation;
                }
                return false;
            }
        },
        methods: {
            onCarouselMouseEnterDo: debounce( function( ) {
                this.displayNavigation = true;
            }, 50 ),
            onCarouselMouseLeaveDo: debounce( function( ) {
                this.displayNavigation = false;
            }, 50 ),
            onCarouselClickPrevDo () {
                if( this.activeSlide > 0 ) {
                    this.activeSlide--;
                }
            },
            onCarouselClickNextDo () {
                if( this.activeSlide < this.imagesToDisplay.length - 1 ) {
                    this.activeSlide++;
                }
            }
        }
    };
</script>

<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";

.gst-room-carousel {
    position: relative;
    width: 260px;
    max-height: 160px;
    border-radius: border-radius( 'xs' );

    .gst-room-card-variant-3__gallery__control-prev,
    .gst-room-card-variant-3__gallery__control-next {
        position: absolute;
        top: calc( 50% - 12px );
        z-index: 1;
        box-shadow: 0 2px 6px rgba( theme-color-hex( 'black' ), 0.25 );

        svg {
            .gst-svg-icon {
                fill: theme-color( 'tertiary' );
            }
        }

        &.disabled {
            svg {
                .gst-svg-icon { fill: theme-color( 'septenary' ); }
            }
        }
    }

    .gst-room-card-variant-3__gallery__control-prev {
        left: theme-spacing( 2 );

        svg {
            margin-left: -2px;
        }
    }

    .gst-room-card-variant-3__gallery__control-next {
        right: theme-spacing( 2 );

        svg {
            margin-right: -2px;
        }
    }

    .v-carousel__controls {
        height: 22px;
        background-color: unset;
    }

    .v-carousel__controls__item {
        height: theme-spacing( 2 );
        width: theme-spacing( 2 );
        margin: 3px;
        background-color: rgba( theme-color-hex( 'white' ), 0.4 );

        .v-icon {
            height: theme-spacing( 3 );
            width: theme-spacing( 3 );
        }
    }

    .v-item--active {
        background: theme-color( 'white' );
        opacity: 1;
    }

    .gst-room-card-variant-3__gallery__fallback {
        height: 100%;
    }

    @include mobile-only {
        width: 100%;
    }
}
</style>
