<template>
    <div class="d-flex flex-column u-position-relative" :style="{ 'flex': 1, 'min-height': 0 }">
        <SelectedEventTicketCard
            v-if="selectedTicket"
            :ticket="selectedTicket"
            :quantity="ticketsQuantity"
            class="ma-2 mb-0"
            @change="$emit( 'change-event-ticket' )" />
        <AddHotelReservation
            :style="{ 'flex': 1, 'min-height': 0 }"
            :value="selectedHotelReservation"
            :event="event"
            :start-date-time="event.startDateTime"
            :end-date-time="endDateReservation"
            :quantity="ticketsQuantity"
            :in-location="venueLocation"
            :distance-unit-of-measure="distanceUnitOfMeasure"
            :bundle-products="bundleProducts"
            :highlighted-item="highlightedHotelReservation"
            @change-show-filter="$emit('change-show-filter', $event)"
            @highlight-item="$emit('highlight-hotel-reservation', $event)"
            @input="onInputDo" />
    </div>
</template>

<script>
    import { getDistanceUnitOfMeasureForCountry } from '@core/utils/measureUtils';
    import { addDays } from '@tenant/app/utils/dateUtils';
    import { create as CreateBundleProductsModel } from '@tenant/app/modelsV2/BundleProductsModel';
    import AddHotelReservation from '@tenant/app/modules/hotel/features/addHotelReservation/AddHotelReservation';
    import SelectedEventTicketCard from './_components/SelectedEventTicketCard';

    export default {
        name: 'EventHotels',
        components: {
            AddHotelReservation,
            SelectedEventTicketCard
        },
        props: {
            event: {
                type: Object,
                required: true,
            },
            selectedHotelReservation: {
                type: Object,
                default: null,
            },
            selectedTicket: {
                type: Object,
                default: null
            },
            ticketsQuantity: {
                type: Number,
                default: 2
            },
            busEventsParent: {
                type: Object,
                required: true
            },
            bundleProducts: {
                type: Object,
                default: function ( ) {
                    return CreateBundleProductsModel( );
                }
            },
            highlightedHotelReservation: {
                type: Object,
                default: null
            }
        },
        computed: {
            currency( ) {
                return this.event.currency;
            },
            endDateReservation( ) {
                const { startDateTime, endDateTime } = this.event;


                return addDays( endDateTime || startDateTime, 1 );
            },
            venueLocation( ) {
                const { venueCity, venueCountry, venueName, venueState, venueStreet, venueLatitude, venueLongitude } = this.event;

                return {
                    name: venueName,
                    stateCode: venueState,
                    countryCode: venueCountry,
                    city: venueCity,
                    street: venueStreet,
                    latitude: venueLatitude,
                    longitude: venueLongitude
                };
            },
            distanceUnitOfMeasure( ) {
                return getDistanceUnitOfMeasureForCountry ( this.venueLocation.countryCode );
            },
        },
        methods: {
            onInputDo( item ) {
                this.$emit( 'select-hotel-reservation', item );
                this.$emit( 'highlight-hotel-reservation', item ? item.item : this.highlightedHotelReservation );
            }
        }
    };
</script>