<template>
    <div class="gst-hotel-details-body">
        <v-tabs-items v-model="tab">
            <v-tab-item v-for="(tabName, index) in navigationTabs" :key="index">
                <HotelDetailsOverviewTab v-if="tabName === 'overview'" ref="overviewTab" :item="item" :event="event" :in-location="inLocation" />
                <HotelDetailsRoomsTab v-if="tabName === 'rooms'"
                    :item="item"
                    :bundle-products="bundleProducts"
                    :has-value="hasValue"
                    :focused-room="focusedRoom"
                    :bus-events-parent="busEventsParent"
                    @close="$emit('close')" />
                <HotelDetailsNearTab v-if="tabName === 'whatsNearby'" :bundle-products="bundleProducts" :item="item" :event="event" />
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>

    import HotelDetailsNearTab from './HotelDetailsBody/HotelDetailsNearTab';
    import HotelDetailsRoomsTab from './HotelDetailsBody/HotelDetailsRoomsTab';
    import HotelDetailsOverviewTab from './HotelDetailsBody/HotelDetailsOverviewTab';

    export default {
        name: 'HotelDetailsBody',
        components: {
            HotelDetailsNearTab,
            HotelDetailsRoomsTab,
            HotelDetailsOverviewTab
        },
        props: {
            item: {
                type: Object,
                default: null
            },
            event: {
                type: Object,
                required: true
            },
            bundleProducts: {
                type: Object,
                default: null
            },
            busEventsParent: {
                type: Object,
                default: null
            },
            hasValue: {
                type: Boolean,
                default: false
            },
            focusedRoom: {
                type: Object,
                default: null
            },
            tab: {
                type: Number,
                default: 0
            },
            navigationTabs: {
                type: Array,
                default: new Array()
            },
            inLocation: {
                type: Object,
                default: () => ( {} )
            },
        }
    };
</script>
