<template>
    <div
        ref="hotelMarker"
        tabindex="0"
        @click.prevent="$emit('click', $event)"
        @keypress.enter="$emit('click', $event)">
        {{ basePrice }}
    </div>
</template>
<script>
    import { create as CreateBundleProductsModel } from '@tenant/app/modelsV2/BundleProductsModel';

    export default {
        name: 'MapMarkerHotel',
        props: {
            mapsApi: {
                type: Object,
                required: true
            },
            map: {
                type: Object,
                required: true
            },
            hotel: {
                type: Object,
                required: true
            },
            isActive: {
                type: Boolean,
                default: false
            },
            isFocused: {
                type: Boolean,
                default: false
            },
            bundleProducts: {
                type: Object,
                required: true,
                default: function( ) {
                    return CreateBundleProductsModel( );
                }
            },
        },
        data( ) {
            return {
                popupInstance: null
            };
        },
        computed: {
            bundleProductsTotals( ) {
                return this.bundleProducts.getTotals( );
            },
            basePrice( ) {
                if ( this.showBasePriceWithoutDiscount ) {
                    return this.$options.filters.currencyFilter( Math.ceil( this.bundleProductsTotals.basePrice ), this.hotel.selectedRoom.selectedRate.currency, { digits: 0 } );
                } else {
                    return this.$options.filters.currencyFilter( this.bundleProductsTotals.basePrice, this.hotel.selectedRoom.selectedRate.currency );
                }
            },
            showBasePriceWithoutDiscount( ) {
                return this.bundleProductsTotals.basePrice !== this.bundleProductsTotals.basePriceWithoutDiscount;
            },
        },
        watch: {
            'isFocused':  function ( newValue ) {
                if ( this.popupInstance ) {
                    if ( newValue ) {
                        this.popupInstance.addClassName( 'gst-map-marker-hotel--focused' );
                    } else {
                        this.popupInstance.removeClassName( 'gst-map-marker-hotel--focused' );
                    }
                }
            },
            isActive:  function ( newValue ) {
                if ( this.popupInstance ) {
                    if ( newValue ) {
                        this.popupInstance.addClassName( 'gst-map-marker-hotel--active' );
                    } else {
                        this.popupInstance.removeClassName( 'gst-map-marker-hotel--active' );
                    }
                }
            }
        },
        mounted( ) {
            this.popupInstance = new this.mapsApi.Popup( { anchor: 'bottom', closeButton: false, closeOnClick: false, className: 'gst-map-marker-hotel' } )
                .setLngLat( [ this.hotel.longitude, this.hotel.latitude ] )
                .setDOMContent( this.$refs.hotelMarker )
                .addTo( this.map );
        },
        beforeDestroy( ) {
            if ( this.popupInstance ) {
                this.popupInstance.remove( );
            }
        }
    };
</script>

<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-map-marker-hotel {
        z-index: $z-index-map-hotel-marker;

        .mapboxgl-popup-content {
            padding: 0 5px;
            border: 1px solid theme-color( 'primary' );
            background-color: theme-color( 'primary' );
            color: theme-color( 'white' );
            font-size: font-size( 's' );
            font-weight: 600;
            font-family: $font-family;
            border-radius: 15px;
            cursor: pointer;
        }

        .mapboxgl-popup-tip {
            position: relative;
            border: 5px solid transparent;
            border-top-color: theme-color( 'primary' );

            &::before {
                position: absolute;
                top: -6px;
                left: -5px;
                height: 0;
                width: 0;
                border: 5px solid transparent;
                border-top-color: theme-color( 'primary' );
                border-bottom: none;
                content: '';
                z-index: $z-index-map-hotel-marker;
            }
        }
    }

    .gst-map-marker-hotel:hover,
    .gst-map-marker-hotel--active,
    .gst-map-marker-hotel--focused {
        z-index: $z-index-map-hotel-marker + 1;

        .mapboxgl-popup-content {
            border: 1px solid theme-color( 'primary' );
            background-color: theme-color( 'white' );
            color: theme-color( 'primary' );
        }

        .mapboxgl-popup-tip::before {
            border-top-color: theme-color( 'white' );
        }
    }
</style>