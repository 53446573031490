<template>
    <div class="gst-event-sidebar u-height-100 d-flex flex-column">
        <slot></slot>
        <div v-if="displayFooter">
            <EventSidebarFooter
                class="gst-event-sidebar__footer d-flex flex-grow-0 u-width-100"
                :title="footerTitle"
                :bundle-products="bundleProducts"
                :event="event" />
            <EventSidebarButton
                class="gst-event-sidebar__button flex-grow-0"
                :disabled="disabledButton"
                :placeholder="submitButtonPlaceholder"
                :test-id-options="$testId('button.next')"
                @submit="$emit('submit')" />
        </div>
    </div>
</template>

<script>
    import EventSidebarFooter from './EventSidebar/EventSidebarFooter.vue';
    import EventSidebarButton from './EventSidebar/EventSidebarButton.vue';

    export default {
        name: 'EventSidebar',
        components: {
            EventSidebarFooter,
            EventSidebarButton
        },
        props: {
            footerTitle: {
                type: String,
                default: null
            },
            submitButtonPlaceholder: {
                type: String,
                default: null
            },
            disabledButton: {
                type: Boolean,
                required: true
            },
            displayFooter: {
                type:Boolean,
                default: true
            },
            bundleProducts: {
                type: Object,
                required: true
            },
            event: {
                type: Object,
                required: true
            }
        },
        testIdOptions: {
            keyPrefix: 'event._components.eventSidebar'
        }
    };
</script>

<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";

@include mobile-only {
    .gst-event-sidebar {
        .gst-ticket-card-variant-6 {
            height: 20%;
        }
    }
}

@media screen and (max-width: 320px) {
    .gst-event-sidebar {
        .gst-ticket-card-variant-6 {
            height: 25%;
        }

        .gst-add-hotel-reservation-header__content {
            padding-left: theme-spacing( 0 ) !important;
            flex-wrap: unset !important;

            .v-btn {
                transform: scale( 0.925 );
            }

            .v-btn:nth-child(1) {
                margin-right: theme-spacing( n1 );
            }
        }
    }
}
</style>