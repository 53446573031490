import { isNumber } from '@core/utils/numberUtils';
import { parseDateString, isAfter as isDateAfter, differenceInDays, isSameDayOrAfter } from '@tenant/app/utils/dateUtils';
import locationsConstants from '@tenant/app/utils/constants/locations';
import GuestsModel from '@tenant/app/models/GuestsModel';

function isEmpty( value ) {
    return value === undefined || value === null || value === '';
}

function validateCoordinates( latitude, longitude ) {
    const latRegex = /^-?([1-8]?\d(?:\.\d{1,})?|90(?:\.0{1,})?)$/;
    const lonRegex = /^-?((?:1[0-7]|[1-9])?\d(?:\.\d{1,})?|180(?:\.0{1,})?)$/;

    return latRegex.test( latitude.toString() ) && lonRegex.test( longitude.toString() );
}

function validateType ( value ) {
    return Object.values( locationsConstants.TYPES ).indexOf( value ) > -1;
}

function validateChildAges ( data ) {
    const array = Array.isArray( data ) ? data : [ data ];
    return array.every( d => isNumber( d ) && d >= 0 && d < 18 );
}

function validateCount ( value ) {
    return isNumber( value ) && value > 0 && value < 50;
}
/**
 * 
 * @param {String} startDate
 * @param {String} endDate
 * @param {*} options = { minStartDate (Date) } 
 * @returns 
 */
function validateStayPeriod ( startDate, endDate, options ) {
    return startDate &&
           endDate &&
           ( !options?.minStartDate || isSameDayOrAfter( parseDateString( startDate ), options.minStartDate ) ) &&
           isDateAfter( parseDateString( endDate ), parseDateString( startDate ) ) &&
           differenceInDays( parseDateString( endDate ), parseDateString( startDate ) ) < 31;
}

function isValidLocation( data ) {
    const ret = { ...data };

    const expectedLocationProperties = [ 'city', 'country', 'countryCode', 'latitude', 'longitude', 'name', 'state', 'stateCode', 'timezone', 'type' ];
    const allLocationPropertiesExist = expectedLocationProperties.every( key => key in ret );

    const validCoordinates = !isEmpty( ret.latitude ) && !isEmpty( ret.longitude ) && validateCoordinates( ret.latitude, ret.longitude );
    const validType = validateType( ret.type );

    return allLocationPropertiesExist && validCoordinates && validType;
}

export function getValidLocationFromQueryParams( data ) {
    return isValidLocation( data ) && {
        city: data.city,
        country: data.country,
        countryCode: data.countryCode,
        latitude: data.latitude,
        longitude: data.longitude,
        name: data.name,
        state: data.state,
        stateCode: data.stateCode,
        timezone: data.timezone,
        type: data.type
    };
}

/**
 * 
 * @param {*} data 
 * @param {*} options = { minStartDate }
 * @returns 
 */
export function getValidStayPeriodFromQueryParams( data, options ) {
    const hasStayPeriod = data.hasOwnProperty( 'startDate' ) || data.hasOwnProperty( 'endDate' );

    return hasStayPeriod 
        && validateStayPeriod( data.startDate, data.endDate, options )
        && {
            startDate: data.startDate,
            endDate: data.endDate
        };
}

function isValidGuestsCriteria( data ) {
    const ret = { ...data };
    const hasStayCriteria = [ 'roomsCount', 'adultsCount', 'childrenCount', 'childAges' ].some( key => key in ret );

    if ( hasStayCriteria ) {
        ret.adultsCount = ret.adultsCount && +ret.adultsCount;
        ret.roomsCount = ret.roomsCount && +ret.roomsCount;
        ret.childrenCount = ret.childrenCount && +ret.childrenCount;
        if ( ret.childAges ) {
            ret.childAges = Array.isArray( ret.childAges ) ? ret.childAges.map( age => +age ) : [ +ret.childAges ];
        }

        const validAdultsCount = validateCount( ret.adultsCount );
        const validRoomsCount = validateCount( ret.roomsCount );
        const validChildrenCount = validateCount ( ret.childrenCount ) || ret.childrenCount === 0;
        const validChildAges = ( !ret.childAges && ret.childrenCount === 0 ) ||
                    validChildrenCount && validateChildAges ( ret.childAges ) && ret.childrenCount === ret.childAges.length;

        return validAdultsCount
            && validRoomsCount
            && validChildrenCount
            && validChildAges;
    }
    return false;
}

export function getValidGuestsFromQueryParams( data ) {
    if ( isValidGuestsCriteria( data ) ) {
        const { roomsCount, adultsCount, childrenCount, childAges } = data;
        let guests;
        const adults = +adultsCount;
        const rooms = +roomsCount;
        const children = childrenCount && +childrenCount;

        if ( children ) {
            let ages;
            if ( Array.isArray( childAges ) ) {
                ages = childAges.reduce( ( acc, age, index ) => {
                    // only read as many ages as the number of children
                    if ( index < children ) {
                        acc.push( +age );
                    }
                    return acc;
                }, [] );
            } else {
                ages = [ +childAges ];
            }
            guests = new GuestsModel( adults, children, ages );
        } else {
            guests = new GuestsModel( adults );
        }

        return {
            roomsCount: rooms,
            guests
        };
    }
    return false;
}

/**
 * 
 * @param {*} data 
 * @param {*} options  = { minStartDate }
 * @returns 
 */
export function validateSearchHotelsQueryParams( data, options ) {
    const ret = { ...data };

    const expectedLocationProperties = [ 'city', 'country', 'countryCode', 'latitude', 'longitude', 'name', 'state', 'stateCode', 'timezone', 'type' ];
    const allLocationPropertiesExist = expectedLocationProperties.every( key => key in ret );

    const hasStayPeriod = ret.hasOwnProperty( 'startDate' ) || ret.hasOwnProperty( 'endDate' );
    const hasStayCriteria = ret.hasOwnProperty( 'roomsCount' ) || ret.hasOwnProperty( 'adultsCount' ) || ret.hasOwnProperty( 'childrenCount' ) || ret.hasOwnProperty( 'childAges' );

    const validCoordinates = !isEmpty( ret.latitude ) && !isEmpty( ret.longitude ) && validateCoordinates( ret.latitude, ret.longitude );
    const validType = validateType( ret.type );

    const validLocation = allLocationPropertiesExist && validCoordinates && validType;

    let validation = validLocation;

    if ( hasStayPeriod ) {
        const validPeriod = validateStayPeriod( ret.startDate, ret.endDate, options );
        validation = validation && validPeriod;
    }

    if ( hasStayCriteria ) {
        ret.adultsCount = ret.adultsCount && +ret.adultsCount;
        ret.roomsCount = ret.roomsCount && +ret.roomsCount;
        ret.childrenCount = ret.childrenCount && +ret.childrenCount;
        if ( ret.childAges ) {
            ret.childAges = Array.isArray( ret.childAges ) ? ret.childAges.map( age => +age ) : [ +ret.childAges ];
        }

        const validAdultsCount = validateCount( ret.adultsCount );
        const validRoomsCount = validateCount( ret.roomsCount );
        const validChildrenCount = validateCount ( ret.childrenCount ) || ret.childrenCount === 0;
        const validChildAges = ( !ret.childAges && ret.childrenCount === 0 ) ||
                    validChildrenCount && validateChildAges ( ret.childAges ) && ret.childrenCount === ret.childAges.length;

        validation = validation &&
                     validAdultsCount &&
                     validRoomsCount &&
                     validChildrenCount &&
                     validChildAges;
    }

    return validation;
}