<template>
    <TicketCardVariant6
        :ticket="ticket"
        :quantity="quantity"
        :image-width="imageWidth"
        :image-height="imageHeight"
        class="gst-event-ticket-detail-sidebar-ticket-image">
        <template slot="actions">
            <div class="d-flex align-center">
                <BaseButton
                    :placeholder="$t('_common:buttons.change')"
                    class="gst-event-ticket-detail-sidebar-ticket-image__change-btn mr-4"
                    @click="$emit('change')" />
            </div>
        </template>
    </TicketCardVariant6>
</template>

<script>
    import BaseButton from '@core/shared/components/buttons/BaseButton.vue';
    import TicketCardVariant6 from '@tenant/app/components/cards/TicketCardVariant6';

    export default {
        name:  'SelectedEventTicketCard',
        components: {
            BaseButton,
            TicketCardVariant6
        },
        props: {
            ticket: {
                type: Object,
                required: true
            },
            quantity: {
                type: Number,
                default: 1
            },
            hasRemoveButton: {
                type: Boolean,
                default: false
            },
            imageWidth: {
                type: [ String, Number ],
                default: null
            },
            imageHeight: {
                type: [ String, Number ],
                default: null
            }
        },
    };
</script>

<style lang="scss">
    @import '@scssVariables';
    @import '@scssMixins';

    .gst-event-ticket-detail-sidebar-ticket-image {
        border-top: 1px solid theme-color-hex( 'senary-darken-2' );
        border-bottom: 1px solid theme-color-hex( 'senary-darken-2' );

        .gst-event-ticket-detail-sidebar-ticket-image__change-btn {
            background-color: theme-color( 'white' ) !important;
            color: theme-color( 'primary' ) !important;
            font-size: font-size( 'xxs' );
            font-weight: font-weight( 'large' );
            text-transform: capitalize;
        }
    }
</style>