<template>
    <div class="gst-event-content-tickets-list-container">
        <InfiniteCardList
            :data="ticketsWithOffers"
            :config="eventTicketCardConfig"
            :loading="loadingTickets"
            :all-data-loaded="tickets.length >= totalTickets"
            :has-load-button="false"
            :prefill="true"
            item-classes="gst-event-content-list__item px-0 mx-2"
            list-classes="pt-2"
            class="gst-event__list u-height-100 u-width-100 px-0"
            @mouse-enter-item="$emit('highlight-ticket', $event )"
            @mouse-leave-item="$emit('highlight-ticket', null )"
            @click-item="$emit('click-item', $event)"
            @load-more="$emit('load-more')">
            <template slot="loading">
                <DataLoading v-if="loadingTickets" class="mt-5 mb-5 pt-5 pb-5" />
            </template>
            <template slot="empty-list">
                <EventTicketListEmpty v-if="!loadingTickets && !ticketsWithOffers.length" />
            </template>
        </InfiniteCardList>
    </div>
</template>

<script>
    import DataLoading from '@core/shared/components/loading/DataLoading';
    import InfiniteCardList from '@core/shared/components/lists/InfiniteCardList';
    import TicketCardVariant5 from '@tenant/app/components/cards/TicketCardVariant5';
    import EventTicketListEmpty from './EventTicketListEmpty';

    export default {
        name: 'EventTicketList',
        components: {
            DataLoading,
            InfiniteCardList,
            // eslint-disable-next-line vue/no-unused-components
            TicketCardVariant5,
            EventTicketListEmpty
        },
        testIdOptions: {
            keyPrefix: 'event'
        },
        props: {
            event: {
                type: Object,
                required: true
            },
            tickets: {
                type: Array,
                default: null,
            },
            totalTickets: {
                type:Number,
                default: 0
            },
            ticketsWithOffers: {
                type: Array,
                default: null
            },
            loadingTickets: {
                type: Boolean,
                default: false
            },
            selectedTicket: {
                type: Object,
                default: null,
            },
            selectedTicketDetailInfo: {
                type: Object,
                default: null
            },
            sort: {
                type: Object,
                default: null
            },
            busEventParent: {
                type: Object,
                required: true
            }
        },
        computed: {
            eventTicketCardConfig( ) {
                return {
                    card: TicketCardVariant5,
                    cardClasses: 'u-width-100 u-background-transparent pt-2 pb-1',
                    propsToBind: ( item ) => {
                        const name = this.$options.filters.seatLocation( item );

                        return {
                            ticket: {
                                ...item,
                                name,
                                isSelected: item === this.selectedTicket
                            },
                            offers: item.offers,
                        };
                    }
                };
            },
            noTickets( ) {
                return '';
            }
        }
    };
</script>

<style lang="scss">
@import '@scssVariables';
@import '@scssMixins';

.gst-event-content-tickets-list-container {
    height: 100%;
    flex: 1;
    min-height: 0;
}

.gst-event-content-list__item {
    margin-bottom: theme-spacing( 2 );

    .gst-ticket-card-variant-5 {
        padding-bottom: 10px !important;

        svg {
            height: 18px;
            width: 18px;
            margin-right: theme-spacing( 1 );

            path {
                fill: theme-color( 'tertiary' );
            }
        }
    }
}

.gst-event-content-list__item:last-of-type {
    margin-bottom: 20px;
}

.gst-event-content-list__item::after,
.gst-event-content-list__item::before {
    content: none !important;
}

@include mobile-only {
    .gst-event__list {
        .v-list {
            padding-bottom: theme-spacing( 0 );
            margin-bottom: theme-spacing( n3 );
        }
    }
}
</style>