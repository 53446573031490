<template>
    <div class="gst-ticket-price-breakdown">
        ({{ basePriceFormatted }} + {{ feePriceFormatted }} {{ taxesText }})
    </div>
</template>

<script>
    export default {
        name: 'TicketPriceBreakdown',
        props: {
            basePrice: {
                type: Number,
                required: true
            },
            feePrice: {
                type: Number,
                required: true
            },
            hotelPrice: {
                type: Number,
                default: 0
            },
            currency: {
                type: String,
                required: true
            },
            allInclusivePricing: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            basePriceFormatted() {
                return this.$options.filters.currencyFilter( this.basePrice + this.hotelPrice, this.currency );
            },
            feePriceFormatted() {
                return this.$options.filters.currencyFilter( this.feePrice, this.currency );
            },
            taxesText() {
                return this.allInclusivePricing ? this.$t( '_common:terms.ticketTaxesIncluded' ) : this.$t( '_common:terms.ticketTaxesNotIncluded' );
            }
        }
    };
</script>