<template>
    <span class="gst-save-amount-card-chip">
        {{ $t( '_common:terms.hotelSaveAmountCard', { amount: saveAmount } ) }}
    </span>
</template>

<script>
    import currencyFilter from '@core/filters/currencyShortNoDigits';

    export default {
        name: 'SaveAmountCardChip',
        props: {
            amount: {
                type: Number,
                required: true
            },
            currency: {
                type: String,
                default: 'USD'
            }
        },
        computed: {
            saveAmount( ) {
                return currencyFilter( this.amount, this.currency );
            }
        }
    };
</script>

<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-save-amount-card-chip {
        height: 18px;
        line-height: 18px;
        padding: 0 8px;
        background-color: theme-color( 'success' );
        color: #FFFFFF;
        font-size: font-size( 'xxxs' );
        font-weight: font-weight( 'large' );
        border-radius: border-radius( 'xs' );
        text-transform: uppercase;
        text-wrap: nowrap;
    }
</style>