<template>
    <div v-if="!isHotelsTabActive" class="gst-event-ticket-detail-content d-flex flex-column flex-md-row u-width-100" :style="{ 'flex': 1, 'min-height': 0 }">
        <div v-if="$vuetify.breakpoint.mdAndUp" class="col col-md-8 col-lg-9 pa-0">
            <div :style="{'max-width' : '970px', 'margin': '0 auto'}" class="u-height-100">
                <EventTicketImage v-if="$vuetify.breakpoint.mdAndUp"
                    :venue-details="eventTicketVenueDetails"
                    :venue-seat-map-url="null"
                    :loading-tickets="loadingTickets" />
                <EventVenueImage v-else-if="selectedHotelReservation" :venue-details="eventTicketVenueDetails" />
            </div>
        </div>
        <div class="col col-md-4 col-lg-3 pa-0 ml-auto u-height-100" :style="{ 'min-height': 0 }">
            <EventSidebar
                class="gst-event-ticket-detail-content__sidebar u-position-relative"
                :disabled-button="!(canSubmit && !!selectedTicket )"
                :display-footer="isFooterDisplayed"
                :footer-title="$t( `_components.eventSidebar.footer.titleTicketsOnly` )"
                :submit-button-placeholder="$t( '_components.eventSidebar.buttons.next' )"
                :bundle-products="bundleProducts"
                :event="event"
                @submit="onSubmitDo">
                <EventTickets
                    :active-tab="activeTab"
                    :venue-details="eventTicketVenueDetails"
                    :filter="filter"
                    :filter-options="filterOptions.current"
                    :show-additional-filters="showAdditionalTicketFilters"
                    :event="event"
                    :tickets="tickets"
                    :total-tickets="totalTickets"
                    :tickets-with-offers="ticketsWithOffers"
                    :loading-tickets="loadingTickets"
                    :selected-ticket="selectedTicket"
                    :selected-hotel-reservation="selectedHotelReservation"
                    :show-tickets-few-available-warning="showEventTicketsStatusFewAvailableWarning"
                    :bus-event-parent="busEvent"
                    :selected-offers="selectedOffers"
                    :show-ticket-details="showTicketDetails"
                    @update-ticket-offers="setTicketAndOffers"
                    @changeTab="onChangeTabDo"
                    @highlight-ticket="setHighlightedTicket"
                    @load-more="loadTicketsDebounced"
                    @open-ticket-details="openEventTicketDetail"
                    @close-ticket-details="onCloseTicketDetailsDo"
                    @close-tickets-few-available-warning="showEventTicketsStatusFewAvailableWarning=false"
                    @update-filter="onUpdateFilterDo"
                    @reset-filter="onResetFilterDo"
                    @open-additional-filters="onOpenAdditionalFiltersDo"
                    @close-additional-filters="onCloseAdditionalFiltersDo"
                    @highlight-hotel-reservation="setHighlightedHotelReservation" />
            </EventSidebar>
        </div>
    </div>
    <div v-else class="d-flex flex-column flex-md-row u-width-100 u-height-100" :style="{ 'flex': 1, 'min-height': 0 }">
        <DataLoading v-if="showLoadingOnHotelDetail" class="d-flex u-width-100 u-height-100 align-center justify-center" />
        <div v-else-if="$vuetify.breakpoint.mdAndUp" class="col col-md-8 col-lg-9 px-0 py-0 u-height-100 u-position-relative">
            <ContainerWithOverlay
                :absolute="true"
                color="#FFFFFF"
                opacity="0.6"
                :overlay="showHotelFilter"
                class="gst-event-ticket-detail-content__hotel-detail">
                <HotelDetailsV2
                    v-if="highlightedHotelReservation || selectedHotelReservation"
                    :has-value="!!highlightedHotelReservation || !!selectedHotelReservation"
                    :item="!selectedHotelReservation ? highlightedHotelReservation : selectedHotelReservation.item"
                    :event="event"
                    :bundle-products="bundleProductsOnlyHotels"
                    :bus-events-parent="busEvent"
                    class="u-height-100 u-overflow-scroll-y mx-auto"
                    :style="{'max-width' : '970px'}" />
                <HotelDetailsEmptyState v-else />
            </ContainerWithOverlay>
        </div>
        <div class="col col-md-4 col-lg-3 pa-0 ml-auto u-height-100" :style="{ 'min-height': 0 }">
            <EventSidebar
                class="u-position-relative"
                :disabled-button="!canSubmit"
                :display-footer="isFooterDisplayed"
                :footer-title="$t( `_components.eventSidebar.footer.titleTicketsOnly` )"
                :submit-button-placeholder="$t( '_components.eventSidebar.buttons.next' )"
                :bundle-products="bundleProducts"
                :event="event"
                @submit="onSubmitDo">
                <EventHotels
                    :event="event"
                    :selected-hotel-reservation="selectedHotelReservation"
                    :selected-ticket="selectedTicket"
                    :bus-events-parent="busEvent"
                    :bundle-products="bundleProductsOnlyHotels"
                    :highlighted-hotel-reservation="highlightedHotelReservation"
                    :tickets-quantity="ticketsQuantityForHotels"
                    @change-show-filter="onChangeShowFilterHotelDo"
                    @change-event-ticket="changeEventTicket"
                    @select-hotel-reservation="selectHotelReservation"
                    @highlight-hotel-reservation="setHighlightedHotelReservation" />
            </EventSidebar>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { isEqual } from 'date-fns';
    import  isEqualObjects  from 'lodash/isEqual';
    import cloneDeep from 'lodash/cloneDeep';
    import { mapActions, mapState, mapGetters } from 'vuex';
    import debounce from 'lodash/debounce';
    import { sortCompare } from '@core/utils/sortCompareUtils';
    import { isNumber } from '@core/utils/numberUtils';
    import apiConstants from '@core/utils/constants/api';
    import searchConstants from '@core/utils/constants/search';
    import ticketsConstants from '@core/utils/constants/ticket';
    import listConstants from '@core/utils/constants/list';
    import ContainerWithOverlay from '@core/shared/components/overlays/ContainerWithOverlay.vue';
    import {
        stopBrowserBack as browserUtilsStopBrowserBack,
    } from '@core/utils/browserUtils';
    import DataLoading from '@core/shared/components/loading/DataLoading.vue';
    import eventConstants from '@core/utils/constants/event';
    import {
        isAfter as dateUtilsIsAfter,
        differenceInSeconds as dateUtilsDifferenceInSeconds
    } from '@tenant/app/utils/dateUtils';
    import { create as CreateBundleProductsModel } from '@tenant/app/modelsV2/BundleProductsModel';
    import { create as CreateBundleProductsHotelReservationModel } from '@tenant/app/modelsV2/BundleProductsHotelReservationModel';
    import { create as CreateBundleProductsEventTicketModel } from '@tenant/app/modelsV2/BundleProductsEventTicketModel';
    import HotelDetailsV2 from '@tenant/app/modules/hotel/components/containers/HotelDetailsV2.vue';
    import HotelDetailsEmptyState from '@tenant/app/modules/hotel/features/addHotelReservation/AddHotelReservation/HotelDetailsEmptyState.vue';
    import EventSidebar from './_components/EventSidebar.vue';
    import EventTicketImage from './_components/EventTicketImage.vue';
    import EventVenueImage from './_components/EventVenueImage.vue';
    import EventHotels from './EventContent/EventHotels.vue';
    import EventTickets from './EventContent/EventTickets.vue';

    const EVENT_TABS = {
        HOTELS: 'hotels',
        TICKETS: 'tickets',
    };

    const STORE_NAME = 'events';
    const CONFIG_TICKETS = {
        LIMIT: searchConstants.DEFAULT.LIMIT,
        FILTER_DEFAULT: {
            QUANTITY: 2,
            MIN_QUANTITY: 1,
            MAX_QUANTITY: 50,
            PRICE: {
                MIN: 0,
                MAX: 5000,
                CURRENCY: ''
            },
            ACCESSIBILITY: false,
        }
    };

    export default {
        name: 'EventContent',
        components: {
            EventSidebar,
            EventTicketImage,
            EventVenueImage,
            EventTickets,
            EventHotels,
            HotelDetailsV2,
            ContainerWithOverlay,
            DataLoading,
            HotelDetailsEmptyState,
        },
        props: {
            activeTab: {
                type: String,
                required: true
            },
            event: {
                type: Object,
                required: true
            },
            busEventParent: {
                type: Object,
                default: null
            }
        },
        data( ) {
            return {
                busEvent: new Vue( ),
                bundleProducts: CreateBundleProductsModel( ),
                selectedHotelReservation: null,
                highlightedHotelReservation: null,
                showHotelFilter: false,
                selectedTicket: null,
                highlightedTicket : null,
                venueDetails: {
                    mapUrl: '',
                    mapWithlabelsUrl: '',
                },
                showAdditionalTicketFilters: false,
                filter: {
                    quantity: CONFIG_TICKETS.FILTER_DEFAULT.QUANTITY,
                    price: {
                        range: [ CONFIG_TICKETS.FILTER_DEFAULT.PRICE.MIN, CONFIG_TICKETS.FILTER_DEFAULT.PRICE.MAX ],
                        min: CONFIG_TICKETS.FILTER_DEFAULT.PRICE.MIN,
                        max: CONFIG_TICKETS.FILTER_DEFAULT.PRICE.MAX,
                        currency: CONFIG_TICKETS.FILTER_DEFAULT.PRICE.QUANTITY
                    },
                    accessibility: CONFIG_TICKETS.FILTER_DEFAULT.ACCESSIBILITY
                },
                filterOptions: {
                    current: {
                        accessibilityEnabled: false,
                        minQuantity: CONFIG_TICKETS.FILTER_DEFAULT.MIN_QUANTITY,
                        maxQuantity: CONFIG_TICKETS.FILTER_DEFAULT.MAX_QUANTITY
                    },
                    noAccesability: {
                        minQuantity: CONFIG_TICKETS.FILTER_DEFAULT.MIN_QUANTITY,
                        maxQuantity: CONFIG_TICKETS.FILTER_DEFAULT.MAX_QUANTITY
                    },
                    accessibility: {
                        minQuantity: CONFIG_TICKETS.FILTER_DEFAULT.MIN_QUANTITY,
                        maxQuantity: CONFIG_TICKETS.FILTER_DEFAULT.MAX_QUANTITY,
                        companionMinQuantity: CONFIG_TICKETS.FILTER_DEFAULT.MIN_QUANTITY,
                        companionMaxQuantity: CONFIG_TICKETS.FILTER_DEFAULT.MAX_QUANTITY
                    }
                },
                tickets: [],
                offersMap: {},
                ticketTypesMap: {},
                totalTickets: 0,
                loadingTickets: false,
                showEventTicketsStatusFewAvailableWarning: false,
                selectedOffers: [],
                showTicketDetails: false,
                ticketsQuantityForHotels: CONFIG_TICKETS.FILTER_DEFAULT.QUANTITY,
            };
        },
        computed: {
            ...mapState( {
                loadingHotels: state => state.addHotelReservationState.hotels.loading,
                hotels: state => state.addHotelReservationState.hotels.list,
                hotelFiltersAdultsCount: state => state.addHotelReservationState.hotels.filters.guests.adultsCount,
                hotelFilters: state => state.addHotelReservationState.filters
            } ),
            ...mapGetters( {
                fanSessionResaleId: 'tickets/getFanSessionResaleId'
            } ),
            ticketsWithOffers( ) {
                return this.tickets.filter( ticket => ticket.offers.length );
            },
            isHotelsTabActive( ) {
                return this.activeTab === EVENT_TABS.HOTELS;
            },
            isFooterDisplayed() {
                return !!( this.selectedHotelReservation || this.selectedTicket ) && !this.showHotelFilter;
            },
            canSubmit( ) {
                return !!this.selectedTicket || !!this.selectedHotelReservation;
            },
            ticketsQuantity( ) {
                if ( this.selectedOffers.length ) {
                    return this.selectedOffers.reduce( ( acc, { quantity } ) => {
                        return acc + quantity;
                    }, 0 );
                }

                return this.filter.quantity;
            },
            ticketsTotalBasePrice( ) {
                if ( this.selectedOffers.length ) {
                    return this.selectedOffers.reduce( ( acc, { offer, quantity } ) => {
                        return acc + offer.basePrice * quantity;
                    }, 0 );
                }

                return 0;
            },
            ticketsTotalPrice( ) {
                if ( this.selectedOffers.length ) {
                    return this.selectedOffers.reduce( ( acc, { offer, quantity } ) => {
                        return acc + offer.totalPrice * quantity;
                    }, 0 );
                }

                return 0;
            },
            bundleProductsOnlyHotels( ) {
                return CreateBundleProductsModel(
                    CreateBundleProductsEventTicketModel(
                        { ...this.event },
                        null,
                        null,
                        null,
                        0,
                        0
                    ),
                    this.selectedHotelReservation ? CreateBundleProductsHotelReservationModel( this.selectedHotelReservation.item ) : null
                );
            },
            isEventTicketsStatusFewAvailable( ) {
                return this.event.ticketsStatus === eventConstants.TICKETS_STATUS.FEW_AVAILABLE;
            },
            eventTicketVenueDetails( ) {
                const ticket = this.selectedTicket || this.highlightedTicket;

                const ret = {
                    mapUrl: ticket ? ticket.venueSeatMapUrl : this.venueDetails.mapUrl,
                    mapWithlabelsUrl: ticket ? ticket.venueSeatMapUrl : this.venueDetails.mapWithlabelsUrl,
                    defaultMapUrl: this.venueDetails.mapUrl,
                    defaultMapWithlabelsUrl: this.venueDetails.mapWithlabelsUrl,
                };


                return ret;
            },
            showLoadingOnHotelDetail( ) {
                return this.loadingHotels && !this.hotels.length && !this.highlightedHotelReservation && this.$vuetify.breakpoint.mdAndUp;
            }
        },
        watch: {
            'showAdditionalTicketFilters': function ( value ) {
                if ( value && !this.$vuetify.breakpoint.mdAndUp ) {
                    this.openTicketAdditionalFiltersModal( );
                }
            },
            'showTicketDetails': function ( value ) {
                if ( value && !this.$vuetify.breakpoint.mdAndUp ) {
                    this.addListenerOnBrowserBack( );
                }
            },
            '$vuetify.breakpoint.mdAndUp': function ( value ) {
                if ( value ) {
                    this._listenerBrowserBack && this._listenerBrowserBack( );
                } else {
                    if ( this.showTicketDetails ) {
                        this.addListenerOnBrowserBack( );
                    }
                }
            },
            'activeTab': function ( value ) {
                if ( value === EVENT_TABS.TICKETS ) {
                    this.showTicketDetails = false;
                }
            },
            'selectedHotelReservation': function ( value ) {
                this.$emit( 'selected-hotel-reservation', value );
            },
        },
        methods: {
            ...mapActions( {
                notificationError               : 'notification/error',
                loadOne                         : `${STORE_NAME}/one`,
                loadEventTicketsAvailability    : `tickets/availability`,
                loadEventTickets                : 'tickets/get',
                loadHotels                      : `addHotelReservationState/hotels/loadPage`,
            } ),
            onSubmitDo( ) {
                this.openEventReviewPurchaseModal( );
            },
            openEducationalWizard( ) {
                this.$router.push( {
                    name: 'educationalWizard',
                    params: {
                    }
                } );
            },
            initFiltersFromQueryParams( ) {
                const { t_quantity, t_accessibility, t_priceFrom, t_priceTo } = this.$route.query;

                const quantity = t_quantity && +t_quantity;
                const priceFrom = t_priceFrom && +t_priceFrom;
                const priceTo = t_priceTo && +t_priceTo;
                const isValidPrice = ( price ) => {
                    return isNumber( price )
                        && price >= CONFIG_TICKETS.FILTER_DEFAULT.PRICE.MIN
                        && price <= CONFIG_TICKETS.FILTER_DEFAULT.PRICE.MAX;
                };

                if ( isNumber( quantity )
                    && quantity >= CONFIG_TICKETS.FILTER_DEFAULT.MIN_QUANTITY
                    && quantity <= CONFIG_TICKETS.FILTER_DEFAULT.MAX_QUANTITY ) {
                    this.filter.quantity = quantity;
                }

                if ( t_accessibility ) {
                    this.filter.accessibility = t_accessibility === 'true';
                }

                if ( isValidPrice( priceFrom ) && isValidPrice( priceTo ) ) {
                    if ( priceFrom <= priceTo ) {
                        this.filter.price.range = [ priceFrom, priceTo ];
                    } else {
                        this.filter.price.range = [ priceTo, priceFrom ];
                    }
                }
            },
            setFilterQueryParams( ) {
                const params = {
                    t_quantity: this.filter.quantity,
                    t_accessibility: this.filter.accessibility,
                    t_priceFrom: this.filter.price.range[0],
                    t_priceTo: this.filter.price.range[1]
                };

                if ( !isEqual( params, this.$route.query ) ) {
                    this.$router.replace( {
                        ...this.$route,
                        query:{
                            ...this.$route.query,
                            ...params
                        }
                    } );
                }
            },
            onUpdateFilterDo( filter ) {
                this.filter = cloneDeep( filter );
                this.setFilterQueryParams( );
            },
            onResetFilterDo( ) {
                this.filter.quantity = CONFIG_TICKETS.FILTER_DEFAULT.QUANTITY;
                this.filter.price.range = [ this.filter.price.min, this.filter.price.max ];
                this.filter.accessibility = CONFIG_TICKETS.FILTER_DEFAULT.ACCESSIBILITY;
                this.setFilterQueryParams( );
            },
            onOpenAdditionalFiltersDo( ) {
                this.showAdditionalTicketFilters = !this.showAdditionalTicketFilters;
            },
            onCloseAdditionalFiltersDo( ) {
                this.showAdditionalTicketFilters = false;
            },
            openTicketAdditionalFiltersModal( ) {
                this.$modal.showBottom(
                    ( ) => import( './EventContent/EventTickets/EventTicketFilterModal' ),
                    {
                        value: this.filter,
                        options: this.filterOptions.current,
                        busEventParent: this.busEvent,
                    },
                    {
                        scrollable: false,
                        fullscreen: false,
                        'hide-overlay': false
                    },
                    {
                        beforeClose: ( ) => {
                            this.showAdditionalTicketFilters = false;
                        }
                    }
                );
            },
            openEventReviewPurchaseModal( ) {
                const modalConfigDesktop = {
                    'no-click-animation': true,
                    scrollable: true,
                    fullscreen: false,
                    'hide-overlay': false,
                    'max-width': 600,
                    'content-class': 'gst-v-dialog',
                };

                const modalConfigMobile = {
                    'content-class': 'gst-v-dialog',
                    scrollable: true,
                    fullscreen: false,
                    'hide-overlay': false,
                    transition: 'dialog-bottom-transition'
                };

                const modalComponent = import( './EventContent/EventReviewPurchaseModal' );
                const modalComponentProps = {
                    event: this.event,
                    selectedHotelReservation: this.selectedHotelReservation,
                    selectedTicket: this.selectedTicket,
                    ticketsQuantity: this.ticketsQuantity,
                    ticketsTotalBasePrice: this.ticketsTotalBasePrice,
                    busEventParent: this.busEvent,
                    bundleProducts: this.bundleProducts,
                    eventTabs: EVENT_TABS,
                    footerTitle: this.$t( `_components.eventSidebar.footer.titleTicketsOnly` ),
                };

                if( this.$vuetify.breakpoint.mdAndUp ) {
                    this.$modal.show( modalComponent, modalComponentProps, modalConfigDesktop );
                } else {
                    this.$modal.showBottom( modalComponent, modalComponentProps, modalConfigMobile );
                }
            },
            openEventTicketDetail( ) {
                this.showTicketDetails = true;
            },
            changeEventTicket( ) {
                this.setTicketAndOffers ( );
                this.changeTab( EVENT_TABS.TICKETS );
                this.showTicketDetails = false;
            },
            async loadData( ) {
                this.loading = true;

                if ( this.event ) {
                    if ( !this.isEventTicketsNotAvailable ) {
                        this.loading = false;

                        !this.needToShowConfirmationHealtCheck || this.openHealthCheckConfirmationModal( );
                        if ( this.isEventTicketsStatusFewAvailable ) {
                            this.showEventTicketsStatusFewAvailableWarning = true;
                            this.filter.quantity = 1;
                        }

                        const responseTickets = await this.loadTickets( true );

                        if ( responseTickets && responseTickets.success ) {
                            await this.loadTicketsAvailability( );
                        }

                        const getMaxPrice = ( maxPrice ) => {
                            const ret = parseFloat( maxPrice );

                            return ret === Math.floor( ret ) ? ret : Math.floor( ret ) + 1;
                        };

                        this.filter.price.min = 0;
                        this.filter.price.max = this.event.maxPrice ? getMaxPrice( this.event.maxPrice ) : 0;
                        if ( this.filter.price.range[0] < this.filter.price.min ) {
                            this.filter.price.range[0] = this.filter.price.min;
                        }
                        if ( this.filter.price.range[1] > this.filter.price.max ) {
                            this.filter.price.range[1] = this.filter.price.max;
                        }
                        this.filter.price.currency = this.event.currency;

                        this.addFilterWatchers( );
                    } else {
                        this.deferReloadFutureOnSaleDate( );
                        this.loading = false;
                    }
                }
            },
            setHighlightedTicket( ticket ) {
                this.highlightedTicket = ticket;
            },
            selectHotelReservation( hotelReservation ) {
                this.selectedHotelReservation = hotelReservation;

                this.bundleProducts = CreateBundleProductsModel(
                    this.selectedTicket ? CreateBundleProductsEventTicketModel(
                        { ...this.event },
                        { ...this.selectedTicket },
                        this.selectedTicket.section,
                        this.ticketsQuantity,
                        this.ticketsTotalBasePrice,
                        this.ticketsTotalBasePrice,
                        this.ticketsTotalPrice
                    ) : null,
                    this.selectedHotelReservation ? CreateBundleProductsHotelReservationModel( hotelReservation.item ) : null
                );
            },
            setHighlightedHotelReservation( hotelReservation ) {
                this.highlightedHotelReservation = hotelReservation;
            },
            async loadTicketsAvailability( ) {
                const response = await this.loadEventTicketsAvailability( { eventId: this.event.id } );

                if ( response.success ) {
                    if ( response.data && response.data.accessibility ) {
                        const { accessibility } = response.data;

                        this.filterOptions.current.accessibilityEnabled         = accessibility.enabled;
                        this.filterOptions.accessibility.minQuantity            = accessibility.minLimit;
                        this.filterOptions.accessibility.maxQuantity            = accessibility.maxLimit;
                        this.filterOptions.accessibility.companionMinQuantity   = accessibility.companionMinLimit;
                        this.filterOptions.accessibility.companionMaxQuantity   = accessibility.companionMaxLimit;
                    }
                }
            },
            addFilterWatchers( ) {
                const addForQuantity = ( ) => {
                    !this._watchFilterQuantity || this._watchFilterQuantity( );
                    this._watchFilterQuantity = this.$watch( 'filter.quantity', ( ) => {
                        this.loadTicketsDebounced( true );
                    } );
                };

                const addForPriceRange = ( ) => {
                    !this._watchFilterPriceRange ||  this._watchFilterPriceRange( );
                    this._watchFilterPriceRange = this.$watch( 'filter.price.range', ( newValue, oldValue ) => {
                        if ( newValue[ 0 ] !== oldValue[ 0 ] || newValue[ 1 ] !== oldValue[ 1 ] ) {
                            this.loadTicketsDebounced( true );
                        }
                    } );
                };
                const addForAccessibility = ( ) => {
                    !this._watchFilterAccessibility || this._watchFilterAccessibility( );
                    this._watchFilterAccessibility = this.$watch( 'filter.accessibility', ( value ) => {
                        const { current, accessibility, noAccesability } = this.filterOptions;
                        const { quantity } = this.filter;

                        if ( value ) {
                            this.filterOptions.current.minQuantity = accessibility.minQuantity;
                            this.filterOptions.current.maxQuantity = accessibility.maxQuantity;
                        } else {
                            this.filterOptions.current.minQuantity = noAccesability.minQuantity;
                            this.filterOptions.current.maxQuantity = noAccesability.maxQuantity;
                        }
                        if ( quantity >= current.minQuantity && quantity <=current.maxQuantity ) {
                            this.loadTicketsDebounced( true );
                        } else {
                            this.filter.quantity = CONFIG_TICKETS.FILTER_DEFAULT.QUANTITY <= current.maxQuantity ? Math.max( current.minQuantity, CONFIG_TICKETS.FILTER_DEFAULT.QUANTITY ) : current.minQuantity;
                        }
                    } );
                };

                addForQuantity( );
                addForPriceRange( );
                addForAccessibility( );
            },
            removeFilterWatchers( ) {
                !this._watchFilterQuantity || this._watchFilterQuantity( );
                !this._watchFilterPriceRange ||  this._watchFilterPriceRange( );
                !this._watchFilterAccessibility || this._watchFilterAccessibility( );
            },
            loadTicketsDebounced: debounce( function( refresh = false ) {
                this.loadTickets( refresh );
            }, listConstants.DEBOUNCE_DELAY ),
            async loadTickets( refresh = false ) {
                const clearData = ( ) => {
                    this.tickets = [];
                    this.offersMap = {};
                    this.ticketTypesMap = {};
                    this.highlightedTicket = null;
                    this.setTicketAndOffers( );
                };

                const getSearchParams = ( ) => {
                    const { price, accessibility, quantity } = this.filter;
                    const ret = {
                        offset: refresh ? 0 : this.tickets.length,
                        limit: CONFIG_TICKETS.LIMIT,
                        quantity,
                    };

                    if ( price.range[0] > price.min || price.range[1] < price.max ) {
                        ret.priceRange = price.range;
                    }
                    if ( accessibility ) {
                        ret.accessibility = accessibility;
                    }

                    return ret;
                };
                const updateDataFromResponse = ( responseTickets, accessibility ) => {
                    const updateFilterPriceRangeFromTicketOffers = ( offers ) => {
                        const getNewMaxPriceFromOffers = ( ) => {
                            return offers.reduce( ( maxPrice, item ) => {
                                let basePrice = parseFloat( item.basePrice );

                                basePrice = basePrice === Math.floor( basePrice ) ? basePrice : Math.floor( basePrice ) + 1;

                                return basePrice > maxPrice ? basePrice : maxPrice;

                            }, this.filter.price.max );
                        };
                        const maxPrice = getNewMaxPriceFromOffers( );
                        if ( maxPrice > this.filter.price.max ) {
                            if ( this.filter.price.max === this.filter.price.range[ 1 ] ) {
                                this.removeFilterWatchers( );
                                this.filter.price.range = [ this.filter.price.range[ 0 ], maxPrice ];
                                this.addFilterWatchers( );
                            }
                            this.filter.price.max = maxPrice;
                        }
                    };
                    const updateFilterQuantityRangeNoAccesibilityFromTicketOffers = ( offers ) => {
                        const getNewMaxQuantityFromOffers = ( ) => {
                            return offers.reduce( ( max, item ) => {
                                let maxLimit = parseFloat( item.limitMax );

                                return maxLimit > max ? maxLimit : max;

                            }, this.filterOptions.noAccesability.maxQuantity );
                        };

                        const maxQuantity = getNewMaxQuantityFromOffers( );
                        if ( maxQuantity > this.filterOptions.noAccesability.maxQuantity ) {
                            this.filterOptions.noAccesability.maxQuantity = maxQuantity;
                            this.filterOptions.current.maxQuantity = maxQuantity;
                        }
                    };

                    this.ticketTypesMap = {
                        ...this.ticketTypesMap,
                        ...( responseTickets.ticketTypes.reduce( ( acc, item ) => {
                            acc[ item.id ] = item;

                            return acc;
                        }, { } ) )
                    };

                    this.offersMap = {
                        ...this.offersMap,
                        ...( responseTickets.offers.reduce( ( acc, { pointsEarned, ...rest } ) => {
                            acc[rest.id] = {
                                ...rest,
                                bonusPoints: pointsEarned || 0,
                                ticketType: rest.id ? this.ticketTypesMap[ rest.id ] : null
                            };

                            return acc;
                        }, { } ) )
                    };


                    const normalizeTicketItem = ( item ) => {
                        const ticket = {
                            ...item,
                            offersIds: item.offers,
                            offers: item.offers
                                .map( offerId => {
                                    const offer = this.offersMap[ offerId ];

                                    if ( !offer ) {
                                        return;
                                    }

                                    return {
                                        ...offer,
                                        ticketType: this.ticketTypesMap[ offer.ticketTypeId ]
                                    };
                                } )
                                .filter( offer => offer )
                        };

                        if ( item.selection === ticketsConstants.TYPES.RESALE ) {
                            ticket.offers.sort( ( a, b ) => sortCompare( a.basePrice, b.basePrice ) );
                        }
                        return ticket;
                    };

                    this.tickets = [
                        ...this.tickets,
                        ...( responseTickets.tickets.items.map ( normalizeTicketItem ) )
                    ];

                    this.totalTickets = responseTickets.tickets.totalCount;

                    updateFilterPriceRangeFromTicketOffers( responseTickets.offers );
                    accessibility || updateFilterQuantityRangeNoAccesibilityFromTicketOffers( responseTickets.offers );
                    if ( !this.venueDetails.mapUrl ) {
                        Vue.set( this.venueDetails, 'mapUrl', responseTickets.eventDetails.venueMapUrl );
                        Vue.set( this.venueDetails, 'mapWithlabelsUrl', responseTickets.eventDetails.venueMapWithLabelsUrl );
                    }
                };

                if ( isEqual ( this.lastAppliedFilter, { ...this.filter } ) && refresh ) {
                    return;
                }

                this.loadingTickets = true;
                !refresh || clearData( );

                this.lastAppliedFilter = cloneDeep( { ...this.filter } );

                const search = cloneDeep( getSearchParams() );
                const accessibility = this.filter.accessibility;
                const responseTickets = await this.loadEventTickets( {
                    eventId: this.event.id,
                    refresh,
                    search,
                    userAnalytics: this.userAnalytics
                }, );
                const currentSearch = getSearchParams( );

                if ( !isEqualObjects( responseTickets.search, currentSearch ) ) {
                    return;
                }

                if ( responseTickets.success ) {
                    if ( responseTickets.data.hint?.code === apiConstants.WARNING_CODES.EVENT_PROVIDER.FAN_SESSION_RESALE_EXPIRED ) {
                        this.notificationError( {
                            content: this.$t( '_common:messages.eventTicketStatus.sessionChange.message' ),
                            autoDismiss: false
                        } );
                        this.loadTickets( true );
                    } else {
                        updateDataFromResponse ( responseTickets.data, accessibility );
                    }
                } else {
                    if ( responseTickets.code && responseTickets.code === apiConstants.ERROR_CODES.EVENT_PROVIDER.SYSTEM_DOWN ) {
                        this.isEventTicketsMaintenance = true;
                    }
                }

                this.loadingTickets = responseTickets.cancel ? this.loadingTickets : false;
                this.userAnalytics = true;

                return responseTickets;
            },
            deferReloadFutureOnSaleDate( ) {
                const now = new Date( );
                const { onSaleStartDateTimeAt } = this.event;

                if ( onSaleStartDateTimeAt && dateUtilsIsAfter( onSaleStartDateTimeAt, now ) ) {
                    const delay = Math.min( ( dateUtilsDifferenceInSeconds( onSaleStartDateTimeAt, now ) * 1000 ), ( Math.pow( 2, 31 ) - 1 ) );

                    this.deferReload = setTimeout(
                        ( ) => {
                            this.loadData( );
                        },
                        delay
                    );
                }
            },
            onCloseTicketDetailsDo( ) {
                this.showTicketDetails = false;
            },
            setTicketAndOffers( { ticket = null, offers = [ ], quantity, totalBasePrice, totalPrice } = { ticket: null } ) {
                this.selectedTicket = ticket;
                this.selectedOffers = offers;

                if ( ticket ) {
                    this.bundleProducts = CreateBundleProductsModel(
                        CreateBundleProductsEventTicketModel(
                            { ...this.event },
                            this.selectedTicket ? { ...this.selectedTicket } : null,
                            this.selectedTicket ? this.selectedTicket.section : null,
                            quantity,
                            totalBasePrice,
                            totalBasePrice,
                            totalPrice
                        ),
                        this.selectedHotelReservation ? CreateBundleProductsHotelReservationModel( this.selectedHotelReservation.item ) : null
                    );
                } else {
                    this.bundleProducts = CreateBundleProductsModel(
                        null,
                        this.selectedHotelReservation ? CreateBundleProductsHotelReservationModel( this.selectedHotelReservation.item ) : null
                    );
                }

            },
            onChangeTabDo( value ) {
                this.changeTab( value );
            },
            onGoToCheckoutDo( ) {
                const { event, selectedTicket, selectedHotelReservation, venueDetails } = this;

                const offersPayload = this.selectedOffers
                    .map( item => {
                        return {
                            name: item.offer.name,
                            ticketTypeId: item.offer.ticketTypeId,
                            quantity: item.quantity,
                            basePrice: item.offer.basePrice,
                            totalPrice: item.offer.totalPrice
                        };
                    } );

                let payload = {
                    ticket: {
                        eventId:                event.id,
                        offers:                 offersPayload,
                        seats:                  selectedTicket.seats,
                        row:                    selectedTicket.row,
                        section:                selectedTicket.section,
                        accessibility:          selectedTicket.accessibility,
                        selection:              selectedTicket.selection,
                        venueSeatMapUrl:        selectedTicket.venueSeatMapUrl,
                        venueMapUrl:            venueDetails.mapUrl,
                        quantity:               selectedTicket.quantity,
                        fanSessionResaleId:     this.fanSessionResaleId
                    }
                };
                if ( selectedHotelReservation ) {
                    payload = {
                        ...payload,
                        hotelReservation: {
                            hotel: selectedHotelReservation.item,
                            room:  selectedHotelReservation.item.selectedRoom,
                            accommodation: {
                                startDateTime:  selectedHotelReservation.startDateTime,
                                endDateTime:    selectedHotelReservation.endDateTime,
                                roomsCount:     selectedHotelReservation.roomsCount,
                                guests:         selectedHotelReservation.guests,
                            },
                        }
                    };
                }
                this.$emit( 'checkout', payload );
            },
            changeTab( value ) {
                this.$emit( 'changeTab', { tab: value } );
            },
            onChangeSelectedHotelDo( ) {
                this.changeTab( EVENT_TABS.HOTELS );
                this.selectHotelReservation( null );
            },
            onRemoveSelectedHotelDo( ) {
                this.selectHotelReservation( null );
                const quantity = this.bundleProducts.getQuantity( );

                if ( !quantity.tickets ) {
                    this.changeTab( EVENT_TABS.TICKETS );
                }
            },
            onChangeShowFilterHotelDo( value ) {
                this.showHotelFilter = value;
            },
            onSelectRoomDo( hotelReservation, filters ) {
                this.selectedHotelReservation = {
                    item: hotelReservation,
                    ...filters
                };

                this.bundleProducts.setHotelReservation( {
                    ...this.bundleProducts.hotelReservation,
                    hotel: hotelReservation
                } );
                this.bundleProducts.hotelReservation.setSelectedRoom( hotelReservation.selectedRoom );
            },
            updateSelectedHotelReservationSelectedRoomRate( newTotalPrice, oldTotalPrice, newGiftCardAmount, newRateId ) {
                const selectedHotelReservationWithNewSelectedRoomRate = cloneDeep( { ...this.selectedHotelReservation } );
                selectedHotelReservationWithNewSelectedRoomRate.item.selectedRoom.selectedRate.netPrice = newTotalPrice;
                selectedHotelReservationWithNewSelectedRoomRate.item.selectedRoom.selectedRate.totalPrice = newTotalPrice;
                selectedHotelReservationWithNewSelectedRoomRate.item.selectedRoom.selectedRate.totals.basePrice = newTotalPrice;
                selectedHotelReservationWithNewSelectedRoomRate.item.selectedRoom.selectedRate.oldTotalPrice = oldTotalPrice;
                if( !selectedHotelReservationWithNewSelectedRoomRate.item.selectedRoom.selectedRate.giftCard ) {
                    selectedHotelReservationWithNewSelectedRoomRate.item.selectedRoom.selectedRate.giftCard = {
                        amount: 0,
                        currency: selectedHotelReservationWithNewSelectedRoomRate.item.selectedRoom.selectedRate.currency
                    };
                }
                selectedHotelReservationWithNewSelectedRoomRate.item.selectedRoom.selectedRate.giftCard.amount = newGiftCardAmount;
                selectedHotelReservationWithNewSelectedRoomRate.item.selectedRoom.selectedRate.id = newRateId;
                this.selectHotelReservation( selectedHotelReservationWithNewSelectedRoomRate );
                this.setHighlightedHotelReservation( selectedHotelReservationWithNewSelectedRoomRate.item );
            },
            onEventHotelRoomRateChangedBackDo( ) {
                this.selectHotelReservation( null );

                if ( !this.isHotelsTabActive ) {
                    this.changeTab( EVENT_TABS.HOTELS );
                }

                this.loadHotels( {
                    refresh: true,
                    filters: this.hotelFilters,
                } );
            },
            onEventHotelRoomRateChangedNextDo( newTotalPrice, oldTotalPrice, newGiftCardAmount, newRateId ) {
                this.updateSelectedHotelReservationSelectedRoomRate( newTotalPrice, oldTotalPrice, newGiftCardAmount, newRateId );
                this.onGoToCheckoutDo( );
            },
            onEventFanSessionExpiredBackDo( ){
                this.selectHotelReservation( null );
                this.selectedTicket = null;

                if ( this.isHotelsTabActive ) {
                    this.changeTab( EVENT_TABS.TICKETS );
                }
            },
            addWatchForTicketsQuantityForHotels( ) {
                this._watchTicketQuantityForHotels = this.$watch( 'filter.quantity', ( value ) => {
                    this.ticketsQuantityForHotels = value;
                } );
                this._watchEventTabsHotelsForTicketQuantityForHotels = this.$watch( 'activeTab', ( value ) => {
                    if ( value === EVENT_TABS.HOTELS && this.ticketsQuantity !== CONFIG_TICKETS.FILTER_DEFAULT.QUANTITY ) {
                        this._watchTicketQuantityForHotels( );
                        this._watchEventTabsHotelsForTicketQuantityForHotels( );
                    }
                } );
            },
            addWatchForTicketsQuantityForHotelsGuests() {
                this._watchEventTabsHotelForChange = this.$watch( 'activeTab', value => {
                    if ( value === EVENT_TABS.HOTELS ) {
                        this._watchEventTabsHotelForChange();

                        this._watchEventTabsHotelForGuestsChange = this.$watch( 'activeTab', value => {
                            if ( value === EVENT_TABS.TICKETS ) {
                                if ( !this.selectedTicket && this.ticketsQuantity === CONFIG_TICKETS.FILTER_DEFAULT.QUANTITY ) {
                                    this.filter.quantity = this.hotelFiltersAdultsCount;
                                }
                            }
                        } );
                    }
                } );
            },
            addListenerOnBrowserBack( ) {
                this._listenerBrowserBack && this._listenerBrowserBack( );
                this._listenerBrowserBack = browserUtilsStopBrowserBack( ( ) => {
                    this.showTicketDetails = false;
                    window.history.back( );
                    this._listenerBrowserBack( );
                } );
            }
        },
        async mounted( ) {
            this.initFiltersFromQueryParams( );
            await this.loadData( );
            this.addWatchForTicketsQuantityForHotels( );
            this.addWatchForTicketsQuantityForHotelsGuests();
        },
        created( ) {
            this.busEvent.$on( 'reset-filter', this.onResetFilterDo );
            this.busEvent.$on( 'update-filter', this.onUpdateFilterDo );
            this.busEvent.$on( 'go-to-checkout', this.onGoToCheckoutDo );
            this.busEvent.$on( 'change-tab', this.onChangeTabDo );
            this.busEvent.$on( 'change-event-ticket', this.changeEventTicket );
            this.busEvent.$on( 'change-selected-hotel', this.onChangeSelectedHotelDo );
            this.busEvent.$on( 'remove-selected-hotel', this.onRemoveSelectedHotelDo );
            this.busEvent.$on( 'add-item', this.onSelectRoomDo );
            this.busEventParent.$on( 'event-hotel-room-rate-changed-back', this.onEventHotelRoomRateChangedBackDo );
            this.busEventParent.$on( 'event-hotel-room-rate-changed-next', this.onEventHotelRoomRateChangedNextDo );
            this.busEventParent.$on( 'event-fan-session-expired-back', this.onEventFanSessionExpiredBackDo );
        },
        destroyed( ) {
            this.busEvent.$off( 'reset-filter' );
            this.busEvent.$off( 'update-filter' );
            this.busEvent.$off( 'go-to-checkout' );
            this.busEvent.$off( 'change-tab' );
            this.busEvent.$off( 'change-event-ticket' );
            this.busEvent.$off( 'change-selected-hotel' );
            this.busEvent.$off( 'remove-selected-hotel' );
            this.busEvent.$off( 'add-item' );
            this.busEventParent.$off( 'event-hotel-room-rate-changed-back' );
            this.busEventParent.$off( 'event-hotel-room-rate-changed-next' );
            this.busEventParent.$off( 'event-fan-session-expired-back' );
            this._listenerBrowserBack && this._listenerBrowserBack( );

            this._destroyed = true;
        }
    };
</script>


<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";

@include desktop-only {
    .gst-event-ticket-detail-content {
        background-color: theme-color( 'quinary' );
    }

    .gst-event-ticket-detail-content__sidebar {
        background-color: white;
    }
}

.gst-event-ticket-detail-content__hotel-detail {
    .v-overlay {
        z-index: $z-index-event-page-over-layer + 1 !important;
    }
}
</style>
