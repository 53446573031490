<template>
    <div
        class="gst-base-form-select"
        :class="{
            'gst-base-form-select--error': hasError,
        }">
        <label
            v-if="label"
            :for="id"
            class="gst-base-form-input__label">
            {{ label }}
        </label>
        <div class="gst-base-form-select__wrapper u-position-relative">
            <CoreBaseSelect
                :id="id"
                :data-aria-label="label"
                :value="value"
                :has-error="hasError"
                v-bind="{ ...$attrs, ...$props }"
                class="gst-base-form-select__input"
                :data-test-id="dataTestId"
                @input="onInputDo"
                @focus="$emit('focus')"
                @blur="$emit('blur')" />
            <slot name="append">
                <ChevronDownIcon
                    class="gst-base-form-select__icon"
                    :class="{
                        'has-error': hasError,
                        'disabled': disabled
                    }" />
            </slot>
        </div>
        <div class="gst-base-form-select__errors-messages">
            {{ errorMessages.join( ' ' ) }}
        </div>
    </div>
</template>

<script>
    import ChevronDownIcon from '@core/shared/assets/icons/chevron_down.svg';
    import CoreBaseSelect from '@core/shared/components/input/BaseSelect.vue';
    import CoreBaseFormSelect from '@core/shared/components/input/BaseFormSelect.vue';

    export default {
        name: 'BaseFormSelect',
        extends: CoreBaseFormSelect,
        components: {
            CoreBaseSelect,
            ChevronDownIcon,
        }
    };
</script>

<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-base-form-select {
        position: relative;
        border: 1px solid theme-color( 'primary' );

        .gst-base-form-select__input {
            position: relative;
            height: 100%;
            width: 100%;
            color: theme-color( 'primary' );
            cursor: pointer;
            outline: none;
        }

        .gst-base-form-select__icon {
            display: inline-block;
            position: absolute;
            top: 0;
            right: 8px;
            bottom: 0;
            margin: auto;
            fill: theme-color( 'primary' );
            pointer-events: none;
        }
    }

    .gst-base-form-select.gst-base-form-select--error {
        .gst-base-form-select__input {
            border-color: theme-color( 'error' ) !important;
        }
    }

    // Variant select ( checkout )
    .gst-base-form-select.gst-base-form-select-variant {
        @include border-radius( 'xxs' );

        display: flex;
        position: relative;
        padding-bottom: 28px;
        border: none;
        flex-direction: column;

        .gst-base-form-input__label {
            position: relative;
            line-height: line-height( 'xl' );
            margin-bottom: theme-spacing( 2 );
            font-size: font-size( 's' );
            font-weight: font-weight( 'regular' );
        }

        .gst-base-form-select__input {
            @include border-radius( 'xxs' );

            height: 36px;
            width: 100%;
            padding-right: theme-spacing( 6 );
            padding-left: theme-spacing( 4 );
            border: 1px solid theme-color( 'septenary' );
            color: theme-color( 'quaternary' );
            font-size: font-size( l );
            box-shadow: inset 0 2px 4px rgba( theme-color-hex( 'black' ), 0.1 );
            outline: none;
        }

        .gst-base-form-select__icon {
            position: absolute;
            top: 50%;
            right: theme-spacing( 4 );
            bottom: auto;
            transform: translateY( -50% );
            pointer-events: none;
        }

        .gst-base-form-select__icon.has-error {
            path {
                fill: theme-color( 'error' );
            }
        }

        .gst-base-form-select__icon.disabled {
            path {
                fill: rgba( theme-color-hex( 'primary' ), 0.3 );
            }
        }

        .gst-base-form-select__errors-messages {
            position: absolute;
            top: calc( 100% - 28px );
            left: 12px;
            line-height: line-height( 's' );
            color: theme-color( 'error' );
            font-size: font-size( 'xxxs' );
        }
    }

    // Style for variant select ( children select )
    .gst-base-form-select.gst-base-form-select-variant-2 {
        border: none;

        .gst-base-form-select__input {
            color: theme-color( 'quaternary' ) !important;
        }

        .gst-base-form-select__input:hover {
            cursor: pointer;
            border-color: theme-color( 'tertiary' );
        }
    }
</style>