<template>
    <v-menu
        ref="menu"
        :value="isMenuOpen && searchFilter"
        offset-y
        :close-on-content-click="true"
        bottom
        :nudge-bottom="nudgeBottom"
        :max-height="500"
        :max-width="248"
        allow-overflow
        content-class="gst-location-search-dropdown__menu"
        @input="isMenuOpen=$event">
        <template v-slot:activator="{}">
            <!-- $vnode.data.staticClass only way to send class from grandparent to this parent -->
            <div
                ref="menuTarget"
                class="gst-location-search-dropdown"
                v-bind="{ class: $vnode.data.staticClass }">
                <label v-if="label" for="location-search-input" class="gst-location-search-dropdown__label">{{ label }}</label>
                <div
                    class="gst-location-search-dropdown__input-wrapper d-flex align-center"
                    :class="{
                        'form-input-error': hasError,
                        'form-input-focus': isActive && !hasError,
                        'gst-location-search-dropdown__input-wrapper--no-border': noBorder
                    }"
                    tabindex="0"
                    @click="onWrapperClickDo"
                    @keypress.enter="onWrapperClickDo">
                    <BaseIcon symbol-id="icons--locations--location" class="gst-location-search-dropdown__location-icon mr-2 ml-2 mt-auto mb-auto" />
                    <input
                        v-if="isActive"
                        id="location-search-input"
                        ref="input"
                        v-model="searchFilter"
                        class="gst-location-search-dropdown__input"
                        :disabled="disabled"
                        :placeholder="$t( 'placeholder' )"
                        :data-has-error="!!errorMessages.length"
                        @blur="onBlurDo"
                        @keyup="onKeyUpDo" />
                    <VClamp
                        v-else
                        autoresize
                        :max-lines="1"
                        class="gst-location-search-dropdown__selection"
                        :class="{
                            'gst-location-search-dropdown__selection--placeholder': !hasValue
                        }">
                        {{ selection }}
                    </VClamp>
                    <button v-if="!disabled && hasValue" class="gst-location-search-dropdown__clear-button d-flex mr-2 ml-2 mt-auto mb-auto" @click="clearSelection">
                        <BaseIcon symbol-id="icons--cancel" class="gst-location-search-dropdown__clear-icon" />
                    </button>
                </div>
                <div class="gst-location-search-dropdown__errors-messages">
                    {{ errorMessages.join( ' ' ) }}
                </div>
            </div>
        </template>
        <div
            class="gst-location-search-dropdown__content flex-grow-1 d-flex flex-column">
            <DataLoading v-if="loading" class="flex-grow-1 d-flex justify-center align-center" size="25" />
            <template v-else-if="locations.length">
                <DropDownItem v-for="(option, index) in locations" :key="index" :item="option" @select-option="selectOption(option)" />
            </template>
            <div v-else class="not-found flex-grow-1 d-flex justify-center align-center">
                {{ $t( '_common:messages.resultsNotFound' ) }}
            </div>
        </div>
    </v-menu>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import VClamp from 'vue-clamp';
    import debounce from 'lodash/debounce';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import InputConstants from '@core/utils/constants/input';
    import DataLoading from '@core/shared/components/loading/DataLoading.vue';
    import { locationParser } from '@tenant/app/utils/locations';
    import DropDownItem from './DropDownItem.vue';

    export default {
        name: 'LocationSearchStoreDropDown',
        components: {
            DropDownItem,
            BaseIcon,
            DataLoading,
            VClamp
        },
        emits: [ 'input', 'click' ],
        props: {
            value: {
                type: Object || undefined,
                required: true,
            },
            disabled: {
                type: Boolean,
                default: false
            },
            maxItem: {
                type: Number,
                default: 10
            },
            countryCodes: {
                type: Array,
                default: () => [ 'US', 'CA' ]
            },
            errorMessages: {
                type: Array,
                default: function ( ) {
                    return [ ];
                }
            },
            label: {
                type: String,
                default: ''
            },
            noBorder: {
                type: Boolean,
                default: false
            },
            nudgeBottom: {
                type: Number,
                default: -10
            }
        },
        i18nOptions: {
            namespaces: 'shared',
            keyPrefix: 'components.misc.locationSearchStoreDropDown'
        },
        data() {
            return {
                isActive: false,
                loading: false,
                searchFilter: '',
                isMenuOpen: true
            };
        },
        computed: {
            ...mapState( {
                locations:   state => state.locations.list,
                loadingStore:  state => state.locations.loading
            } ),
            selection() {
                if ( this.value && this.value.name ) {
                    return locationParser( this.value, true );
                }
                return this.$t( 'placeholder' );
            },
            hasError( ) {
                return !!this.errorMessages.length;
            },
            hasValue() {
                return !!this.value?.name;
            }
        },
        watch: {
            'searchFilter': function ( value ) {
                if ( value ) {
                    this.searchLocations( );
                    this.isMenuOpen = true;
                    this.loading = true;
                } else {
                    this.isMenuOpen = false;
                    this.loading = false;
                }
            },
            'isMenuOpen': {
                handler: function ( value ) {
                    if ( value  && !this.searchFilter ) {
                        this.isMenuOpen = false;
                    }
                },
                immediate: true
            },
            'loadingStore': function ( value ) {
                this.loading = value;
            },
            'isActive': function ( value ) {
                if ( value && this.searchFilter ) {
                    this.isMenuOpen = true;
                }
            }
        },
        methods: {
            ...mapActions( {
                loadLocations: 'locations/get',
                updateLocation: 'addHotelReservationState/init'
            } ),
            focus( ) {
                this.isActive = true;
                this.$nextTick( ( ) => {
                    this.$refs.input.focus( );
                } );
            },
            searchLocations: debounce( function ( ) {
                if ( this.searchFilter ) {
                    this.loadLocations( { limit: 5, offset: 1, keyword: this.searchFilter } );
                }
            }, InputConstants.INPUT_APPLY_DEBOUNCE_TIME ),
            selectOption( option ) {
                this.isActive = false;
                this.isMenuOpen = false;
                this.$emit( 'input', { ...option } );
            },
            onWrapperClickDo( ) {
                this.$emit( 'click' );
                if ( !this.disabled ) {
                    this.isActive = true;
                    this.$nextTick( ( ) => {
                        this.$refs.input.focus( );
                    } );
                }
            },
            onBlurDo() {
                this.isActive = false;
            },
            onKeyUpDo( event ) {
                /**
                 * Input event is not trigger when the autocomplete feature is on different mobile devices
                 * The behaviour is described here
                 * https://stackoverflow.com/questions/32968624/prevent-samsung-predictive-text-in-html-form
                 *
                 * we are listening for the keyUp and see if the input was modified.
                 */
                if ( event.target.value !== this.searchFilter ) {
                    this.searchFilter = event.target.value;
                } else {
                    if ( event.key === 'Enter' && this.locations[0] )
                        this.selectOption( this.locations[0] );
                }
            },
            clearSelection( event ) {
                event.stopPropagation();
                this.searchFilter = '';
                this.$emit( 'input', { } );
            },
        }
    };
</script>


<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";

.gst-location-search-dropdown {
    display: flex;
    flex-direction: column;

    ::placeholder {
        color: theme-color( 'quaternary' );
    }

    .gst-location-search-dropdown__label {
        position: relative;
        line-height: line-height( 'xl' );
        margin-bottom: theme-spacing( 2 );
        font-size: font-size( 's' );
        font-weight: font-weight( 'regular' );
    }

    .gst-location-search-dropdown__input-wrapper {
        position: relative;
        height: 44px;
        border: 1px solid theme-color( 'septenary' );
        border-radius: border-radius( 'xxs' ) !important;

        &:focus {
            border: 1px solid theme-color( 'primary' );
        }

        .gst-location-search-dropdown__location-icon {
            height: 16px;
            width: 16px;

            .gst-svg-icon {
                fill: theme-color( 'primary' );
            }
        }

        .gst-location-search-dropdown__clear-button {
            border: none;

            :hover {
                background-color: unset !important;
            }

            .gst-location-search-dropdown__clear-icon {
                .gst-svg-icon {
                    fill: theme-color( 'septenary' );
                }
            }
        }
    }

    .gst-location-search-dropdown__input-wrapper--no-border {
        border: none;
    }

    .gst-location-search-dropdown__input-wrapper.form-input-error {
        border-color: theme-color( 'error' );
    }

    .gst-location-search-dropdown__input-wrapper.form-input-focus {
        border-color: theme-color( 'primary' );
    }

    .gst-location-search-dropdown__errors-messages {
        line-height: line-height( 's' );
        margin-top: theme-spacing( 1 );
        color: theme-color( 'error' );
        font-size: font-size( 'xxs' );
        font-weight: font-weight( 'regular' );
        min-height: line-height( 's' );
    }

    .gst-location-search-dropdown__input,
    .gst-location-search-dropdown__selection {
        display: block;
        width: 100%;
        line-height: 42px;
        padding: 0 6px;
        border: none;
        background: theme-color( 'white' );
        color: theme-color( 'quaternary' );
        font-size: font-size( 'l' );
        cursor: pointer;
        border-radius: 3px;
        min-width: 50px;

        &::placeholder {
            color: theme-color( 'tertiary' );
        }

        &:focus {
            border: none;
            outline: none;
        }
    }
}

.gst-location-search-dropdown__menu {
    background-color: theme-color( 'white' );

    .gst-location-search-dropdown__content {
        max-width: 248px;
        min-height: 55px;

        .not-found {
            color: theme-color( 'error' );
        }
    }
}
</style>