<template>
    <div class="gst-event-totals-container d-flex u-width-100">
        <div class="price-container d-flex flex-column flex-grow-1">
            <span class="total-title">{{ title }}:</span>
            <span v-if="totalPrice" class="text-lowercase total-subtitle mt-2">
                {{ selectedItemsWithQuantity }}
            </span>
        </div>
        <div v-if="totalPrice" class="price-container d-flex flex-column flex-grow-1 text-right">
            <div class="total-title">
                <span>{{ pricePerPerson | currencyFilter( currency ) }}</span>
                <span>
                    {{ $t( '_common:terms.perPerson' ) }}
                </span>
            </div>
            <TicketPriceBreakdown
                v-if="!hidePriceBreakdown && ticketOffer"
                class="price-container__breakdown mt-2"
                :base-price="breakdownBasePrice"
                :fee-price="breakdownFeePrice"
                :currency="ticketOffer.currency"
                :all-inclusive-pricing="ticketOffer.allInclusivePricing" 
                :hotel-price="hotelPricePerPerson" />
            <div class="total-subtitle mt-2">
                <span>{{ totalPrice | currencyFilter( currency ) }}</span>
                <span>
                    {{ $t( '_common:terms.perPackage' ) }}
                </span>
            </div>
        </div>
        <div v-else class="d-flex flex-column flex-grow-1 text-right gst-event-totals-container__no-price">
            <h4>{{ noSelectionPrice }}</h4>
        </div>
    </div>
</template>

<script>
    import TicketPriceBreakdown from '@tenant/app/components/cards/TicketPriceBreakdown.vue';

    export default {
        name: 'EventTotalsContainer',
        components: {
            TicketPriceBreakdown
        },
        props: {
            title: {
                type: String,
                default: null
            },
            bundleProducts: {
                type: Object,
                default: null
            },
            event: {
                type: Object,
                required: true
            },
            hidePriceBreakdown: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            selectedHotel( ) {
                if ( this.bundleProducts.hasHotelReservation ( ) ) {
                    return this.bundleProducts.hotelReservation.hotel;
                }
                return null;
            },
            currency() {
                if ( this.selectedHotel ) {
                    return this.selectedHotel.selectedRoom ? this.selectedHotel.selectedRoom.selectedRate.currency : this.event.currency;
                }

                return this.event.currency;
            },
            adultsCount() {
                return this.selectedHotel && this.selectedHotel.selectedRoom.accommodation.guests.adultsCount || 0;
            },
            ticketsQuantity( ) {
                const { tickets } = this.bundleProducts.getQuantity( );

                return tickets;
            },
            ticketOffer() {
                if ( this.bundleProducts.eventTicket ) {
                    const offer = this.bundleProducts.eventTicket.ticket?.offers[0];

                    return offer;
                }
                return null;
            },
            breakdownBasePrice() {
                if ( this.bundleProducts.eventTicket?.ticket ) {
                    const count = this.bundleProducts.eventTicket.ticket.offers.length;
                    const price = this.bundleProducts.eventTicket.ticket.offers
                        .reduce( ( acc, item ) => {
                            acc += item.basePrice;
                            return acc;
                        }, 0 );
                    return price / count;
                }
                return 0;
            },
            breakdownFeePrice() {
                if ( this.bundleProducts.eventTicket?.ticket ) {
                    const count = this.bundleProducts.eventTicket.ticket.offers.length;
                    const price = this.bundleProducts.eventTicket.ticket.offers
                        .reduce( ( acc, item ) => {
                            acc += item.feePrice;
                            return acc;
                        }, 0 );
                    return price / count;
                }
                return 0;
            },
            hotelPrice() {
                if ( this.bundleProducts.hasHotelReservation() ) {
                    return this.bundleProducts.hotelReservation.totals.totalPrice;
                }
                return 0;
            },
            hotelPricePerPerson() {
                return ( this.hotelPrice / ( this.adultsCount || this.ticketsQuantity ) );
            },
            totalPrice() {
                const totals = this.bundleProducts.getTotals( );

                return totals.displayPrice;
            },
            pricePerPerson() {
                return ( this.totalPrice / ( this.adultsCount || this.ticketsQuantity ) );
            },
            noSelectionPrice() {
                const tempPrice = this.$options.filters.currencyFilter( 0, this.currency );
                const price = tempPrice.replace( '0.00', '-' );

                return price;
            },
            selectedItemsWithQuantity( ) {
                let items = [];

                if ( this.bundleProducts.hasHotelReservation( ) ) {
                    items.unshift( this.$t( '_common:terms.nightWithCount', { count: this.selectedHotel.selectedRoom.accommodation.nights } ) );
                }

                if ( this.ticketsQuantity ) {
                    items.push( this.$t( '_common:terms.ticketWithCount', { count: this.ticketsQuantity } ) );
                }

                return items.join( ' + ' );
            }
        }
    };
</script>

<style lang="scss" scoped>
@import "@scssVariables";
@import "@scssMixins";

.gst-event-totals-container {
    padding: theme-spacing( 4 );
    background: theme-color-hex( 'primary-lighten-4' );
    color: theme-color( 'primary' );
    z-index: 1;

    h4 {
        line-height: line-height( 'xxxxl' );
        font-size: font-size( 'l' );
        font-weight: font-weight( 'bold' );
    }

    p {
        line-height: line-height( 'm' );
        font-size: font-size( 'xxs' );
        font-weight: font-weight( 'regular' );
    }

    .gst-event-totals-container__no-price {
        min-height: 39px;
    }

    .price-container {
        line-height: line-height( 'm' );
        color: theme-color( 'anchor' );

        .total-title {
            font-size: font-size( 'xxl' );
            font-weight: font-weight( 'bold' );
        }

        .price-container__breakdown {
            font-size: font-size( 'xxxxs' );
        }

        .total-subtitle {
            font-size: font-size( 'xxs' );
        }
    }
}

@include mobile-only {
    .gst-event-totals-container {
        padding-top: theme-spacing( 2 );
        padding-bottom: theme-spacing( 2 );
    }
}
</style>
