import { render, staticRenderFns } from "./EventVenueImage.vue?vue&type=template&id=bee8b62c&scoped=true&"
import script from "./EventVenueImage.vue?vue&type=script&lang=js&"
export * from "./EventVenueImage.vue?vue&type=script&lang=js&"
import style0 from "./EventVenueImage.vue?vue&type=style&index=0&id=bee8b62c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bee8b62c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib/components/VGrid';
installComponents(component, {VFlex})
