import { mapActions, mapState, mapGetters } from 'vuex';
import isEqual from 'lodash/isEqual';
import VuelidateMessages from '@core/mixins/forms/VuelidateMessages';
import { formatQueryParam as dateUtilsFormatQueryParam } from '@tenant/app/utils/dateUtils';
import { differenceInCalendarDays, addDays } from '@tenant/app/utils/dateUtils';
import EventFlowMixin from '../mixins/EventFlowMixin';

const STORE_NAME = 'addHotelReservationState';

const isValidLocation = ( location ) => {
    return !!( location?.latitude && location?.longitude );
};

export default {
    mixins: [
        VuelidateMessages,
        EventFlowMixin
    ],
    i18nOptions: {
        namespaces: 'main',
        keyPrefix: 'modules.hotel.features.addHotelReservation._components.filter'
    },
    data( ) {
        return {
            showModal: false,
            location: null,
            startDate: null,
            endDate: null,
            roomsCount: null,
            adultsCount: null,
            childrenCount: null,
            childAges: [ ],
            childAgeOptions: new Array( 18 ).fill().map( ( _, idx ) => {
                return {
                    id: idx,
                    name: idx
                };
            } ),
            applyButtonIsAnimated: false,
        };
    },
    validations( ) {
        if ( this.canEditLocation ) {
            return {
                location: {
                    required: isValidLocation
                },
            };
        }
        return { };
    },
    computed: {
        ...mapState( {
            filters: state => state[STORE_NAME].filters,
            initialContext: state => state[STORE_NAME].context
        } ),
        ...mapGetters( {
            getFiltersFromContext: `${STORE_NAME}/getFiltersFromContext`,
        } ),
        canEditLocation( ) {
            return !this.isDynamicFlow;
        },
        isDesktop( ) {
            return  this.$vuetify.breakpoint.mdAndUp;
        },
        now() {
            return new Date( );
        },
        dates: {
            get( ) {
                const { startDate, endDate } = this;

                return [ startDate, endDate ];
            },
            set( value ) {
                this.startDate = value[ 0 ];
                this.endDate = value[ 1 ];
            }
        },
        daysStay( ) {
            const { startDate, endDate } = this;
            if ( startDate && endDate ) {
                const diff = differenceInCalendarDays( endDate, startDate );
                return ` (${this.$t( '_common:terms.nightStayWithCount', { count: diff } )})`;
            }
            return '';
        },
        maxAllowDate( ) {
            const { startDate } = this;

            if ( startDate ) {
                return this.$options.filters.date( addDays ( startDate, 30 ), 'yyyy-MM-dd' );
            }

            return null;
        },
        iconsAttrs( ) {
            return {
                width: this.isDesktop ? 16 : 21,
                height: this.isDesktop ? 16 : 21,
            };
        },
        hasLocation( ) {
            return isValidLocation( this.location );
        },
        _validationsMessages( ) {
            return {
                location: {
                    required: ( ) => this.$t( 'errors.cityVenueRequired' )
                }
            };
        },
    },
    watch: {
        'showModal': function ( value ) {
            if ( !value && !this.isDesktop ) {
                this.$emit( 'close' );
            }
        },
        'isDesktop': function ( value ) {
            this.showModal = !value;
        },
        'childrenCount': function ( newValue, oldValue ) {
            if ( oldValue !== null ) {
                if ( newValue > oldValue ) {
                    this.childAges.push( 1 );
                } else {
                    let i = oldValue;
                    while( i > newValue ) {
                        this.childAges.pop( );
                        i--;
                    }
                }
            }
        }
    },
    methods: {
        ...mapActions( {
            updateFilters: `${STORE_NAME}/filters/update`,
            resetFilters: `${STORE_NAME}/resetFilters`,
        } ),
        onLocationChangeDo( newLocation ) {
            this.location = { ...newLocation };
        },
        init( ) {
            this.startDate = this.filters.startDateTime;
            this.endDate = this.filters.endDateTime;
            this.setLocalFilters( this.filters );
        },
        setLocalFilters( { guests, roomsCount, location } ) {
            this.adultsCount = guests.adultsCount;
            this.childrenCount = guests.childrenCount;
            this.roomsCount = roomsCount;
            this.childAges = [ ...guests.childAges ];
            this.location = { ...location };
        },
        validateForm( ) {
            this.$v.$touch( );
            if ( this.$v.$invalid ) {
                this.$refs.location.focus( );
                return false;
            }
            return true;
        },
        save( ) {
            if ( !this.validateForm( ) ) {
                return;
            }
            const { startDate, endDate, location } = this;
            const guests = {
                adultsCount: this.adultsCount,
                childrenCount: this.childrenCount,
                childAges: [ ...this.childAges ]
            };

            this.updateFilters( {
                startDateTime: startDate,
                endDateTime: endDate || addDays ( startDate, 1 ),
                roomsCount: this.roomsCount,
                guests,
                location
            } );
            const endDateParam = endDate || addDays ( startDate, 1 );
            const newQueryParams = {
                startDate: dateUtilsFormatQueryParam( startDate ),
                endDate: dateUtilsFormatQueryParam( endDateParam ),
                roomsCount: this.roomsCount,
                adultsCount: this.adultsCount,
                childrenCount: this.childrenCount,
                childAges: [ ...this.childAges ],
                ...location
            };

            const query = {
                ...this.$route.query,
                ...newQueryParams
            };

            if ( !isEqual( query, this.$route.query ) ) {
                this.$router.replace( {
                    ...this.$route, 
                    query: query,
                } ); 
            }

            this.$emit( 'close' );
        },
        updateAdultsCount( value ) {
            this.adultsCount = value;
        },
        updateChildrenCount( value ) {
            this.childrenCount = value;
        },
        updateRoomsCount( value ) {
            this.roomsCount = value ;
        },
        onClickOverlayDo( event ) {
            event.stopPropagation( );
            event.preventDefault( );
            this.applyButtonIsAnimated = true;
        },
        onClickDesktopContainerDo( ) {
            this.applyButtonIsAnimated = false;
        },
        isLocation( location ) {
            return isValidLocation( location );
        },
        reset( ) {
            const { location, startDateTime, endDateTime, roomsCount, guests } = this.getFiltersFromContext( );

            this.location = { ...location };
            this.startDate = startDateTime;
            this.endDate = endDateTime;
            this.roomsCount = roomsCount;
            this.adultsCount = guests.adultsCount;
            this.childrenCount = guests.childrenCount;
        }
    },
    mounted( ) {
        this.showModal = !this.isDesktop;
    },
    created( ) {
        this.init( );
    }
};