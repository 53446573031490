<template>
    <div class="gst-location-item d-flex items-center" @mousedown="selectOption">
        <BaseIcon :symbol-id="icon" class="mr-2 ml-4 mt-auto mb-auto" />
        <div class="mb-2 mt-2">
            <div>
                {{ item.name }}
            </div>
            <div class="tertiary--text">
                {{ subtitle }}
            </div>
        </div>
    </div>
</template>

<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import locationsConstants from '@tenants/ticketmaster/app/utils/constants/locations';
    import { locationParser } from '@tenant/app/utils/locations';

    export default {
        name: 'DropDownItem',
        components: {
            BaseIcon
        },
        props: {
            item: {
                type: Object,
                required: true
            }
        },
        computed: {
            icon() {
                return this.item.type === locationsConstants.TYPES.VENUE ? 'icons--locations--star_outlined' : 'icons--locations--location';
            },
            subtitle() {
                return locationParser( this.item );
            }
        },
        methods: {
            selectOption() {
                this.$emit( 'select-option' );
            }
        }
    };
</script>

<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";

div.gst-location-item:hover {
    background-color: theme-color( 'primary-lighten-1' );
    cursor: pointer;
}

.gst-location-item {
    width: 100%;

    svg {
        path {
            fill: theme-color( 'tertiary' );
        }
    }
}
</style>