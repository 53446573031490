<template>
    <v-card ref="modalBody" class="gst-hotel-details d-flex flex-column rounded">
        <HotelDetailsHeader
            ref="modalHeader"
            class="flex-grow-0"
            :item="item"
            :has-back-button="hasBackButton"
            :back-button-text="backButtonText"
            @back="$emit( 'back' )"
            @close="$emit('close')">
            <template v-if="$vuetify.breakpoint.mdAndUp" v-slot:addressBar>
                <HotelDetailsAddressBar
                    :item="item"
                    :event="event"
                    :bus-events-parent="busEventsParent"
                    :in-location="inLocation"
                    :distance-from="isOpenInventoryFlow"
                    @show-map="onShowMapDo" />
            </template>
        </HotelDetailsHeader>
        <HotelDetailsGallery ref="modalImageGallery" :images="item.images" />
        <HotelDetailsAddressBar
            v-if="!$vuetify.breakpoint.mdAndUp"
            :item="hotelData"
            :event="event"
            :in-location="inLocation"
            :distance-from="isOpenInventoryFlow"
            @show-map="onShowMapDo" />
        <HotelDetailsTabsNavigation ref="modalNavigation" :item="hotelData" :tab="tab" :navigation-tabs="navigationTabs" @change="changeTab" />
        <HotelDetailsBody ref="modalNavigationBody"
            :bundle-products="bundleProducts"
            :item="hotelData"
            :event="event"
            :tab="tab"
            :navigation-tabs="navigationTabs"
            :has-value="hasValue"
            :focused-room="focusedRoom"
            :bus-events-parent="busEventsParent"
            :in-location="inLocation"
            @close="$emit('close')" />
    </v-card>
</template>

<script>
    import debounce from 'lodash/debounce';
    import isEqual from 'date-fns/isEqual/index';
    import cloneDeep from 'lodash/cloneDeep';
    import CloseModalOnRouteChangeMixin from '@core/mixins/modals/CloseModalOnRouteChange';
    import CloseModalOnBreakpointChangeMixin from '@core/mixins/modals/CloseModalOnBreakpointChange';
    import HotelDetailsHeader from './HotelDetailsV2/HotelDetailsHeader';
    import HotelDetailsAddressBar from './HotelDetailsV2/HotelDetailsAddressBar';
    import HotelDetailsGallery from './HotelDetailsV2/HotelDetailsGallery';
    import HotelDetailsBody from './HotelDetailsV2/HotelDetailsBody';
    import HotelDetailsTabsNavigation from './HotelDetailsV2/HotelDetailsTabsNavigation';
    import EventFlowMixin from '../../features/addHotelReservation/mixins/EventFlowMixin';

    export default {
        name: 'HotelDetailsV2',
        components: {
            HotelDetailsHeader,
            HotelDetailsGallery,
            HotelDetailsBody,
            HotelDetailsTabsNavigation,
            HotelDetailsAddressBar
        },
        mixins: [ CloseModalOnRouteChangeMixin, CloseModalOnBreakpointChangeMixin, EventFlowMixin ],
        props: {
            item: {
                type: Object,
                default: null
            },
            event: {
                type: Object,
                required: true
            },
            bundleProducts: {
                type: Object,
                default: null
            },
            busEventsParent: {
                type: Object,
                default: null
            },
            focusedRoom: {
                type: Object,
                default: null
            },
            hasValue: {
                type: Boolean,
                default: false
            },
            hasBackButton: {
                type: Boolean,
                default: false
            },
            backButtonText: {
                type: String,
                default: null
            },
            inLocation: {
                type: Object,
                default: () => ( {} )
            }
        },
        data() {
            return {
                tab: 1,
                hotelData: null,
            };
        },
        computed: {
            hasItemsToDisplayOnWhatNearby() {
                return this.item.attractions.length > 0
                    || this.item.restaurants.length > 0
                    || this.item.publicTransportation.length > 0
                    || this.item.terminals.length > 0;
            },
            navigationTabs( ) {
                if ( this.hasItemsToDisplayOnWhatNearby ) {
                    return [ 'overview', 'rooms', 'whatsNearby' ];
                } else {
                    return [ 'overview', 'rooms' ];
                }
            },
            overviewTabIndex( ) {
                return this.navigationTabs.findIndex( tab => tab === 'overview' );
            },
            roomsTabIndex( ) {
                return this.navigationTabs.findIndex( tab => tab === 'rooms' );
            }
        },
        watch: {
            'item': {
                handler: function ( item, oldItem ) {
                    this.hotelData = cloneDeep( this.item );

                    if ( !isEqual( item, oldItem ) && this.tab !== this.roomsTabIndex ) {
                        this.changeTab( this.roomsTabIndex );
                    }
                },
                immediate: true,
                deep: true
            }
        },
        methods: {
            changeTab( id ) {
                this.tab = id;
            },
            scrollToRoom( offsetRoom ) {
                const headerHeight = this.$refs['modalHeader'].$el.clientHeight;
                const navigationHeight = this.$refs['modalNavigation'].$el.clientHeight;
                const modalBodyRef =  this.$refs['modalBody'].$el;

                modalBodyRef.scrollTo( 0, offsetRoom + headerHeight + navigationHeight );
            },
            scrollToMap: debounce( function( ) {
                const modalBodyRef =  this.$refs['modalBody'].$el;
                const imageGalleryHeight = this.$refs['modalImageGallery'].$el.clientHeight;
                const overviewTabMapRef = this.$refs.modalNavigationBody.$refs.overviewTab[0].$refs.map;
                const scrollTopOffset = overviewTabMapRef.offsetTop + imageGalleryHeight;

                modalBodyRef.scrollTo( {
                    top: scrollTopOffset,
                    behavior: 'smooth'
                } );
            }, 250 ),
            onShowMapDo( ) {
                if ( this.tab !== this.overviewTabIndex ) {
                    this.changeTab( this.overviewTabIndex );
                }
                this.scrollToMap( );
            }
        },
        mounted() {
            this.busEventsParent.$on( 'scrollToRoom', this.scrollToRoom );
        },
        destroyed() {
            this.busEventsParent.$off( 'scrollToRoom' );
        }
    };
</script>

<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";

.gst-hotel-details {
    position: relative;
    padding-bottom: 16px;
    background-color: theme-color( 'white' ) !important;
    color: theme-color( 'quaternary' );
    overflow-x: hidden;
    overflow-y: scroll;
}
</style>
