<template>
    <div
        class="gst-selected-hotel-card d-flex flex-row"
        :class="{
            'gst-selected-hotel-card--has-image': showImage,
        }">
        <div v-if="showImage" class="gst-selected-hotel-card__image flex-shrink-1">
            <BaseImage :src="image" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="100%" :width="$vuetify.breakpoint.mdAndUp ? 124 : 98" />
        </div>
        <div class="gst-selected-hotel-card__content d-flex flex-column flex-grow-1">
            <div
                class="d-flex gst-selected-hotel-card__title "
                :class="{
                    'flex-column-reverse': $vuetify.breakpoint.smAndDown
                }">
                <span
                    class="gst-selected-hotel-card__title-name"
                    :class="{
                        'text-truncate': $vuetify.breakpoint.smAndDown
                    }">
                    {{ selectedHotelReservation.item.name }}
                </span>
                <StarRating class="gst-selected-hotel-card__title-star-rating" :value="rating" />
            </div>
            <div v-if="showGiftCard" class="d-flex mt-2">
                <GiftCardChip :amount="giftCard.amount" :currency="giftCard.currency" />
            </div>
            <span v-if="showImage || $vuetify.breakpoint.mdAndUp" class="gst-selected-hotel-card__venue-distance">{{ distance }}</span>
            <div v-if="$vuetify.breakpoint.mdAndUp" class="d-flex align-center gst-selected-hotel-card__stay-details">
                <BedIcon
                    v-if="$vuetify.breakpoint.mdAndUp"
                    class="mr-2 flex-shrink-0" />
                <span>{{ roomInfo }} • {{ stayPeriod }}</span>
            </div>
            <div v-else
                class="d-flex gst-selected-hotel-card__stay-details"
                :class="{
                    'flex-column': showImage
                }">
                <span
                    class="flex-shrink-1 "
                    :class="{
                        'text-truncate': !showImage
                    }">{{ roomInfo }}</span>
                <span v-if="!showImage" class="flex-shrink-0 mx-1"> • </span>
                <span class="flex-shrink-0">{{ stayPeriod }}</span>
            </div>
        </div>
        <div
            class="gst-selected-hotel-card__actions d-flex flex-grow-0"
            :class="{
                'align-center': showImage,
                'flex-column justify-center': !$vuetify.breakpoint.mdAndUp
            }">
            <BaseButton v-if="hasRemoveButton" class="gst-selected-hotel-card__actions-remove-hotel-btn" @click="onRemoveBtnClickDo">
                {{ $t( '_common:buttons.remove' ) }}
            </BaseButton>
            <BaseButton class="gst-selected-hotel-card__actions-change-hotel-btn" @click="onChangeBtnClickDo">
                {{ $t( '_common:buttons.change' ) }}
            </BaseButton>
        </div>
    </div>
</template>

<script>
    import StarRating from '@core/shared/components/misc/StarRating.vue';
    import BaseButton from '@core/shared/components/buttons/BaseButton.vue';
    import { getDistanceUnitOfMeasureForCountry } from '@core/utils/measureUtils';
    import BedIcon from '@tenants/ticketmaster/app/assets/icons/bed.svg';
    import BaseImage from '@tenants/ticketmaster/app/components/BaseImage';
    import GiftCardChip from '@tenant/app/components/chips/GiftCardChip';
    import { getImageByWidth } from '@tenants/ticketmaster/app/utils/imageModelUtils';
    import { IMAGE_SIZE } from '@tenants/ticketmaster/app/utils/constants/imageData';

    export default {
        name: 'SelectedHotelCard',
        components: {
            StarRating,
            BaseButton,
            BedIcon,
            BaseImage,
            GiftCardChip
        },
        props: {
            selectedHotelReservation: {
                type: Object,
                required: true
            },
            event: {
                type: Object,
                required: true
            },
            showImage: {
                type: Boolean,
                default: false
            },
            hasRemoveButton: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            rating() {
                const awards = this.selectedHotelReservation.item.awards;

                return awards.length ? awards[0].rating : 0;
            },
            distanceUnitOfMeasure( ) {
                return getDistanceUnitOfMeasureForCountry ( this.event.venueCountry );
            },
            distance( ) {
                return this.$options.filters.distanceToVenue( this.selectedHotelReservation.item.distance, this.distanceUnitOfMeasure );
            },
            stayPeriod() {
                return this.$options.filters.reservationDatePeriod(
                    new Date( this.selectedHotelReservation.item.selectedRoom.accommodation.startDateTime ),
                    new Date( this.selectedHotelReservation.item.selectedRoom.accommodation.endDateTime ),
                    true
                );
            },
            roomInfo() {
                return this.$options.filters.roomNameAndQuantity( {
                    name: this.selectedHotelReservation.item.selectedRoom.name,
                    standardNumBeds: this.selectedHotelReservation.roomsCount,
                } );
            },
            image( ) {
                return getImageByWidth( IMAGE_SIZE.THUMBNAIL, this.selectedHotelReservation.item.image );
            },
            giftCard( ) {
                return this.selectedHotelReservation.item.selectedRoom.selectedRate.giftCard;
            },
            showGiftCard( ) {
                return this.giftCard ? this.giftCard.amount : 0;
            },
        },
        methods: {
            onChangeBtnClickDo() {
                this.$emit( 'change' );
            },
            onRemoveBtnClickDo() {
                this.$emit( 'remove' );
            }
        }
    };
</script>

<style scoped lang="scss">
@import '@scssVariables';
@import '@scssMixins';

$borderColor: #E3E3E3;

.gst-selected-hotel-card {
    padding: theme-spacing( 4 ) theme-spacing( 5 );
    border: 1px solid $borderColor;
    margin-bottom: theme-spacing( 0 );
    border-radius: border-radius( 'xs' );

    .gst-selected-hotel-card__title {
        position: relative;

        .gst-selected-hotel-card__title-name {
            line-height: line-height( 'xxxl' );
            color: theme-color( 'quaternary' );
            font-size: font-size( 'l' );
            font-weight: font-weight( 'large' );
        }
    }

    .gst-selected-hotel-card__venue-distance {
        line-height: line-height( 'm' );
        margin-top: theme-spacing( 2 );
        color: theme-color( 'tertiary' );
        font-size: font-size( 'xxs' );
        font-weight: font-weight( 'regular' );
    }

    .gst-selected-hotel-card__stay-details {
        margin-top: theme-spacing( 2 );

        svg {
            path {
                fill: theme-color( 'tertiary' );
            }
        }

        span {
            line-height: line-height( 'xl' );
            color: theme-color( 'quaternary' );
            font-size: font-size( 's' );
            font-weight: font-weight( 'regular' );
        }
    }

    .gst-selected-hotel-card__actions {
        .gst-selected-hotel-card__actions-change-hotel-btn {
            line-height: line-height( 'm' );
            background-color: theme-color( 'white' ) !important;
            color: theme-color( 'primary' ) !important;
            font-size: font-size( 'xxs' );
            font-weight: font-weight( 'large' );
            text-transform: capitalize;
        }

        .gst-selected-hotel-card__actions-remove-hotel-btn {
            line-height: line-height( 'm' );
            background-color: theme-color( 'white' ) !important;
            color: theme-color( 'error' ) !important;
            font-size: font-size( 'xxs' );
            font-weight: font-weight( 'large' );
            text-transform: capitalize;
        }
    }
}

.gst-selected-hotel-card--has-image {
    padding: theme-spacing( 0 );

    .gst-selected-hotel-card__image {
        .v-image {
            border-top-left-radius: theme-spacing( 1 );
            border-bottom-left-radius: theme-spacing( 1 );
        }
    }

    .gst-selected-hotel-card__content {
        padding: theme-spacing( 4, 0, 4, 4 );

        .gst-selected-hotel-card__title {
            .gst-selected-hotel-card__title-name {
                line-height: line-height( 'xl' );
                font-size: font-size( 's' );
            }
        }

        .gst-selected-hotel-card__stay-details {
            svg {
                height: 25px;
                width: 25px;
            }

            span {
                line-height: line-height( 'm' );
                font-size: font-size( 'xxs' );
            }
        }
    }

    .gst-selected-hotel-card__actions {
        padding-right: theme-spacing( 4 );
    }
}

@include mobile-only {
    .gst-selected-hotel-card {
        padding: theme-spacing( 3, 0, 3, 4 );

        .gst-selected-hotel-card__content {
            min-width: 0;
        }

        .gst-selected-hotel-card__title {
            padding-right: theme-spacing( 2 );

            .gst-selected-hotel-card__title-name {
                font-size: font-size( 's' );
            }
        }

        .gst-selected-hotel-card__venue-distance {
            font-size: font-size( 'xxs' );
        }

        .gst-selected-hotel-card__stay-details {
            margin: 0;

            svg {
                height: 25px;
                width: 25px;
            }

            span {
                font-size: font-size( 'xxs' );
            }
        }
    }

    .gst-selected-hotel-card--has-image {
        padding: theme-spacing( 0 );

        .gst-selected-hotel-card__content {
            padding: theme-spacing( 3, 0, 3, 3 );

            .gst-selected-hotel-card__title {
                padding-right: theme-spacing( 0 );

                .gst-selected-hotel-card__title-name {
                    order: 2;
                }
            }

            .gst-selected-hotel-card__stay-details {
                margin-top: theme-spacing( 2 );

                span {
                    color: theme-color( 'tertiary' );
                    font-weight: font-weight( 'regular' );
                    margin-left: theme-spacing( 0 );
                }
            }
        }

        .gst-selected-hotel-card__actions {
            .gst-selected-hotel-card__actions-remove-hotel-btn {
                order: 2;
            }

            .gst-selected-hotel-card__actions-change-hotel-btn {
                order: 1;
            }
        }
    }
}
</style>