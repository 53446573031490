<template>
    <MapLoader
        v-if="isReady && isMounted"
        ref="mapLoader"
        :map-config="mapConfig"
        class="gst-hotels-map u-height-100">
        <template v-slot="{ mapsApi, map }">
            <HotelsNoData v-if="resultsNotFound && !isNewSearchArea" class="gst-hotels-map__empty-state" @reset-filters="resetFilters" />
            <HotelsMapActions
                v-if="isOpenInventoryFlow"
                :map="map"
                class="gst-hotels-map__actions" />
            <MapMarkerLocation
                v-if="showVenueMarker"
                :maps-api="mapsApi"
                :map="map"
                :location="location" />
            <MapMarkerHotel
                v-for="( hotel, index) in hotels"
                :key="index"
                :maps-api="mapsApi"
                :map="map"
                :hotel="hotel"
                :is-focused="focusedHotel && focusedHotel.id === hotel.id"
                :is-active="selectedHotel && selectedHotel.id === hotel.id"
                :bundle-products="getFinalBundleProductsForHotelRoom( hotel )"
                @click.prevent="onHotelMarkerClickDo( hotel )" />
            <MapPopupHotelCard
                v-if="selectedHotel"
                :maps-api="mapsApi"
                :map="map"
                :config="popupConfig"
                :hotel="selectedHotel"
                @open="onPopupOpenDo( $event )"
                @closed="unselectHotel()" />
        </template>
    </MapLoader>
</template>

<script>
    import isEqual from 'lodash/isEqual';
    import { mapState, mapGetters, mapActions } from 'vuex';
    import { getDistanceUnitOfMeasureForCountry } from '@core/utils/measureUtils';
    import { create as CreateBundleProductsModel } from '@tenant/app/modelsV2/BundleProductsModel';
    import { create as CreateBundleProductsHotelReservationModel } from '@tenant/app/modelsV2/BundleProductsHotelReservationModel';
    import MapLoader from '@tenants/ticketmaster/app/components/map/MapLoader.vue';
    import MapMarkerLocation from '@tenants/ticketmaster/app/components/map/MapMarkerLocation.vue';
    import MapMarkerHotel from '@tenants/ticketmaster/app/components/map/MapMarkerHotel.vue';
    import MapPopupHotelCard from '@tenants/ticketmaster/app/components/map/MapPopupHotelCard.vue';
    import HotelsListCard from '@tenants/ticketmaster/app/modules/hotel/features/addHotelReservation/AddHotelReservation/HotelsListCard.vue';
    import HotelsMapActions from '@tenants/ticketmaster/app/modules/hotel/features/addHotelReservation/AddHotelReservation/HotelsMapActions.vue';
    import mapConstants from '@tenant/app/utils/constants/map';
    import locationsConstants from '@tenants/ticketmaster/app/utils/constants/locations';
    import EventFlowMixin from '../mixins/EventFlowMixin';
    import HotelsNoData from './HotelsNoData';

    export default {
        name: 'HotelsMap',
        components: {
            MapLoader,
            MapMarkerLocation,
            MapMarkerHotel,
            MapPopupHotelCard,
            HotelsMapActions,
            HotelsNoData
        },
        mixins: [
            EventFlowMixin
        ],
        props: {
            event: {
                type: Object,
                required: true
            },
            busEventsParent: {
                type: Object,
                default: null
            },
            bundleProducts: {
                type: Object,
                required: true,
                default: function( ) {
                    return CreateBundleProductsModel( );
                }
            },
            // when true the hotel card layout is the same for desktop and mobile
            uniformHotelCard: {
                type: Boolean,
                default: false
            },
            isVisible: {
                type: Boolean,
                default: false
            }
        },
        emits: [
            'highlight-hotel-reservation',
            'open-hotel-card',
            'close-hotel-card'
        ],
        data( ) {
            return {
                isReady: false,
                isMounted: false,
                mapConfig: null,
                popupConfig: null,
                selectedHotel: null,
                mapBounds: {
                    minLat: null,
                    maxLat: null,
                    minLng: null,
                    maxLng: null
                }
            };
        },
        computed: {
            ...mapState( {
                hotels: state => state.addHotelReservationState.hotels.list,
                loading: state => state.addHotelReservationState.hotels.loading,
                focusedHotel: state => state.addHotelReservationState.hotels.focusedHotel,
                location: state => state.addHotelReservationState.hotels.filters.location,
                resultsNotFound: state => state.addHotelReservationState.hotels.resultsNotFound
            } ),
            ...mapGetters( {
                isNewSearchArea:  'addHotelReservationState/map/isNewSearchArea'
            } ),
            distanceUnitOfMeasure( ) {
                return getDistanceUnitOfMeasureForCountry ( this.location.countryCode );
            },
            isValidCoordinatesLocation() {
                return this.location.latitude && this.location.longitude;
            },
            showVenueMarker() {
                return this.isValidCoordinatesLocation && !this.isCityCentric;
            },
            bundleProductsFinal( ) {
                const ret = this.bundleProducts._clone();

                ret.setHotelReservation(
                    CreateBundleProductsHotelReservationModel (
                        ret.hotelReservation.hotel
                    )
                );

                return ret;
            },
            fitBoundsPadding( ) {
                return this.$vuetify.breakpoint.mdAndUp ? 100 : 50;
            },
            isCityCentric() {
                return this.location.type === locationsConstants.TYPES.CITY;
            }
        },
        watch: {
            'hotels': {
                handler: function ( hotelsList ) {
                    this.unselectHotel( );
                    if ( hotelsList.length ) {
                        this.initMapFromLocation( { preserveZoom: true } );
                    }
                },
                deep: true
            },
            isVisible: function ( newValue ) {
                if ( !newValue ) {
                    this.unselectHotel( );
                }
            },
            /**
             * Location watcher. If the location is changed the map will center on that location
             * before fetching the hotels. this way the costly animation from one location to
             * another is removed.
             */
            'location': {
                handler: function ( newLocation, oldLocation ) {
                    this.unselectHotel( );
                    if ( !isEqual( newLocation, oldLocation ) ) {
                        if ( newLocation.longitude && newLocation.latitude ) {
                            this.initMapFromLocation( { preserveZoom: true } );
                        } else {
                            this.initMapDefaults( );
                        }
                    }
                },
                deep: true
            }
        },
        methods: {
            ...mapActions( {
                resetFilters: 'addHotelReservationState/resetFilters',
            } ),
            getFinalBundleProductsForHotelRoom( item ) {
                const ret = this.bundleProducts._clone( );

                ret.setHotelReservation(
                    CreateBundleProductsHotelReservationModel (
                        item
                    )
                );

                return ret;
            },
            onHotelMarkerClickDo( hotel ) {
                this.selectedHotel = { ...hotel };
                this.popupConfig = {
                    card: HotelsListCard,
                    cardClasses: 'u-width-100',
                    propsToBind: ( item ) => {
                        return {
                            item: item,
                            distanceUnitOfMeasure: this.distanceUnitOfMeasure,
                            inLocation: this.location,
                            bundleProducts: this.getFinalBundleProductsForHotelRoom( item ),
                            withTo: true,
                            width: this.$vuetify.breakpoint.mdAndUp ? '146px' : '138px',
                            height: this.$vuetify.breakpoint.mdAndUp ? '81px' : '138px',
                            addButtonLabel: this.$t( '_common:buttons.add' ),
                            busEventsParent: this.busEventsParent,
                            uniformLayout: this.uniformHotelCard,
                            distanceFrom: this.isOpenInventoryFlow
                        };
                    }
                };
            },
            unselectHotel( ) {
                this.selectedHotel = null;
                this.$emit( 'close-hotel-card' );
            },
            onPopupOpenDo( boundingRect ) {
                this.$emit( 'open-hotel-card', boundingRect );
            },
            addCoordinatesToMapBounds( longitude, latitude ) {
                if ( !this.mapBounds.minLng || longitude < this.mapBounds.minLng ) {
                    this.mapBounds.minLng = longitude;
                }

                if ( !this.mapBounds.maxLng || longitude > this.mapBounds.maxLng ) {
                    this.mapBounds.maxLng = longitude;
                }

                if ( !this.mapBounds.minLat || latitude < this.mapBounds.minLat ) {
                    this.mapBounds.minLat = latitude;
                }

                if ( !this.mapBounds.maxLat || latitude > this.mapBounds.maxLat ) {
                    this.mapBounds.maxLat = latitude;
                }
            },
            addLocationToMapBounds( ) {
                if ( this.isValidCoordinatesLocation ) {
                    this.addCoordinatesToMapBounds( this.location.longitude, this.location.latitude );
                }
            },
            addEventVenueToMapBounds( ) {
                this.addCoordinatesToMapBounds( this.event.venueLongitude, this.event.venueLatitude );
            },
            addHotelsToMapBounds( ) {
                ( this.hotels || [] ).forEach( hotel => this.addCoordinatesToMapBounds( hotel.longitude, hotel.latitude ) );
            },
            updateMapConfigBounds( ) {
                this.mapConfig = {
                    bounds: [ [ this.mapBounds.minLng, this.mapBounds.minLat ], [ this.mapBounds.maxLng, this.mapBounds.maxLat ] ],
                    fitBoundsOptions: {
                        padding: this.fitBoundsPadding
                    },
                };
            },
            resetMapBounds( ) {
                this.mapBounds = {
                    minLat: null,
                    maxLat: null,
                    minLng: null,
                    maxLng: null
                };
            },
            /**
             * Initialize map configuration based on a searched location
             * Useful on mobile on the user's initial switch to map view
             */
            initMapFromLocation( config ) {
                const defaultZoom = mapConstants.DEFAULT_CONFIG.ZOOM;
                const zoom = config?.preserveZoom ? ( this.getMapZoom( ) || defaultZoom ) : defaultZoom;

                this.resetMapBounds( );
                // if we have hotels then set bounds to zoom the map to fit all
                // otherwise center on the new location with default zoom to eliminate animation.
                // very useful when a new location returns no hotels.
                if ( this.hotels.length ) {
                    this.addLocationToMapBounds( );
                    this.addHotelsToMapBounds( );
                    this.updateMapConfigBounds( );
                } else {
                    this.mapConfig = {
                        center: [ this.location.longitude, this.location.latitude ],
                        zoom
                    };
                }
            },
            /**
             * Initialize map configuration based on a known event
             */
            initMapFromEvent() {
                this.addEventVenueToMapBounds( );
                this.addHotelsToMapBounds( );
                this.updateMapConfigBounds( );
            },
            /**
             * Initialize map configuration from Prismic resource if it exists, otherwise it will default to New York
             */
            async initMapDefaults() {
                try {
                    const mapDefaults = await this.$prismic.client.getSingle( mapConstants.DEFAULT_CONFIG.PRISMIC.CUSTOM_TYPE );
                    // Filter map by type
                    const mapType = mapDefaults.data.locations.find( ( item ) => item.type ===  mapConstants.DEFAULT_CONFIG.PRISMIC.MAP_TYPES.UNKNOWN_EVENT );
                    this.mapConfig = {
                        center: [ mapType.location.longitude, mapType.location.latitude ],
                        zoom: mapType.zoom
                    };
                } catch ( e ) {
                    this.mapConfig = {
                        center: [ mapConstants.DEFAULT_CONFIG.LONGITUDE, mapConstants.DEFAULT_CONFIG.LATITUDE ],
                        zoom: mapConstants.DEFAULT_CONFIG.ZOOM
                    };
                }
            },
            async init( ) {
                if ( this.event && this.event.id && !this.isValidCoordinatesLocation ) {
                    // if a known event is used the map will load with the venue in the center
                    // and after that the hotels will be added
                    this.initMapFromEvent();
                } else if ( this.isValidCoordinatesLocation ) {
                    // Useful on mobile on the user's initial switch to map view
                    // on unknown event flow or know event when locations was changed
                    this.initMapFromLocation();
                } else {
                    await this.initMapDefaults();
                }
                this.isReady = true;
            },
            getMapZoom( ) {
                return this.$refs.mapLoader?.map?.getZoom( );
            }
        },
        mounted() {
            this.isMounted = true;
        },
        created() {
            this.init( );
        }
    };
</script>

<style lang="scss" scoped>
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-hotels-map {
        position: relative;

        .gst-hotels-map__actions {
            position: absolute;
            top: 38px;
            left: 50%;
            z-index: $z-index-map-hotel-button;
            transform: translate( -50% );
        }
    }

    @include mobile-only {
        .gst-hotels-map {
            .gst-hotels-map__empty-state {
                position: absolute;
                top: -40px;
                left: 50%;
                width: 84%;
                padding: theme-spacing( 4 );
                background: theme-color( 'warning-alpha-15' );
                transform: translate( -50%, 0 );
            }

            .gst-hotels-map__actions {
                top: 20px;
            }
        }
    }
</style>
