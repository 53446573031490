<template>
    <div class="gst-base-form-select">
        <label
            v-if="label"
            :for="id"
            class="gst-base-form-select__label">
            {{ label }}
        </label>
        <BaseSelect
            :id="id"
            :data-aria-label="label"
            :value="value"
            :has-error="hasError"
            v-bind="{ ...$attrs, ...$props }"
            class="gst-base-form-select__input"
            @input="onInputDo" />
        <slot name="append">
            <BaseIcon symbol-id="icons--chevron_down" class="gst-base-form-select__icon" />
        </slot>
    </div>
</template>

<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import BaseSelect from './BaseSelect';

    export default {
        name: 'BaseFormSelect',
        components: {
            BaseSelect,
            BaseIcon
        },
        inheritAttrs: false,
        props: {
            id: {
                type: String,
                required: true
            },
            label: {
                type: String,
                default: null,
            },
            clearable: {
                type: Boolean,
                default: false,
            },
            displayProp: {
                type: String,
                default: 'name'
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            loading: {
                type: Boolean,
                default: false
            },
            i18nPrefix: {
                type: String,
                default: ''
            },
            invalidateOnChangeOptions: {
                type: Boolean,
                default: true
            },
            options: {
                type: Array,
                required: true
            },
            optionsArrayObj: {
                type: Boolean,
                default: true
            },
            renderer: {
                type: Function,
                default: null,
            },
            valueProp: {
                type: String,
                default: 'id'
            },
            value: {
                type: [ Object, String, Number ],
                default: null
            },
            errorMessages: {
                type: Array,
                default: function ( ) {
                    return [ ];
                }
            },
            dataTestId: {
                type: String,
                required: true
            }
        },
        computed: {
            hasError( ) {
                return !!this.errorMessages.length;
            }
        },
        methods: {
            onInputDo ( value ) {
                this.$emit( 'input', value );
            }
        }
    };
</script>