import { mapGetters } from 'vuex';
import eventConstants from '@tenant/app/utils/constants/event';
import { minCheckInDate } from '@tenants/ticketmaster/app/utils/bookingUtils';

/**
 * TODO ionut
 *
 * This mixin needs to be changed to remove any dependency from event store ( outside the feature )
 */

export default {
    computed: {
        ...mapGetters( {
            eventFlow: 'events/getEventFlow',
            tokenStatus: `appState/tokenStatus`,
        } ),
        isDynamicFlow( ) {
            return this.eventFlow === eventConstants.FLOWS.DYNAMIC;
        },
        isPackagesFlow( ) {
            return this.eventFlow === eventConstants.FLOWS.PACKAGES;
        },
        isExperiencesFlow( ) {
            return this.eventFlow === eventConstants.FLOWS.EXPERIENCES;
        },
        isPreAllocatedFlow( ) {
            return this.eventFlow === eventConstants.FLOWS.HOTELS_PRE_ALLOCATED;
        },
        isOpenInventoryFlow( ) {
            return this.eventFlow === eventConstants.FLOWS.HOTELS_OPEN_INVENTORY;
        },
        isHotelOnlyFlow( ) {
            return this.isPreAllocatedFlow || this.isOpenInventoryFlow;
        },
        minBookingStartDate() {
            return minCheckInDate( !this.isHotelOnlyFlow, this.tokenStatus );
        }
    }
};
