<template>
    <v-flex shrink>
        <v-flex class="gst-event__venue-img">
            <EventTicketVenueImage
                class="u-mouse-pointer"
                :image-props="{
                    src: venueImageUrl,
                    height: $vuetify.breakpoint.mdAndUp ? 247 : '20vh',
                    width: '100%',
                    contain: true,
                    'aspect-ratio': 1
                }"
                :image-url-no-seats="imageUrlNoSeats"
                :loading="loading" />
        </v-flex>
    </v-flex>
</template>

<script>
    import EventTicketVenueImage from '@tenant/app/components/images/TicketVenueImage';

    export default {
        name: 'EventVenueImage',
        components: {
            EventTicketVenueImage
        },
        props: {
            venueDetails: {
                type: Object,
                default: null
            },
            loading: {
                type: Boolean,
                default: false,
            },
            imageUrlNoSeats: {
                type: String,
                default: null
            }
        },
        computed: {
            venueImageUrl( ) {
                return this.venueDetails.mapUrl;
            }
        }
    };
</script>

<style scoped lang="scss">
    @import '@scssVariables';
    @import '@scssMixins';

    .gst-event__venue-img {
        height: 260px;
        background-color: theme-color( 'quinary' );

        .gst-event-ticket-venue-image {
            padding-bottom: 15px;
        }
    }

    @include mobile-only {
        .gst-event__venue-img {
            height: 24vh;
        }
    }
</style>
