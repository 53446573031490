<template>
    <span v-if="upToLabel" class="gst-gift-card-chip">
        {{ $t( '_common:terms.upToGiftCard', { amount: giftAmount } ) }}
    </span>
    <span v-else class="gst-gift-card-chip">
        {{ $t( '_common:terms.hotelGiftCard', { amount: giftAmount } ) }}
    </span>
</template>

<script>
    export default {
        name: 'GiftCardChip',
        props: {
            amount: {
                type: Number,
                required: true
            },
            currency: {
                type: String,
                default: 'USD'
            },
            upToLabel: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            giftAmount( ) {
                return this.$options.filters.currencyFilter( Math.floor( this.amount ), this.currency, { digits: 0 } );
            }
        }
    };
</script>

<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-gift-card-chip {
        line-height: 18px;
        padding: 0 8px;
        background-color: #FBFF2C;
        color: theme-color( 'quaternary' );
        font-size: font-size( 'xxxs' );
        font-weight: font-weight( 'large' );
        border-radius: border-radius( 'xs' );
        text-transform: uppercase;
    }
</style>