<template>
    <div class="d-flex align-center justify-center u-height-100 u-width-100">
        <HotelDetailsEmptyStateIcon />
    </div>
</template>

<script>
    import HotelDetailsEmptyStateIcon from '@tenants/ticketmaster/app/assets/icons/hotel-details-empty-state.svg';

    export default {
        name: 'HotelDetailsEmptyState',
        components: {
            HotelDetailsEmptyStateIcon
        }
    };
</script>

<style>

</style>