<template>
    <InfiniteCardList
        :data="items"
        :config="hotelCardConfig"
        :loading="loading"
        :all-data-loaded="loadOnlyFirstLimit || allDataLoaded"
        :has-load-button="!loadOnlyFirstLimit"
        :prefill="true"
        :bus-events-parent="busEventsParent"
        item-classes="gst-add-hotel-reservation-hotel-list_card px-0 mx-md-2 mt-md-2"
        class="gst-add-hotel-reservation-hotel-list u-height-100 u-width-100"
        @load-more="!loadOnlyFirstLimit && loadNextPage( )">
        <template slot="header">
            <BaseButton
                v-if="isNewSearchArea && $vuetify.breakpoint.smAndDown"
                outlined
                color="primary"
                class="gst-hotels-map__update-list-btn u-text-transform-none ma-2 d-flex"
                @click="updateLocationFilters">
                <BaseIcon symbol-id="icons--reset" class="mr-2" />
                <span> {{ $t( '_common:buttons.updateListToMatchArea' ) }}</span>
            </BaseButton>
        </template>
        <template slot="loading">
            <DataLoading class="mt-5 mb-5 pt-5 pb-5" />
        </template>
        <template slot="empty-list">
            <div v-if="isEventUnknown && !hasCurrentLocation" class="d-flex justify-center mt-10">
                <BaseIcon symbol-id="icons--hotel_with_stars" />
            </div>
            <HotelsNoData v-else-if="resultsNotFound" @reset-filters="resetFilters" />
        </template>
        <template v-if="!loadOnlyFirstLimit" slot="see-more-button">
            <BaseButton
                :placeholder="$t('_common:buttons.loadMore')"
                outlined
                color="primary"
                class="gst-add-hotel-reservation-hotel-list__load-more-btn mx-auto d-flex mt-4"
                :loading="loading"
                @click="loadNextPage" />
        </template>
    </InfiniteCardList>
</template>

<script>
    import debounce from 'lodash/debounce';
    import isEqual from 'lodash/isEqual';
    import { mapActions, mapState, mapGetters } from 'vuex';
    import DataLoading from '@core/shared/components/loading/DataLoading';
    import InfiniteCardList from '@core/shared/components/lists/InfiniteCardList';
    import listConstants from '@core/utils/constants/list';
    import BaseButton from '@core/shared/components/buttons/BaseButton.vue';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import DynamicStoreModule from '@core/mixins/DynamicStoreModule';
    import searchHotelReservationConstants from '@tenant/app/utils/constants/searchHotelReservation';
    import { create as CreateBundleProductsModel } from '@tenant/app/modelsV2/BundleProductsModel';
    import { create as CreateBundleProductsHotelReservationModel } from '@tenant/app/modelsV2/BundleProductsHotelReservationModel';
    import HotelsListCard from './HotelsListCard.vue';
    import HotelsNoData from './HotelsNoData';
    import EventFlowMixin from '../mixins/EventFlowMixin';

    const STORE_NAME = 'addHotelReservationState';

    export default {
        name: 'HotelsList',
        components: {
            InfiniteCardList,
            DataLoading,
            BaseButton,
            // eslint-disable-next-line vue/no-unused-components
            HotelsListCard,
            HotelsNoData,
            BaseIcon
        },
        mixins: [
            DynamicStoreModule,
            EventFlowMixin
        ],
        props: {
            bundleProducts: {
                type: Object,
                required: true,
                default: function( ) {
                    return CreateBundleProductsModel( );
                }
            },
            distanceUnitOfMeasure: {
                type: String,
                default: 'km'
            },
            inLocation: {
                type: Object,
                required: true
            },
            busEventsParent: {
                type: Object,
                required: true
            },
            highlightItem: {
                type: Object,
                default: null
            },
            limit: {
                type: Number,
                default: searchHotelReservationConstants.DEFAULT.limit
            },
            /**
             * Load only first page
             */
            loadOnlyFirstLimit: {
                type: Boolean,
                default: false
            },
            // when true the hotel card layout is the same for desktop and mobile
            uniformHotelCard: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            ...mapState( {
                list: state => state[ STORE_NAME ].hotels.list,
                loading: state => state[ STORE_NAME ].hotels.loading,
                allDataLoaded: state => state[ STORE_NAME ].hotels.allItemsLoaded,
                listFilters: state => state[ STORE_NAME].hotels.filters,
                filters: state => state[ STORE_NAME ].filters,
                location: state => state[ STORE_NAME ].hotels.filters.location,
                mapCenter: state => state[ STORE_NAME ].map.mapCenter,
                searchCenter: state => state[ STORE_NAME ].map.searchCenter,
                resultsNotFound: state => state[ STORE_NAME ].hotels.resultsNotFound
            } ),
            ...mapGetters( {
                isEventUnknown: 'events/isUnknown',
                hasCurrentLocation: `${STORE_NAME}/hasCurrentLocation`,
                isFiltersLocationValid: `${STORE_NAME}/filters/isLocationValid`,
                isNewSearchArea: `${STORE_NAME}/map/isNewSearchArea`
            } ),
            items( ) {
                return this.list;
            },
            hotelCardConfig( ) {
                return {
                    card: HotelsListCard,
                    cardClasses: 'u-width-100',
                    propsToBind: ( item ) => {
                        return {
                            item: item,
                            distanceUnitOfMeasure: this.distanceUnitOfMeasure,
                            inLocation: this.inLocation,
                            bundleProducts: this.getFinalBundleProductsForHotelRoom( item ),
                            withTo: true,
                            width: this.$vuetify.breakpoint.mdAndUp ? '146px' : '138px',
                            height: this.$vuetify.breakpoint.mdAndUp ? '81px' : '138px',
                            addButtonLabel: this.$t( '_common:buttons.add' ),
                            isHighlighted: this.highlightItem === item,
                            uniformLayout: this.uniformHotelCard,
                            distanceFrom: this.isOpenInventoryFlow
                        };
                    }
                };
            },
        },
        watch: {
            'filters': {
                handler: function ( filters ) {
                    this.loadPage( {
                        filters,
                        limit: this.limit
                    } );
                },
                deep: true
            }
        },
        methods: {
            ...mapActions( {
                loadPage: `${STORE_NAME}/hotels/loadPage`,
                selectRoom: `${STORE_NAME}/hotels/selectRoom`,
                resetFilters: `${STORE_NAME}/resetFilters`,
                updateFilters: `${STORE_NAME}/filters/update`,
                setSearchCenter: `${STORE_NAME}/map/setSearchCenter`,
                updateFilterLocationWithMapCenter: `${STORE_NAME}/updateFilterLocationWithMapCenter`
            } ),
            updateLocationFilters( ) {
                this.setSearchCenter( { latitude: this.mapCenter.latitude, longitude: this.mapCenter.longitude } );
                this.updateFilterLocationWithMapCenter( );
            },
            loadNextPage: debounce( function( ) {
                this.loadPage( {
                    filters: this.filters,
                    limit: this.limit
                } );
            }, listConstants.DEBOUNCE_DELAY ),
            getFinalBundleProductsForHotelRoom( item ) {
                const ret = this.bundleProducts._clone( );

                ret.setHotelReservation(
                    CreateBundleProductsHotelReservationModel (
                        item
                    )
                );

                return ret;
            },
        },
        async mounted( ) {
            if ( this.isFiltersLocationValid && ( !this.list.length || !isEqual( this.filters, this.listFilters ) ) ) {
                const response = await this.loadPage( {
                    refresh: true,
                    filters: this.filters,
                    limit: this.limit
                } );
                this.$emit( 'hotels-loaded', response );
            } else {
                this.$emit( 'hotels-loaded', { success: true } );
            }
        },
    };
</script>


<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-add-hotel-reservation-hotel-list {
        .v-list {
            padding-top: 0 !important;

            .v-list-item::before {
                background-color: transparent;
            }
        }

        .gst-add-hotel-reservation-hotel-list__load-more-btn {
            text-transform: capitalize;
        }

        .v-btn:not(.v-btn--round).v-size--default {
            height: unset !important;
            min-height: 36px !important;
        }

        .gst-hotels-map__update-list-btn {
            span {
                width: 100%;

                span {
                    width: fit-content;
                    text-wrap: wrap;
                }
            }

            svg {
                -webkit-transform: scaleX( -1 );
                transform: scaleX( -1 );

                .gst-svg-icon {
                    stroke: theme-color( 'primary' );
                }
            }
        }
    }
</style>
