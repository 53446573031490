<template>
    <div
        :class="[ticket.isSelected ? 'selected' : '']"
        class="gst-ticket-card-variant-5 d-flex align-center justify-space-between"
        tabindex="0"
        @click="$emit('click', ticket)"
        @keypress.enter="$emit('click', ticket)">
        <div class="d-flex flex-column">
            <span class="gst-ticket-card-variant-5__name">{{ ticket.name }}</span>
            <div class="gst-ticket-card-variant-5__extra">
                <component :is="icon" />
                <span class="gst-ticket-card-variant-5__extra-status">{{ ticketStatus }}</span>
            </div>
        </div>
        <div class="gst-ticket-card-variant-5__price-wrap d-flex align-center">
            <div class="d-flex flex-column align-end text-right">
                <span class="gst-ticket-card-variant-5__price">
                    {{ ticketPrice }}
                </span>
                <TicketPriceBreakdown
                    v-if="ticketOffer"
                    class="gst-ticket-card-variant-5__price-breakdown"
                    :base-price="ticketOffer.basePrice"
                    :fee-price="ticketOffer.feePrice"
                    :currency="ticketOffer.currency"
                    :all-inclusive-pricing="ticketOffer.allInclusivePricing" />
            </div>
            <CheckIcon v-if="ticket.isSelected" class="ml-2 gst-ticket-card-variant-5__selected-ticket-icon flex-shrink-0" />
        </div>
    </div>
</template>

<script>
    import { isResale as ticketUtilsIsResale } from '@core/utils/ticketUtils';
    import CheckIcon from '@tenants/ticketmaster/app/assets/icons/check_round_green.svg';
    import TicketIcon from '@tenants/ticketmaster/app/assets/icons/tickets.svg';
    import TicketPriceBreakdown from '@tenant/app/components/cards/TicketPriceBreakdown.vue';

    export default {
        name: 'TicketCardVariant5',
        components: {
            // eslint-disable-next-line
            CheckIcon,
            // eslint-disable-next-line
            TicketIcon,
            TicketPriceBreakdown
        },
        i18nOptions: {
            namespaces: 'shared',
        },
        props: {
            ticket: {
                type: Object,
                required: true
            },
            offers: {
                type: Array,
                required: true
            }
        },
        computed: {
            ticketPrice() {
                const firstOffer = this.ticket.offers[ 0 ];
                const priceValue = ( firstOffer.allInclusivePricing ? firstOffer.totalPrice : firstOffer.basePrice ) || 0;
                const price = this.$options.filters.currencyFilter( priceValue, firstOffer.currency );

                return `${this.ticket.offers.length > 1 ? this.$t( '_common:terms.from' ) : ''} ${price} ${this.$t( 'components.misc.priceIcon.labels.priceSuffix' )}`;
            },
            ticketOffer() {
                return this.ticket.offers[0];
            },
            isResaleTicket() {
                return ticketUtilsIsResale( this.ticket.selection );
            },
            ticketStatus() {
                return this.isResaleTicket ? this.$t( 'components.ticket.ticketOfferTypeIcon.labels.resale' ) : this.$t( 'components.ticket.ticketOfferTypeIcon.labels.standard' );
            },
            icon() {
                return this.isResaleTicket ? CheckIcon : TicketIcon;
            }
        }
    };
</script>

<style lang="scss">
@import '@scssVariables';
@import '@scssMixins';

.gst-ticket-card-variant-5 {
    padding: theme-spacing( 4 ) theme-spacing( 4 );
    border: 1px solid #E3E3E3;
    border-radius: border-radius( 'xxs' );
}

.gst-ticket-card-variant-5:hover {
    background: #EBF5FF !important;
    border-color: transparent;
}

.gst-ticket-card-variant-5.selected {
    background: #EBF5FF !important;
    border-color: transparent;
}

.gst-ticket-card-variant-5__name {
    line-height: line-height( 'xxxl' );
    color: theme-color( 'quaternary' );
    font-size: font-size( 'l' );
    font-weight: font-weight( 'large' );
}

.gst-ticket-card-variant-5__extra {
    display: flex;
    padding-top: theme-spacing( 1 );
    align-items: center;

    .gst-ticket-card-variant-5__extra-status {
        line-height: line-height( 'xl' );
        color: theme-color( 'tertiary' );
        font-size: font-size( 's' );
    }
}

.gst-ticket-card-variant-5__price-wrap {
    max-width: 40%;
}

.gst-ticket-card-variant-5__price {
    line-height: line-height( 'xl' );
    color: theme-color( 'primary' );
    font-size: font-size( 's' );
    font-weight: font-weight( 'large' );
}

.gst-ticket-card-variant-5__price-breakdown {
    color: theme-color( 'tertiary' );
    font-size: font-size( 'xxs' );
}

.gst-ticket-card-variant-5__selected-ticket-icon {
    path {
        fill: theme-color( 'success' ) !important;
    }
}
</style>
