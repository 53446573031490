<template>
    <div :ref="'room-' + item.id" class="gst-room-card-variant-2">
        <div class="gst-room-card-variant-2__room-details px-4 py-md-4">
            <RoomCarousel :images="item.images" />
            <div
                class="amenities-container u-width-100">
                <h6 class="room-name">
                    {{ item | roomName }}
                </h6>
                <ElementsClamp :items="amenitiesVisible" class="amenities mt-1" tag-item="span" class-item="d-inline-block gst-room-card-variant-2__amenity">
                    <template slot="item" slot-scope="slotProps">
                        {{ slotProps.item }}
                    </template>
                    <template slot="after" slot-scope="slotProps">
                        <div class="d-inline" @click.stop="" @mousedown.stop="" @touchstart.stop="">
                            <v-tooltip v-if="slotProps.remainingCount" :bottom="isDesktop" :left="!isDesktop" :open-on-click="!$vuetify.breakpoint.mdAndUp" :open-on-hover="$vuetify.breakpoint.mdAndUp">
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-if="isDesktop" class="gst-room-card-variant-2__amenity-remaining" v-bind="attrs" v-on="on">
                                        + {{ slotProps.remainingCount }} {{ $t( '_common:terms.more' ) }}
                                    </span>
                                    <span v-else class="gst-room-card-variant-2__amenity-remaining" v-bind="attrs" v-on="on">
                                        [...]
                                    </span>
                                </template>
                                <span v-for="( amenity, k ) in amenities.slice( amenities.length - slotProps.remainingCount, amenities.length )" :key="k">
                                    {{ amenity | roomAmenity }} <br />
                                </span>
                            </v-tooltip>
                        </div>
                    </template>
                </ElementsClamp>
            </div>
        </div>
        <div class="gst-room-card-variant-2__offers d-flex flex-column align-self-stretch">
            <RoomOffer v-for="(rate, index) in item.rates"
                :key="index"
                class="offer"
                :rate="rate"
                :bundle-products="bundleProductsWithCurrentRoom"
                :selected="isRateSelected(rate)"
                @click="selectRoomWithRate(rate)" />
        </div>
    </div>
</template>

<script>
    import ElementsClamp from '@core/shared/components/misc/ElementsClamp';
    import { create as CreateBundleProductsModel } from '@tenant/app/modelsV2/BundleProductsModel';
    import RoomCarousel from './RoomCardVariant2/RoomCarousel';
    import RoomOffer from './RoomCardVariant2/RoomOffer';

    export default {
        name: 'RoomCardVariant2',
        components: {
            RoomCarousel,
            ElementsClamp,
            RoomOffer
        },
        props: {
            item: {
                type: Object,
                required: true
            },
            bundleProducts: {
                type: Object,
                default: function ( ) {
                    return CreateBundleProductsModel( );
                }
            },
            isRoomSelected: {
                type: Boolean,
                default: false
            },
            isRoomFocused: {
                type: Boolean,
                default: false,
            }
        },
        computed: {
            isDesktop( ) {
                return  this.$vuetify.breakpoint.mdAndUp;
            },
            amenities( ) {
                return this.item.amenities;
            },
            amenitiesVisible( ) {
                return this.item.amenities.filter( item => item.quantity !== 0 ).map( item => this.$options.filters.roomAmenity( item ) );
            },
            bundleProductsWithCurrentRoom( ) {
                const ret = this.bundleProducts._clone( );
                ret.hotelReservation.hotel.selectedRoom = this.item;

                ret.setHotelReservationSelectRoom( this.item );

                return ret;
            }
        },
        methods: {
            selectRoomWithRate( selectedRate ) {
                /**
                 * TODO ionutt
                 * THIS IS a bug.
                 * We are changing elements stored in Vuex
                 */
                const selectedRoomWithRate = this.item;
                selectedRoomWithRate.selectedRate = selectedRate;
                this.$emit( 'click', selectedRoomWithRate );
            },
            isRateSelected( rate ) {
                if( !this.isRoomSelected ) {
                    return false;
                }

                return rate.id === this.item.selectedRate.id;
            }
        },
        mounted() {
            this.$nextTick( ( ) => {
                if( this.isRoomFocused ) {
                    this.$emit( 'focusedRoomMounted', this.$refs['room-' + this.item.id] );
                }
            } );
        }
    };
</script>

<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";

.gst-room-card-variant-2 {
    display: flex;
    border: 1px solid theme-color-hex( 'senary-darken-2' );
    align-items: center;
    flex-direction: row;

    .gst-room-card-variant-2__room-details {
        display: flex;
        max-width: 33%;
        flex-direction: column;
        border-right: 1px solid theme-color-hex( 'senary-darken-2' );
        align-self: stretch;
        align-items: center;

        .amenities-container {
            display: flex;
            margin-top: theme-spacing( 3 );
            flex: 1;
            flex-direction: column;
            align-items: flex-start;
            max-width: 270px;

            .room-name {
                line-height: line-height( 'xl' );
                color: theme-color( 'quaternary' );
                font-size: font-size( 's' );
                font-weight: font-weight( 'large' );
            }

            .amenities {
                line-height: line-height( 'xxxxl' );
                font-size: font-size( 'xxxs' );
            }

            .gst-room-card-variant-2__amenity {
                padding: theme-spacing( 0, 1 );
                margin: theme-spacing( 1, 1, 0, 0 );
                background-color: theme-color( 'background-success' );
                color: theme-color( 'success' );
                border-radius: 4px;
            }

            .gst-room-card-variant-2__amenity-remaining {
                height: 30px;
                padding: 4px;
                margin-top: 8px;
                color: theme-color( 'success' );
            }
        }
    }

    .gst-room-card-variant-2__offers {
        width: 100%;

        .offer {
            align-items: stretch;
            flex: 1 1 auto;
            border-bottom: 1px solid theme-color-hex( 'senary-darken-2' );

            &:last-child {
                border-bottom: 0;
            }
        }
    }

    @include mobile-only {
        border: 0;
        flex-direction: column;

        .gst-room-card-variant-2__room-details {
            min-width: 100%;

            .amenities-container {
                width: 100%;
                padding: 12px 0;
                margin-top: 0;
                max-width: unset;

                .room-name {
                    padding: 0;
                }

                .amenities {
                    height: auto;
                    padding: 0;
                }
            }
        }

        .gst-room-card-variant-2__offers {
            .offer {
                border: 1px solid theme-color-hex( 'senary' );
                margin: theme-spacing( 0, 4, 4, 4 );
                border-radius: border-radius( 'xxs' );

                &:last-child {
                    border: 1px solid theme-color-hex( 'senary' );
                    margin-bottom: theme-spacing( 0 );
                }
            }
        }
    }
}

.gst-room-card-variant-2:last-child {
    @include mobile-only {
        margin-bottom: theme-spacing( 4 );
    }
}
</style>
