<template>
    <div class="gst-cancellation-policies" :data-test-id="'cancellation-policies'">
        <div class="gst-cancellation-policies__policy">
            <BaseIcon :symbol-id="showFreeCancellation ? 'icons--check_round' : 'icons--calendar_cancellation'"
                class="gst-cancellation-policies__icon flex-shrink-0"
                :class="{'gst-cancellation-policies__icon--free': showFreeCancellation }"
                width="18"
                height="18" />
            <div class="gst-cancellation-policies__items">
                <span v-for="( item, index ) in itemsToDisplay" :key="index">
                    {{ item.description }}
                </span>
                <span v-if="!cancellationPolicies.length">
                    {{ $t( '_common:terms.nonRefundable' ) }}
                </span>
            </div>
        </div>
        <a v-if="cancellationPolicies.length > numberOfItemsToDisplay" 
            class="gst-cancellation-policies__toggle"
            data-test-id="cancellationPoliciesToggle"
            @click="toggleShowAll"
            @keypress="toggleShowAll">
            {{ toggleText }}
        </a>
    </div>
</template>

<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import dateConstants from '@core/utils/constants/date';
    import { isAfter as dateUtilsIsAfter } from '@core/utils/dateUtils';
    import dateFilter from '@tenant/app/filters/date';
    import currencyFilter from '@tenant/app/filters/currency';


    export default {
        name: 'CancellationPolicies',
        components: {
            BaseIcon
        },
        props: {
            data: {
                type: Array,
                required: true
            },
            numberOfItemsToDisplay: {
                type: Number,
                default: 1
            },
            currency: {
                type: String,
                default: 'USD'
            }
        },
        data() {
            return {
                showAll: false
            };
        },
        computed: {
            cancellationPolicies( ) {
                const filteredPolicies = this.data.reduce( ( acc, policy, index ) => {
                    const date = new Date( policy.fromAt );
                    const amount = currencyFilter( policy.amount, this.currency );

                    if ( dateUtilsIsAfter( date, this.today ) ) { 
                        if ( !index ) {
                            acc.push( { 
                                description: this.$t( '_common:terms.newFreeCancellation', { 
                                    date: dateFilter( date, dateConstants.FORMAT.UI_DATE ), 
                                    time: dateFilter( date, dateConstants.FORMAT.TIME ) 
                                } ),
                                freeCancellation: true
                            } );
                        } 
                        acc.push( {
                            description: policy.nonRefundable ?  
                                this.$t( '_common:terms.fullPurchasePrice', { 
                                    date: dateFilter( date, dateConstants.FORMAT.UI_DATE ), time: dateFilter( date, dateConstants.FORMAT.TIME ) 
                                } ) :
                                this.$t( '_common:terms.feeCancellation', { 
                                    date: dateFilter( date, dateConstants.FORMAT.UI_DATE ), time: dateFilter( date, dateConstants.FORMAT.TIME ), amount 
                                } ),
                            fromAt: policy.fromAt
                        } );
                    }

                    return acc;
                }, [] );

                return filteredPolicies;
            },
            today( ) {
                return new Date();
            },
            showFreeCancellation( ) {
                return this.itemsToDisplay[0]?.freeCancellation;
            },
            toggleText( ) {
                return !this.showAll ? this.$t( '_common:terms.seeFullCancellationPolicy' ) : this.$t( '_common:terms.minusLess' );
            },
            itemsToDisplay( ) {
                return this.showAll ? this.cancellationPolicies : this.cancellationPolicies.slice( 0, this.numberOfItemsToDisplay );
            }
        },
        methods: {
            toggleShowAll( event ) {
                event.stopPropagation( );
                this.showAll = !this.showAll;
            }
        }
    };
</script>

<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";

.gst-cancellation-policies {
    display: flex;
    line-height: normal;
    color: theme-color( 'quaternary' );
    font-size: font-size( 'xxs' );
    flex-direction: column;
    gap: theme-spacing( 3 );

    .gst-cancellation-policies__icon {
        .gst-svg-icon {
            stroke: theme-color( 'tertiary' );
            fill: none;
        }
    }

    .gst-cancellation-policies__icon--free {
        .gst-svg-icon {
            fill: theme-color( 'success' );
            stroke: none;
        }
    }

    .gst-cancellation-policies__policy {
        display: flex;
        gap: theme-spacing( 2 );
    }

    .gst-cancellation-policies__items {
        display: flex;
        flex-direction: column;
        gap: theme-spacing( 3 );
    }

    .gst-cancellation-policies__toggle {
        color: theme-color( 'primary' );
        font-weight: font-weight( 'large' );
        margin-left: theme-spacing( 6 );
    }
}
</style>
