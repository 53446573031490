<template>
    <div class="gst-ticket-card-variant-6 d-flex flex-row">
        <div class="gst-ticket-card-variant-6__image-wrapper flex-shrink-1">
            <TicketVenueImage
                :preview="true"
                class="gst-ticket-card-variant-6__image"
                :image-props="{
                    src: ticket.venueSeatMapUrl,
                    height: imageHeight ? imageHeight : $vuetify.breakpoint.mdAndUp ? '105px' : '73px',
                    width: imageWidth ? imageWidth : $vuetify.breakpoint.mdAndUp ? '140px' : '98px',
                    contain: true
                }" />
        </div>
        <div class="gst-ticket-card-variant-6__content flex-grow-1 d-flex flex-column justify-center align-start">
            <h5 class="gst-ticket-card-variant-6__seats">
                {{ ticket | seatLocation }}
                <template v-if="$vuetify.breakpoint.mdAndUp">
                    •
                </template>
                <template v-if="!$vuetify.breakpoint.mdAndUp">
                    <br />
                </template>
                <template v-if="ticket.seats">
                    {{ $t( '_common:entities.seat.seat', { count: seats.length } ) }}
                </template>
                {{ { seats: ticket.seats } | seat( { joinChar: ', '} ) }}
            </h5>
            <div class="gst-ticket-card-variant-6__ticket-type">
                {{ tickets }}
            </div>
        </div>
        <slot name="actions">
        </slot>
    </div>
</template>

<script>
    import { isResale as ticketUtilsIsResale } from '@core/utils/ticketUtils';
    import TicketVenueImage from '@tenant/app/components/images/TicketVenueImage';

    export default {
        name:  'TicketCardVariant6',
        components: {
            TicketVenueImage
        },
        props: {
            ticket: {
                type: Object,
                required: true
            },
            quantity: {
                type: Number,
                default: 1
            },
            imageWidth: {
                type: [ String, Number ],
                default: null
            },
            imageHeight: {
                type: [ String, Number ],
                default: null
            }
        },
        computed: {
            seats( ) {
                return this.ticket.seats;
            },
            isResaleTicket() {
                return ticketUtilsIsResale( this.ticket.selection );
            },
            tickets() {
                return this.$t(
                    this.isResaleTicket ? '_common:terms.ticketResaleWithCount' : '_common:terms.ticketStandardWithCount',
                    { count: this.quantity }
                );
            },
        },
    };
</script>

<style lang="scss">
    @import '@scssVariables';
    @import '@scssMixins';

    $borderColor: #E3E3E3;

    .gst-ticket-card-variant-6 {
        border: 1px solid $borderColor;
        border-radius: border-radius( 'xs' );

        .gst-ticket-card-variant-6__image-wrapper {
            border-right: 1px solid $borderColor;
        }

        .gst-ticket-card-variant-6__image {
            -webkit-box-align: start !important;
            -ms-flex-align: start !important;
            align-items: flex-start !important;
        }

        .gst-ticket-card-variant-6__content {
            padding-left: theme-spacing( 4 );

            .gst-ticket-card-variant-6__seats {
                line-height: line-height( 'xl' );
                font-size: font-size( 's' );
                font-weight: font-weight( 'large' );
            }

            .gst-ticket-card-variant-6__ticket-type {
                line-height: line-height( 's' );
                color: theme-color( 'tertiary' );
                font-size: font-size( 'xxs' );
                font-weight: font-weight( 'regular' );
            }
        }
    }
</style>