<template>
    <div
        v-if="$vuetify.breakpoint.mdAndUp"
        class="gst-add-hotel-reservation-filter u-width-100 u-position-relative"
        tabindex="0"
        @click="onClickDesktopContainerDo"
        @keypress.enter="onClickDesktopContainerDo">
        <div class="overlay" tabindex="0" @click="onClickOverlayDo" @keypress.enter="onClickOverlayDo"></div>
        <div class="gst-add-hotel-reservation-filter__content u-position-absolute u-height-100 d-flex flex-column">
            <LocationSearchStoreDropDown
                v-if="canEditLocation"
                ref="location"
                :value="location"
                class="ma-4"
                :error-messages="_extractValidationsMessages( 'location' )"
                @input="onLocationChangeDo" />
            <DatePickerTooltipDisabledDates
                v-model="dates"
                full-width
                flat
                range
                color="primary"
                :min="minBookingStartDate"
                class="gst-add-hotel-reservation-filter__picker"
                no-title />
            <div
                class="d-flex flex-column pa-4 flex-grow-1 u-overflow-auto-y"
                :class="[$vuetify.breakpoint.mdAndUp ? 'pr-6' : 'pr-3']">
                <h5 class="gst-rooms-tabs-filters__guests-title">
                    {{ $t( '_common:terms.guest_plural') }}
                </h5>
                <div class="d-flex gst-add-hotel-reservation-filter__form-input pr-sm-0 align-center justify-space-between">
                    <p>{{ $t( '_common:terms.adult_plural' ) }}</p>
                    <div>
                        <NumericStep :min="1" :max="99" :value="adultsCount" :icons-attrs="iconsAttrs" @input="updateAdultsCount" />
                    </div>
                </div>
                <div class="d-flex gst-add-hotel-reservation-filter__form-input pr-sm-0 align-center justify-space-between">
                    <p>{{ $t( '_common:terms.room_plural' ) }}</p>
                    <div>
                        <NumericStep :min="1" :max="adultsCount" :value="roomsCount" :icons-attrs="iconsAttrs" @input="updateRoomsCount" />
                    </div>
                </div>
                <div class="d-flex gst-add-hotel-reservation-filter__form-input pr-sm-0 align-center justify-space-between">
                    <div class="d-flex align-start flex-column">
                        <p>
                            {{ $t( '_common:terms.children' ) }}
                        </p>
                        <span class="children-ages-label">{{ $t('labels.children') }}</span>
                    </div>
                    <div>
                        <NumericStep :min="0" :max="99" :value="childrenCount" :icons-attrs="iconsAttrs" @input="updateChildrenCount" />
                    </div>
                </div>
                <div v-if="childrenCount" class="children-ages-container d-flex flex-wrap">
                    <div v-for="(_, idx) in childrenCount" :key="idx" ref="childAgeContainer" class="col-3 px-0 py-1">
                        <div class="child-age">
                            <p>{{ $t( '_common:terms.childAge', { index: idx + 1 } ) }}</p>
                            <BaseFormSelect
                                :id="`select.childAges${idx}`"
                                v-model="childAges[idx]"
                                v-ripple
                                :options="childAgeOptions"
                                :clearable="false"
                                data-test-id="select.children"
                                class="gst-base-form-select-variant-2 u-width-100 u-height-100" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="gst-add-hotel-reservation-filter__footer d-flex flex-column">
                <div class="gst-add-hotel-reservation-filter__buttons d-flex flex-row align-center">
                    <BaseButton
                        text
                        color="primary"
                        class="px-0 text-capitalize"
                        width="auto"
                        @click="reset()">
                        {{ $t('_common:buttons.reset') }}
                    </BaseButton>
                    <div class="flex-grow-1"></div>
                    <BaseButton
                        text
                        color="primary"
                        class="mr-6 text-capitalize"
                        width="auto"
                        @click="$emit('close')">
                        {{ $t('_common:buttons.cancel') }}
                    </BaseButton>
                    <PulseButton
                        class="px-0 gst-apply-button  text-capitalize mr-4"
                        :is-animated="applyButtonIsAnimated"
                        :disabled="!startDate"
                        :placeholder="$t('_common:buttons.apply')"
                        @click="save" />
                </div>
            </div>
        </div>
    </div>
    <BaseBottomModal
        v-else
        v-model="showModal"
        overlay-opacity="0.8"
        content-class="gst-add-hotel-reservation-filter d-flex flex-column">
        <HeaderVariant1
            class="flex-grow-0"
            :title="$t( '_common:terms.changeDatesAndGuests' )"
            @close="onCloseModalDo" />
        <div class="u-width-100 flex-grow-1 u-overflow-auto-y">
            <LocationSearchStoreDropDown
                v-if="canEditLocation"
                ref="location"
                :value="location"
                class="ma-4"
                :error-messages="_extractValidationsMessages( 'location' )"
                @input="onLocationChangeDo" />
            <DatePickerTooltipDisabledDates
                v-model="dates"
                full-width
                flat
                range
                color="primary"
                :min="minBookingStartDate"
                class="gst-add-hotel-reservation-filter__picker"
                no-title />
            <div
                class="d-flex flex-column pa-4 flex-grow-1"
                :class="[$vuetify.breakpoint.mdAndUp ? 'pr-6' : 'pr-3']">
                <h5 class="gst-rooms-tabs-filters__guests-title">
                    {{ $t( '_common:terms.guest_plural') }}
                </h5>
                <div class="d-flex gst-add-hotel-reservation-filter__form-input pr-sm-0 align-center justify-space-between">
                    <p>{{ $t( '_common:terms.adult_plural' ) }}</p>
                    <div>
                        <NumericStep :min="1" :max="99" :value="adultsCount" :icons-attrs="iconsAttrs" @input="updateAdultsCount" />
                    </div>
                </div>
                <div class="d-flex gst-add-hotel-reservation-filter__form-input pr-sm-0 align-center justify-space-between">
                    <p>{{ $t( '_common:terms.room_plural' ) }}</p>
                    <div>
                        <NumericStep :min="1" :max="adultsCount" :value="roomsCount" :icons-attrs="iconsAttrs" @input="updateRoomsCount" />
                    </div>
                </div>
                <div class="d-flex gst-add-hotel-reservation-filter__form-input pr-sm-0 align-center justify-space-between">
                    <div class="d-flex align-start flex-row">
                        <p>
                            {{ $t( '_common:terms.children' ) }}
                        </p>
                        <span class="children-ages-label">({{ $t('labels.children') }})</span>
                    </div>
                    <div>
                        <NumericStep :min="0" :max="99" :value="childrenCount" :icons-attrs="iconsAttrs" @input="updateChildrenCount" />
                    </div>
                </div>
                <div v-if="childrenCount" class="children-ages-container d-flex flex-wrap">
                    <div v-for="(_, idx) in childrenCount" :key="idx" ref="childAgeContainer" class="col-4 px-0 py-1">
                        <div class="child-age">
                            <p>{{ $t( '_common:terms.childAge', { index: idx + 1 } ) }}</p>
                            <BaseFormSelect
                                :id="`select.childAges${idx}`"
                                v-model="childAges[idx]"
                                v-ripple
                                :options="childAgeOptions"
                                :clearable="false"
                                data-test-id="select.children"
                                class="gst-base-form-select-variant-2 u-width-100 u-height-100" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="gst-add-hotel-reservation-filter__footer">
            <div class="gst-add-hotel-reservation-filter__buttons">
                <BaseButton
                    color="primary"
                    :disabled="!startDate"
                    class="text-capitalize"
                    width="90%"
                    @click="save">
                    {{ $t('_common:buttons.apply') }}
                </BaseButton>
                <BaseButton
                    text
                    color="primary"
                    :disabled="!startDate"
                    class="text-capitalize"
                    width="90%"
                    @click="reset">
                    {{ $t('_common:buttons.reset') }}
                </BaseButton>
            </div>
        </div>
    </BaseBottomModal>
</template>

<script>
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import BaseBottomModal from '@core/shared/components/modals/BaseBottomModal';
    import NumericStep from '@core/shared/components/input/NumericStep';
    import LocationSearchStoreDropDown from '@tenant/app/components/misc/LocationSearchStoreDropDown/LocationSearchStoreDropDown';
    import HeaderVariant1 from '@tenant/app/components/modals/HeaderVariant1';
    import DatePickerTooltipDisabledDates from '@tenant/app/components/date/DatePickerTooltipDisabledDates.vue';
    import BaseFormSelect from '@tenants/ticketmaster/app/components/inputs/BaseFormSelect.vue';
    import PulseButton from '@tenants/ticketmaster/app/components/buttons/PulseButton.vue';
    import FilterMixin from './FilterMixin';

    export default {
        name: 'AddHotelReservationFilter',
        components: {
            DatePickerTooltipDisabledDates,
            BaseBottomModal,
            BaseButton,
            HeaderVariant1,
            NumericStep,
            BaseFormSelect,
            LocationSearchStoreDropDown,
            PulseButton
        },
        mixins: [
            FilterMixin
        ],
        methods: {
            onCloseModalDo( ) {
                this.$emit( 'close' );
            }
        }
    };
</script>

<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-add-hotel-reservation-filter {
        @include font-size( 's' );
        @include font-weight( 'regular' );

        background-color: theme-color( 'white' );

        .gst-rooms-tabs-filters__guests-title {
            font-weight: font-weight( 'large' );
        }

        .gst-add-hotel-reservation-filter__form-input {
            font-size: font-size( 's' );

            p {
                margin: 0;
                color: theme-color( 'primary' );
                font-size: font-size( 'xs' );

                span {
                    @include font-size('xxs');

                    display: block;
                    color: theme-color( 'tertiary' );
                }
            }

            .gst-numeric-step-input__plus-btn,
            .gst-numeric-step-input__minus-btn {
                height: 24px !important;
                width: 24px !important;
                min-width: unset !important;
            }

            .gst-numeric-step-input .gst-numeric-step-input__plus-btn .gst-svg-icon,
            .gst-numeric-step-input .gst-numeric-step-input__minus-btn svg .gst-svg-icon {
                fill: theme-color( 'white' );
            }

            .gst-numeric-step-input {
                .v-btn {
                    height: 24px !important;
                    width: 24px !important;
                    padding: theme-spacing( 3 );
                    background-color: theme-color( 'primary' );
                    border-radius: 24px;

                    &.gst-numeric-step-input__minus-btn--disabled,
                    &.gst-numeric-step-input__plus-btn--disabled {
                        background: rgba( theme-color-hex( 'primary' ), 0.3 );
                    }

                    ::v-deep .v-btn-content {
                        svg {
                            height: 16px !important;
                            width: 16px !important;
                        }
                    }
                }

                .gst-numeric-step-input__content {
                    border: 0;
                    background: none;
                    font-size: 22px;
                    font-weight: font-weight( 'regular' );
                    min-height: 20px;
                    min-width: 60px;
                    text-align: center;
                }
            }

            .children-ages-label {
                color: theme-color( 'tertiary' );
            }
        }

        .gst-date-picker-tooltip-disabled-dates__date-picker .v-date-picker-table--date .v-btn {
            border-radius: border-radius( 'xs' );
        }

        .children-ages-container {
            padding: theme-spacing( 3 ) 0;

            .child-age {
                padding: theme-spacing( 2 );
                border: 1px solid theme-color( 'septenary' );
                margin-right: theme-spacing( 1 );
                background: theme-color( 'white' );
                min-height: 48px;
                border-radius: border-radius( 'xxs' );

                p {
                    position: relative;
                    left: theme-spacing( 1 );
                    line-height: line-height( 'xxs' );
                    margin-bottom: 0;
                    color: theme-color( 'quaternary' );
                    font-size: font-size( 'xxxxs' );
                }
            }

            .gst-base-form-select {
                height: 24px;
                line-height: font-size( 'm' );
                border: none;
                font-size: font-size( 'xxs' );
                font-weight: font-weight( 'large' );
            }

            .gst-base-form-select .gst-base-form-select__input {
                padding-left: theme-spacing( 1 );
                color: theme-color( 'quaternary' ) !important;
            }

            .gst-base-form-select .gst-base-form-select__icon {
                right: 0;
                height: 16px;
            }
        }

        .gst-add-hotel-reservation-filter__footer {
            background-color: theme-color( 'quinary' );
            text-align: center;

            .gst-apply-button {
                background-color: theme-color( 'primary' );
                min-width: 110px;
                min-height: 40px;
            }

            .gst-add-hotel-reservation-filter__buttons {
                padding: theme-spacing( 4, 4, 3, 4 );

                .v-btn {
                    font-weight: font-weight( 'large' );
                }
            }
        }

        .overlay {
            position: fixed;
            top: 50px;
            left: 0;
            height: 100vh;
            width: 100vw;
            background-color: transparent !important;
            z-index: $z-index-event-page-over-layer-top + 2;
        }

        .gst-add-hotel-reservation-filter__content {
            z-index: $z-index-event-page-over-layer-top + 3;
        }

        @include mobile-only {
            .gst-date-picker-tooltip-disabled-dates__date-picker .v-date-picker-table--date .v-btn {
                border-radius: border-radius( 'xxs' );
            }

            .gst-add-hotel-reservation-filter__form-input {
                font-size: font-size( 's' );

                p {
                    margin: 0;
                    color: theme-color( 'primary' );
                    font-size: font-size( 's' );

                    span {
                        @include font-size('s');

                        display: block;
                        color: theme-color( 'tertiary' );
                    }
                }

                .gst-numeric-step-input .gst-numeric-step-input__plus-btn .gst-svg-icon,
                .gst-numeric-step-input .gst-numeric-step-input__minus-btn svg .gst-svg-icon {
                    fill: theme-color( 'white' );
                }

                .gst-numeric-step-input {
                    .v-btn {
                        height: 32px !important;
                        width: 32px !important;
                        padding: theme-spacing( 3 );
                        background-color: theme-color( 'primary' );
                        border-radius: 32px;

                        &.gst-numeric-step-input__minus-btn--disabled,
                        &.gst-numeric-step-input__plus-btn--disabled {
                            background: rgba( theme-color-hex( 'primary' ), 0.3 );
                        }

                        ::v-deep .v-btn-content {
                            svg {
                                height: 16px !important;
                                width: 16px !important;
                            }
                        }
                    }

                    .gst-numeric-step-input__content {
                        border: 0;
                        background: none;
                        font-size: 22px;
                        font-weight: font-weight( 'regular' );
                        min-height: 26px;
                        min-width: 60px;
                        text-align: center;
                    }
                }

                .children-ages-label {
                    color: theme-color( 'tertiary' );
                    margin-left: theme-spacing( 1 );
                }
            }

            .children-ages-container {
                max-height: 400px;
                overflow-y: auto;
            }

            .gst-add-hotel-reservation-filter__footer {
                padding: theme-spacing( 4, 4, 8, 4 );
                background-color: theme-color( 'quinary' );

                .gst-add-hotel-reservation-filter__buttons {
                    .v-btn {
                        letter-spacing: normal;
                    }
                }
            }
        }
    }

    @include mobile-only {
        .gst-add-hotel-reservation-filter {
            overflow: auto !important;

            .gst-add-hotel-reservation-filter__footer {
                padding-bottom: theme-spacing( 0 ) !important;
            }
        }
    }
</style>
