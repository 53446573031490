<template>
    <div
        class="gst-add-hotel-reservation u-width-100 d-flex flex-column"
        :class="{ 'gst-add-hotel-reservation--with-toggle-view': allowToggleView }"
        :style="{ 'flex': 1, 'min-height': 0 }">
        <AddHotelReservationHeader
            :title="headerTitle"
            :start-date-time="startDateTimeFilters"
            :end-date-time="endDateTimeFilters"
            :rooms-count="roomsCountFilters"
            :guests="guestsFilters"
            :value="value"
            :allow-update="!hasValue"
            :show-filter="showFilter"
            :read-only="readOnlyFilters"
            :enable-location-search="isOpenInventoryFlow"
            :search-title="selectedLocation"
            class="mt-4"
            @change-show-filter="toggleShowFilter" />
        <v-fade-transition hide-on-leave>
            <div
                v-if="showFilter"
                :style="filterStyle"
                class="u-position-relative flex-grow-1">
                <AddHotelReservationFilter
                    class="pull-front"
                    @close="closeShowFilter" />
            </div>
        </v-fade-transition>
        <template v-if="!showFilter || !$vuetify.breakpoint.mdAndUp">
            <div
                v-show="!hasValue"
                :style="{ 'flex': 1, display: 'flex', 'min-height':0 }">
                <HotelsMap
                    v-if="isHotelsMapEnabled"
                    v-show="showMap"
                    class="flex-grow-1"
                    :event="event"
                    :bundle-products="bundleProducts"
                    :bus-events-parent="busEvents"
                    :uniform-hotel-card="uniformHotelCard"
                    :is-visible="currentView === 'map'"
                    @open-hotel-card="onMapHotelCardOpenedDo( $event )"
                    @close-hotel-card="onMapHotelCardClosedDo( )" />
                <HotelsList
                    :style="hotelsListStyle"
                    :distance-unit-of-measure="distanceUnitOfMeasure"
                    :in-location="inLocation"
                    :bundle-products="bundleProductsFinal"
                    :bus-events-parent="busEvents"
                    :highlight-item="highlightedItem"
                    :limit="limit"
                    :load-only-first-limit="loadOnlyFirstLimit"
                    :uniform-hotel-card="uniformHotelCard"
                    @hotels-loaded="$emit( 'hotels-loaded', $event )" />
            </div>
            <HotelSelectedCard
                v-if="hasValue"
                :distance-unit-of-measure="distanceUnitOfMeasure"
                :in-location="inLocation"
                :bundle-products="bundleProducts"
                :bus-events-parent="busEventsSelected"
                :item="value.item"
                :distance-from="isOpenInventoryFlow" />
            <BaseButton
                v-if="showToggleView"
                elevation="2"
                class="gst-add-hotel-reservation__toggle-view"
                :style="toggleViewStyle"
                @click.stop="onToggleViewDo( )">
                <BaseIcon v-show="showMap" symbol-id="icons--list" height="24" width="24" />
                <BaseIcon v-show="showList" symbol-id="icons--map" height="24" width="24" />
                <span class="ml-1">{{ toggleButtonText }}</span>
            </BaseButton>
        </template>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { mapActions, mapState } from 'vuex';
    import DynamicStoreModule from '@core/mixins/DynamicStoreModule';
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import { addDays, parseDateString } from '@tenant/app/utils/dateUtils';
    import { getValidLocationFromQueryParams, getValidStayPeriodFromQueryParams, getValidGuestsFromQueryParams } from '@tenant/app/utils/search/validateQuery';
    import searchHotelReservationConstants from '@tenant/app/utils/constants/searchHotelReservation';
    import { create as CreateBundleProductsModel } from '@tenant/app/modelsV2/BundleProductsModel';
    import { create as CreateBundleProductsHotelReservationModel } from '@tenant/app/modelsV2/BundleProductsHotelReservationModel';
    import { locationParser } from '@tenant/app/utils/locations';
    import GuestsModel from '@tenant/app/models/GuestsModel';
    import HotelsList from './AddHotelReservation/HotelsList';
    import HotelsMap from './AddHotelReservation/HotelsMap';
    import HotelSelectedCard from './AddHotelReservation/HotelSelectedCard';
    import AddHotelReservationHeader from './AddHotelReservation/Header.vue';
    import AddHotelReservationFilter from './AddHotelReservation/Filter.vue';
    import EventFlowMixin from './mixins/EventFlowMixin';

    export default {
        name: 'AddHotelReservation',
        components: {
            AddHotelReservationHeader,
            HotelsList,
            HotelSelectedCard,
            AddHotelReservationFilter,
            HotelsMap,
            BaseButton,
            BaseIcon
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'modules.hotel.features.addHotelReservation'
        },
        mixins: [
            DynamicStoreModule,
            EventFlowMixin
        ],
        props: {
            value: {
                type: Object,
                default: null
            },
            event: {
                type: Object,
                required: true
            },
            startDateTime: {
                type: Date,
                required: true
            },
            endDateTime: {
                type: Date,
                required: true
            },
            quantity: {
                type: Number,
                required: true
            },
            distanceUnitOfMeasure: {
                type: String,
                required: true
            },
            inLocation: {
                type: Object,
                default: function( ) {
                    return {
                        latitude: 0,
                        longitude: 0,
                        name: ''
                    };
                }
            },
            highlightedItem: {
                type: Object,
                default: null
            },
            bundleProducts: {
                type: Object,
                default: function( ) {
                    return new CreateBundleProductsModel( );
                }
            },
            range: {
                type: Number,
                default: searchHotelReservationConstants.DEFAULT.range
            },
            limit: {
                type: Number,
                default: searchHotelReservationConstants.DEFAULT.limit
            },
            loadOnlyFirstLimit: {
                type: Boolean,
                default: false
            },
            // when true the first hotel in the list will not be highlighted
            suppressAutoHighlight: {
                type: Boolean,
                default: false
            },
            defaultView: {
                type: String,
                default: 'list',
                validator: function ( value ) {
                    return [ 'list', 'map' ].includes( value );
                }
            },
            allowToggleView: {
                type: Boolean,
                default: false
            },
            // when true the hotel card layout is the same for desktop and mobile
            uniformHotelCard: {
                type: Boolean,
                default: false
            },
            readOnlyFilters: {
                type: Boolean,
                default: false
            },
            mobileHotelDetailsClass: {
                type: String,
                default: 'v-dialog__height-80'
            },
            initFromQuery: {
                type: Boolean,
                default: false
            }
        },
        data( ) {
            return {
                busEvents: new Vue( ),
                busEventsSelected: new Vue( ),
                hotelsMapLoaded: false, // flag to load the map only if the user will ever switch to map view
                currentView: null,
                toggleViewMapOffset: 30,
                toggleViewMapPopupHeight: 0,
                hideToggleView: false, // used to overwrite allowToggleView when no results are returned by filtering
                unwatchStatusLoading: null
            };
        },
        computed: {
            ...mapState( {
                list: state => state.addHotelReservationState.hotels.list,
                loading: state => state.addHotelReservationState.hotels.loading,
                listFilters: state => state.addHotelReservationState.hotels.filters,
                startDateTimeFilters: state => state.addHotelReservationState.filters.startDateTime,
                endDateTimeFilters: state => state.addHotelReservationState.filters.endDateTime,
                roomsCountFilters: state => state.addHotelReservationState.filters.roomsCount,
                guestsFilters: state => state.addHotelReservationState.filters.guests,
                showFilter: state => state.addHotelReservationState.showFilter
            } ),
            toggleButtonText( ) {
                return this.showMap ? this.$t( '_common:buttons.list' ) : this.$t( '_common:buttons.map' );
            },
            hasValue( ) {
                return !!this.value;
            },
            showNoData ( ) {
                return !this.loading && !this.list.length;
            },
            selectedLocation( ) {
                if ( this.listFilters.location.name && this.isOpenInventoryFlow ) {
                    return locationParser( this.listFilters.location, true );
                }
                return undefined;
            },
            headerTitle( ) {
                return this.isOpenInventoryFlow ? this.$t( '_components.header.titleForEvent' ) : this.$t( '_components.header.titleForVenue' );
            },
            bundleProductsFinal( ) {
                const ret = this.bundleProducts._clone( );

                ret.setHotelReservation(
                    CreateBundleProductsHotelReservationModel (
                        ret.hotelReservation.hotel
                    )
                );

                return ret;
            },
            filterStyle( ) {
                if ( this.$vuetify.breakpoint.mdAndUp ) {
                    return {
                        'flex': 1,
                        display: 'flex',
                        'min-height': 0
                    };
                }
                return {
                    display: 'none'
                };
            },
            /**
             * HotelsMap will be enabled on mobile devices, on post purchase flow, and if the
             * user will switch from list View to map View.
             * After the first switch to map View the map component will always remain in DOM
             * to prevent multiple loads of the mapbox api (reduce cost, improve ux)
             */
            isHotelsMapEnabled( ) {
                return this.allowToggleView && this.hotelsMapLoaded;
            },
            showList( ) {
                return this.currentView === 'list';
            },
            // will be used on the HotelsMap component with a v-show to only load the map component once
            showMap( ) {
                return this.currentView === 'map';
            },
            showToggleView( ) {
                return !this.hideToggleView && this.allowToggleView && !this.hasValue;
            },
            toggleViewStyle( ) {
                if ( this.showMap ) {
                    return {
                        bottom: ( this.toggleViewMapPopupHeight || this.toggleViewMapOffset ) + 'px'
                    };
                }
                return {
                    bottom: 0
                };
            },
            hotelsListStyle( ) {
                if ( !this.showList ) {
                    return {
                        display: 'none !important'
                    };
                }
                return null;
            }
        },
        watch: {
            'list.length': function ( newValue, oldValue ) {
                if ( !oldValue && newValue && !this.suppressAutoHighlight ) {
                    this.$emit( 'highlight-item', this.list[ 0 ] );
                }
                if ( !newValue ) {
                    this.$emit( 'highlight-item', null );
                    this.hideToggleView = true;
                } else {
                    this.hideToggleView = false;
                }
            },
            'showFilter': function ( newValue ) {
                this.$emit( 'change-show-filter', newValue );
            },
            'allowToggleView': function ( newValue, oldValue ) {
                if ( newValue !== oldValue && !newValue ) {
                    this.currentView = this.defaultView;
                    this.hotelsMapLoaded = false;
                }
            }
        },
        methods: {
            ...mapActions( {
                'init': 'addHotelReservationState/init',
                'resetFilters': 'addHotelReservationState/resetFilters',
                'setShowFilter': 'addHotelReservationState/setShowFilter',
                'updateFilters': 'addHotelReservationState/filters/update'
            } ),
            initiateFromQuery( ) {
                const { startDate, endDate, city, country, countryCode, latitude, longitude, name, state, stateCode, timezone, type, roomsCount, adultsCount, childrenCount, childAges } = this.$route.query;
                const startDateTime = startDate ? parseDateString( startDate ) : this.minBookingStartDate;
                const endDateTime = endDate ? parseDateString( endDate ) : addDays( startDateTime, 1 );

                const location = {
                    city,
                    country,
                    countryCode,
                    latitude,
                    longitude,
                    name,
                    state,
                    stateCode,
                    timezone,
                    type
                };

                this.init( {
                    startDateTime: startDateTime,
                    endDateTime: endDateTime,
                    location: location,
                    quantity: this.quantity,
                    range: this.range
                } );

                // if guests parameters are present
                if ( roomsCount && adultsCount ) {
                    let guests;
                    const adults = +adultsCount;
                    const rooms = +roomsCount;
                    const children = childrenCount && +childrenCount;

                    if ( children ) {
                        let ages;
                        if ( Array.isArray( childAges ) ) {
                            ages = childAges.reduce( ( acc, age, index ) => {
                                // only read as many ages as the number of children
                                if ( index < children ) {
                                    acc.push( +age );
                                }
                                return acc;
                            }, [] );
                        } else {
                            ages = [ +childAges ];
                        }
                        guests = new GuestsModel( adults, children, ages );
                    } else {
                        guests = new GuestsModel( adults );
                    }
                    this.updateFilters( {
                        roomsCount: rooms,
                        guests
                    } );
                }
            },
            updateFiltersFromQuery( ) {
                const hasLocation = getValidLocationFromQueryParams( this.$route.query );
                const hasStayPeriod = getValidStayPeriodFromQueryParams( this.$route.query, { minStartDate: this.minBookingStartDate } );
                const hasGuestsCriteria = getValidGuestsFromQueryParams( this.$route.query );

                if ( hasLocation ) {
                    this.updateFilters( {
                        location: { ...hasLocation }
                    } );
                }

                if ( hasStayPeriod ) {
                    this.updateFilters( {
                        startDateTime: parseDateString( hasStayPeriod.startDate ),
                        endDateTime: parseDateString( hasStayPeriod.endDate ),
                    } );
                }

                if ( hasGuestsCriteria ) {
                    this.updateFilters(
                        { ...hasGuestsCriteria }
                    );
                }
            },
            initiate( ) {
                const { startDateTime, endDateTime, quantity, inLocation, range } = this;

                if ( this.initFromQuery ) {
                    this.initiateFromQuery( );
                } else {
                    // init hotel reservation context
                    this.init( {
                    startDateTime,
                    endDateTime,
                    quantity,
                    location: { ...inLocation },
                    range } );

                    // update filters if we have valid query params
                    this.updateFiltersFromQuery( );
                }
            },
            openItemDetails( item, focusedRoom ) {
                if ( this.$vuetify.breakpoint.mdAndUp ) {
                    this.$emit( 'highlight-item', item, focusedRoom );
                    return;
                }
                const modal = import( '@tenant/app/modules/hotel/components/containers/HotelDetailsV2' );

                const props = {
                    'no-click-animation': true,
                    persistent: false,
                    'max-width': 920,
                    'content-class': `${this.mobileHotelDetailsClass} gst-v-dialog`,
                    scrollable: true,
                    fullscreen: false,
                    'hide-overlay': false,
                    transition: 'dialog-bottom-transition'
                };
                const componentProps = {
                    event: this.event,
                    item,
                    focusedRoom,
                    bundleProducts: this.bundleProductsFinal,
                    hasValue: this.hasValue,
                    busEventsParent: !this.hasValue ? this.busEvents : this.busEventsSelected,
                    inLocation: this.inLocation
                };

                this.$modal.showBottom( modal, componentProps, props );
            },
            addItem( item, accommodation ) {
                /**
                 * $emit input and that needs to be add to the bundle products
                 * parameter - hotel item -> and inside item.room is the selected room
                 */

                const { listFilters } = this;

                this.$emit( 'input', {
                    item: item,
                    startDateTime: accommodation ? accommodation.startDateTime : listFilters.startDateTime,
                    endDateTime: accommodation ? accommodation.endDateTime : listFilters.endDateTime,
                    guests: accommodation ? accommodation.guests : listFilters.guests,
                    roomsCount: accommodation ? accommodation.roomsCount : listFilters.roomsCount
                } );
            },
            removeItem( ) {
                this.$emit( 'input', null );
            },
            toggleShowFilter( ) {
                this.setShowFilter ( !this.showFilter );
            },
            closeShowFilter( ) {
                this.setShowFilter( false );
                this.$emit( 'change-show-filter', this.showFilter );
            },
            onToggleViewDo( ) {
                this.currentView = this.showList ? 'map' : 'list';
                if ( !this.showList && !this.hotelsMapLoaded ) {
                    this.hotelsMapLoaded = true;
                }
            },
            onMapHotelCardOpenedDo( boundingRect ) {
                this.toggleViewMapPopupHeight = boundingRect.height + 10; // add 10 for the popup bottom margin
            },
            onMapHotelCardClosedDo( ) {
                this.toggleViewMapPopupHeight = 0;
            },
            async showSearchHotelModal( ) {
                this.$modal.show(
                    ( ) => import( '@tenant/app/modules/hotel/features/addHotelReservation/SearchHotelModal' ),
                    null,
                    {
                        'max-width': '480px',
                        persistent: true,
                    }
                );
            },
            destroyStatusLoading( ) {
                if ( this.unwatchStatusLoading ) {
                    this.unwatchStatusLoading( );
                }
            }
        },
        mounted( ) {
            this.busEvents.$on( 'open-item-details', this.openItemDetails );
            this.busEvents.$on( 'add-item', this.addItem );
            this.busEventsSelected.$on( 'add-item', this.addItem );
            this.busEventsSelected.$on( 'remove-item', this.removeItem );
            this.busEventsSelected.$on( 'open-item-details', this.openItemDetails );
        },
        created( ) {
            this.currentView = this.defaultView;
            this.initiate( );
            if ( !this.event?.id && !this.initFromQuery ) {
                this.showSearchHotelModal( );
            }
            if ( this.showMap ) {
                this.hotelsMapLoaded = true;
            }
        },
        destroyed( ) {
            this.busEvents.$off( 'open-item-details' );
            this.busEvents.$off( 'add-item' );
            this.busEventsSelected.$off( 'remove-item' );
            this.busEventsSelected.$off( 'change-room' );
            this.busEventsSelected.$off( 'open-item-details' );
            this.destroyStatusLoading( );
        }
    };
</script>

<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";

@include mobile-only {
    .gst-add-hotel-reservation {
        .gst-add-hotel-reservation-header {
            margin-top: theme-spacing( 2 ) !important;

            h6 {
                padding-left: theme-spacing( 2 ) !important;
            }
        }
    }

    .gst-add-hotel-reservation--with-toggle-view {
        .gst-add-hotel-reservation-hotel-list {
            .v-list {
                padding-bottom: 100px !important;
            }
        }
    }

    .gst-add-hotel-reservation__toggle-view {
        position: fixed;
        right: 10px;
        bottom: 10px;
        margin-bottom: theme-spacing( 2 );
        background-color: theme-color( 'primary-darken-2' ) !important;
        color: white !important;
        font-weight: font-weight( 'large' );
        z-index: 8;
        text-transform: capitalize;

        svg path {
            fill: theme-color( 'white' );
        }
    }
}
</style>
