<template>
    <div class="gst-event-ticket-filter d-flex flex-column">
        <div class="gst-event-ticket-filter__title d-flex align-center justify-space-between pt-2 pt-md-4 px-4">
            <h5>{{ $t( 'title' ) }}</h5>
            <p class="mb-0">
                {{ $t( 'description' ) }}
            </p>
        </div>
        <div class="d-flex flex-row align-stretch py-2 py-md-4 px-4">
            <div class="flex-grow-1 d-flex align-stretch u-height-100">
                <BaseFormSelect
                    id="filter.select.quantity"
                    v-model="quantity"
                    v-ripple
                    :options="quantityOptions"
                    :clearable="false"
                    data-test-id="select.quantity"
                    class="gst-event-ticket-filter__quantity-input u-width-100 u-height-100 pl-4" />
            </div>
            <v-btn
                :outlined="!showFilters"
                color="primary"
                class="gst-event-ticket-filter__btn-filter ml-2"
                @click="$emit('click-filter-btn')">
                <BaseIcon symbol-id="icons--filters" :class="{'gst-event-ticket-filter__btn-filter-icon--active': showFilters }" class="gst-event-ticket-filter__btn-filter-icon mr-2" />
                {{ $t( '_common:buttons.filters') }}
            </v-btn>
        </div>
        <div v-if="$vuetify.breakpoint.mdAndUp" class="px-4 pb-4">
            <RangeEditValuesInput
                v-model="priceRange"
                :min="value.price.min"
                :max="value.price.max">
                <template v-slot:minValueLabel="slotProps">
                    {{ Math.ceil( slotProps.value ) | currencyFilter( value.price.currency, { digits: 0 }, false ) }}
                </template>
                <template v-slot:maxValueLabel="slotProps">
                    {{ Math.ceil( slotProps.value ) | currencyFilter( value.price.currency, { digits: 0 }, false ) }}+
                </template>
            </RangeEditValuesInput>
        </div>
    </div>
</template>

<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import RangeEditValuesInput from '@tenant/app/components/inputs/RangeEditValuesInput';
    import BaseFormSelect from '@tenants/ticketmaster/app/components/inputs/BaseFormSelect.vue';

    export default {
        name: 'EventTicketFilter',
        components: {
            BaseFormSelect,
            BaseIcon,
            RangeEditValuesInput
        },
        props: {
            value: {
                type: Object,
                required: true
            },
            options: {
                type: Object,
                required: true
            },
            showFilters: {
                type: Boolean,
                default: false
            }
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.eventV2.theEvent._components.eventTicketsFilter'
        },
        computed: {
            quantityOptions( ) {
                const { maxQuantity, minQuantity } = this.options;

                return new Array( maxQuantity - minQuantity + 1 ).fill( ).map( ( value, key ) => {
                    const q = key + minQuantity;

                    return {
                        id: q,
                        name: this.$t( '_common:terms.ticketWithCount', { count: q } )
                    };
                } );
            },
            quantity: {
                get( ) {
                    return this.value.quantity;
                },
                set( newValue ) {
                    this.$emit( 'input', {
                        ...this.value,
                        quantity: newValue
                    } );
                }
            },
            priceRange: {
                get( ) {
                    return this.value.price.range;
                },
                set( value ) {
                    this.$emit( 'input', {
                        ...this.value,
                        price: {
                            ...this.value.price,
                            range: value
                        }
                    } );
                }
            },
        }
    };
</script>

<style lang="scss">
    @import '@scssVariables';
    @import '@scssMixins';

    .gst-event-ticket-filter {
        border-bottom: 1px solid theme-color-hex( 'senary-darken-2' );

        .gst-event-ticket-filter__quantity-input {
            @include border-radius('xxs');

            .gst-base-form-select__wrapper {
                height: 38px;
            }

            .gst-select__input {
                @include font-size('s');
                @include font-weight('regular');

                height: 100%;
                width: 100%;
                color: theme-color( 'primary' );
            }
        }

        .gst-event-ticket-filter__title {
            h5 {
                line-height: line-height( 'xxxl' );
                font-weight: font-weight( 'large' );
            }

            p {
                line-height: line-height( 'm' );
                color: theme-color( 'tertiary' );
                font-size: font-size( 'xxs' );
                font-weight: font-weight( 'regular' );
            }
        }

        .gst-event-ticket-filter__btn-filter {
            @include border-radius('xxs');

            height: 40px !important;
            border: 1px solid theme-color( 'primary' );
            text-transform: unset;
        }

        .gst-event-ticket-filter__btn-filter-icon {
            .gst-svg-icon {
                fill: theme-color( 'primary' );
                stroke: theme-color( 'primary' );
            }
        }

        .gst-event-ticket-filter__btn-filter-icon--active {
            .gst-svg-icon {
                stroke: theme-color( 'white' );
                fill: theme-color( 'white' );
            }
        }
    }
</style>
