<template>
    <div class="gst-room-offer">
        <div class="gst-room-offer__benefits" :data-test-id="'room-offer-benefits'">
            <div v-if="isWithMealPlan" class="benefit">
                <IconCheckCircle class="gst-svg-icon__rounded" width="18" height="18" />
                <p> {{ rate.mealPlan | mealPlan }} </p>
            </div>
            <CancellationPolicies v-if="cancellationPolicies" :data="cancellationPolicies" :currency="currency" />
        </div>
        <div class="gst-room-offer__bundle-price-container">
            <div class="price-details text-left">
                <div class="col col-12 d-flex flex-column justify-start pa-0">
                    <VClamp autoresize :max-lines="2" :title="bundleProductsWithCurrentRate | bundleProductsQuantity" class="bundle-products">
                        {{ bundleProductsWithCurrentRate | bundleProductsQuantity }}
                    </VClamp>
                    <div
                        v-if="showGiftCard || showDiscountAmount"
                        class="gifts d-flex flex-wrap mt-2">
                        <SaveAmountCardChip v-if="showDiscountAmount" :amount="bundleProductsTotals.discountedAmount" :currency="currency" />
                        <GiftCardChip v-if="showGiftCard" :amount="giftCard.amount" :currency="giftCard.currency" />
                    </div>
                    <div class="prices mt-1 mb-2">
                        <span v-if="showBasePriceWithoutDiscount" class="price-with-discount">
                            {{ roundedUpBasePriceWithoutDiscountFormatted }}
                        </span>
                        <span class="price">
                            {{ roundedUpBasePriceFormatted }}
                        </span>
                    </div>
                </div>
                <div class="col col-12 order-md-last d-flex align-center pa-0 pr-1 pa-md-0">
                    <span v-if="feesExcluded.length && bundleProductsTotals.includesTaxesAndFees" class="fees">
                        {{ $t( '_common:terms.feesIncludedExcept', { amount: feesExcludedText } ) }}
                    </span>
                    <span v-else-if="feesExcluded.length" class="fees">
                        {{ $t( '_common:terms.excluded' ) }}: {{ feesExcludedText }}
                    </span>
                </div>
            </div>
            <BaseButton
                :placeholder="selectButtonText"
                class="gst-select-button"
                color="primary"
                :disabled="selected"
                @click="$emit('click')" />
        </div>
    </div>
</template>

<script>
    import VClamp from 'vue-clamp';
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import roomRateFee from '@core/utils/constants/roomRateFee';
    import { isWithoutMealPlan } from '@core/utils/roomRateMealPlanUtils';
    import { create as CreateBundleProductsModel } from '@tenant/app/modelsV2/BundleProductsModel';
    import IconCheckCircle from '@tenant/app/assets/icons/check_round.svg';
    import SaveAmountCardChip from '@tenant/app/components/chips/SaveAmountCardChip';
    import GiftCardChip from '@tenant/app/components/chips/GiftCardChip';
    import CancellationPolicies from './CancellationPolicies.vue';

    export default {
        name: 'RoomOffer',
        components: {
            VClamp,
            BaseButton,
            IconCheckCircle,
            SaveAmountCardChip,
            GiftCardChip,
            CancellationPolicies
        },
        props: {
            rate: {
                type: Object,
                default: function ( ) {
                    return null;
                }
            },
            bundleProducts: {
                type: Object,
                default: function ( ) {
                    return CreateBundleProductsModel( );
                }
            },
            selected: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                activeSlide: 0,
                displayNavigation: false
            };
        },
        computed: {
            selectButtonText( ) {
                return this.selected ? this.$t( '_common:buttons.selected' ) : this.$t( '_common:buttons.select' ) ;
            },
            bundleProductsWithCurrentRate( ) {
                const ret = this.bundleProducts._clone( );

                ret.setHotelReservationRateForSelectedRoom( this.rate );

                return ret;
            },
            bundleProductsTotals( ) {
                return this.bundleProductsWithCurrentRate.getTotals( );
            },
            showBasePriceWithoutDiscount( ) {
                return this.bundleProductsTotals.basePrice !== this.bundleProductsTotals.basePriceWithoutDiscount;
            },
            roundedUpBasePriceFormatted( ) {
                return this.$options.filters.currencyFilter( Math.ceil( this.bundleProductsTotals.basePrice ), this.currency, { digits: 0 } );
            },
            roundedUpBasePriceWithoutDiscountFormatted() {
                return this.$options.filters.currencyFilter( Math.ceil( this.bundleProductsTotals.basePriceWithoutDiscount ), this.currency, { digits: 0 } );
            },
            showDiscountAmount( ) {
                return this.showBasePriceWithoutDiscount && this.bundleProductsTotals.discountedAmount;
            },
            currency( ) {
                return this.rate.currency;
            },
            cancellationPolicies( ) {
                return this.rate.cancellationPolicies;
            },
            giftCard( ) {
                return this.rate.giftCard;
            },
            showGiftCard( ) {
                return this.giftCard ? this.giftCard.amount : 0;
            },
            feesExcluded( ) {
                return this.rate.fees
                    .map( item => item.fee )
                    .filter( item => item.typeIncluded !== roomRateFee.TYPES_INCLUDED.INCLUDED );
            },
            feesExcludedText( ) {
                return this.feesExcluded
                    .map( item => this.$options.filters.roomRateFee( item, this.currency ) )
                    .join( ', ' );
            },
            room() {
                return this.bundleProductsWithCurrentRate.hotelReservation.hotel.selectedRoom;
            },
            isWithMealPlan( ) {
                return !isWithoutMealPlan( this.rate.mealPlan );
            }
        }
    };
</script>

<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";

.gst-room-offer {
    display: flex;
    flex-direction: row;

    .gst-room-offer__benefits {
        display: flex;
        padding: theme-spacing( 4 );
        flex-direction: column;
        justify-content: center;
        flex-basis: 45%;
        min-width: 45%;
        border-right: 1px solid theme-color-hex( 'senary-darken-2' );

        .benefit {
            display: flex;
            margin-bottom: theme-spacing( 2 );
            flex-direction: row;
            align-items: center;

            &:last-child {
                margin-bottom: theme-spacing( 0 );
            }

            p {
                margin-bottom: 0;
                color: theme-color( 'quaternary' );
                font-size: font-size( 'xxs' );
            }

            .gst-svg-icon__rounded {
                margin-right: theme-spacing( 2 );
                flex-shrink: 0;
                min-width: 16px;
                min-height: 16px;

                .gst-svg-icon {
                    fill: #1BAB1E;
                }
            }
        }
    }

    .gst-room-offer__bundle-price-container {
        display: flex;
        padding: theme-spacing( 4 );
        flex-grow: 1;
        justify-content: space-between;
        align-items: center;
        gap: theme-spacing( 4 );

        .gst-select-button {
            display: flex;
            font-size: font-size( 's' );
            font-weight: font-weight( 'bold' );
            text-transform: unset;
        }

        .fees {
            max-width: 250px;
        }

        .fees,
        .bundle-products {
            line-height: line-height( 's' );
            color: theme-color( 'tertiary' );
            font-size: font-size( 'xxs' );
        }

        .gifts {
            gap: theme-spacing( 1 );
        }

        .prices {
            .price {
                line-height: line-height( 'xxxl' );
                color: theme-color( 'primary' );
                font-size: font-size( 'l' );
                font-weight: font-weight( 'large' );
            }

            .price-with-discount {
                line-height: line-height( 'xxxl' );
                margin-right: theme-spacing( 1 );
                color: theme-color( 'tertiary' );
                font-size: font-size( 's' );
                text-decoration: line-through;
            }
        }
    }

    @include mobile-only {
        flex-direction: column;

        .gst-room-offer__benefits {
            padding-right: theme-spacing( 4 );
            padding-left: theme-spacing( 3 );
            border: 0;

            .benefit {
                p {
                    line-height: normal;
                    font-size: font-size( 'xxs' );
                }
            }
        }

        .gst-room-offer__room-details {
            .amenities-container {
                width: 100%;
                padding-top: theme-spacing( 3 );
                max-width: unset;

                .gst-room-offer__name {
                    padding: theme-spacing( 0 );
                }

                .gst-room-offer__amenities {
                    height: auto;
                    padding: theme-spacing( 0 );
                }
            }
        }

        .gst-room-offer__bundle-price-container {
            width: 100%;
            padding-top: theme-spacing( 0 );
            padding-right: theme-spacing( 3 );
            padding-left: theme-spacing( 3 );
            max-width: unset;

            .price-details {
                text-align: left;
            }

            .gst-select-button {
                padding: 8px;
                font-weight: 600;
                float: right;
            }

            .prices,
            .bundle-products,
            .fees {
                text-align: left;
            }
        }
    }
}
</style>
