<template>
    <div
        class="gst-add-hotel-reservation-header"
        :class="{
            'gst-add-hotel-reservation-header--show-filter': showFilter
        }">
        <h6 class="gst-add-hotel-reservation-header__title">
            {{ title }}
        </h6>
        <div v-if="readOnly"
            class="d-flex align-center px-2 py-2 mt-2">
            <div class="d-flex align-end">
                <CalendarIcon class="calendar-icon--read-only" />
                <div>{{ date }}</div>
            </div>
            <div class="dot"></div>
            <div>{{ hotelReservationQuantity }}</div>
        </div>
        <div v-else-if="enableLocationSearch && !showFilter"
            class="gst-add-hotel-reservation-header__content-unknown-event d-flex flex-row px-2 py-2 mt-2"
            tabindex="0"
            @click="allowUpdate && $emit( 'change-show-filter', true)"
            @keypress.enter="allowUpdate && $emit( 'change-show-filter', true)">
            <div class="ml-2">
                <div class="gst-add-hotel-reservation-header__wrapper d-flex">
                    <BaseIcon symbol-id="icons--location" class="mr-2" />
                    <h6 class="gst-add-hotel-reservation-header__location-title">
                        {{ searchTitle }}
                    </h6>
                </div>
                <div class="gst-add-hotel-reservation-header__location-details">
                    <span>{{ date }}</span>
                    <span class="ml-2">{{ hotelReservationQuantity }}</span>
                </div>
            </div>
            <BaseIcon class="gst-add-hotel-reservation-header__icon ml-auto mr-3" symbol-id="icons--draw_on_map" />
        </div>
        <div v-else-if="!enableLocationSearch"
            class="gst-add-hotel-reservation-header__content d-flex flex-row flex-wrap px-2 py-2 mt-2"
            :class="{
                'gst-add-hotel-reservation-header__content--open-filter': showFilter
            }">
            <BaseButton
                large
                outlined
                class="base-button"
                @click="allowUpdate && $emit( 'change-show-filter', true)">
                <CalendarIcon class="calendar-icon" />
                {{ date }}
            </BaseButton>
            <BaseButton large
                outlined
                class="base-button"
                @click="allowUpdate && $emit( 'change-show-filter', true)">
                {{ hotelReservationQuantity }}
                <ChevronDownIcon
                    v-if="$vuetify.breakpoint.mdAndUp"
                    class="chevron-down-icon"
                    :class="{
                        'u-rotate-180': showFilter
                    }" />
            </BaseButton>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import dateConstants from '@core/utils/constants/date';
    import ChevronDownIcon from '@core/shared/assets/icons/chevron_down.svg';
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import BaseIcon from '@core/shared/components/misc/BaseIcon';
    import CalendarIcon from '@tenants/ticketmaster/app/assets/icons/calendar.svg' ;
    import datePeriod from '@tenant/app/filters/datePeriod';

    export default {
        name: 'AddHotelReservationHeader',
        components: {
            BaseButton,
            CalendarIcon,
            ChevronDownIcon,
            BaseIcon
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'modules.hotel.features.addHotelReservation._components.header'
        },
        props: {
            startDateTime: {
                type: Date,
                required: true,
            },
            endDateTime: {
                type: Date,
                required: true,
            },
            roomsCount: {
                type: Number,
                required: true,
            },
            guests: {
                type: Object,
                required: true,
            },
            allowUpdate: {
                type: Boolean,
                default: true
            },
            value: {
                type: Object,
                default: null
            },
            showFilter: {
                type: Boolean,
                default: false
            },
            readOnly: {
                type: Boolean,
                default: false
            },
            enableLocationSearch: {
                type: Boolean,
                default: false
            },
            searchTitle: {
                type: String,
                default() {
                    return this.$t( 'searchTitlePlaceholder' );
                }
            },
            title: {
                type: String,
                required: true
            }
        },
        computed: {
            ...mapGetters( {
                appLocale: 'appState/getLocale'
            } ),
            hotelReservationQuantity ( ) {
                const { roomsCount, guests } = this;
                const { adultsCount, childrenCount } = guests;

                return `${this.$t( '_common:terms.roomWithCount', { count: roomsCount } )}, ${this.$t( '_common:terms.guestWithCount', { count: adultsCount + childrenCount } )}`.toLowerCase();
            },
            date( ) {
                const format = { full: dateConstants.FORMAT.FULL, period: dateConstants.FORMAT.PERIOD, periodFull: dateConstants.FORMAT.PERIOD_FULL, time: dateConstants.FORMAT.TIME };
                return datePeriod( this.startDateTime, this.endDateTime, format, this.appLocale, false );
            }
        },
    };
</script>

<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-add-hotel-reservation-header {
        line-height: line-height( 'xxxxl' );
        background-color: theme-color( 'white' );
        overflow-y: auto;

        .gst-add-hotel-reservation-header__title {
            padding-left: theme-spacing( 4 );
            color: theme-color( 'quaternary' );
            font-size: font-size( 'xxl' );
            font-weight: font-weight( 'large' );
        }

        .gst-add-hotel-reservation-header__content {
            background-color: theme-color( 'primary-lighten-1' );
        }

        .gst-add-hotel-reservation-header__content-unknown-event {
            background-color: theme-color( 'quinary' ) !important;
            cursor: pointer;

            .gst-add-hotel-reservation-header__wrapper {
                align-items: center;

                .gst-add-hotel-reservation-header__location-title {
                    color: theme-color( 'quaternary' );
                    font-size: font-size( 'l' );
                    font-weight: font-weight( 'large' );
                }
            }

            .gst-add-hotel-reservation-header__icon {
                height: unset;
            }
        }

        .gst-add-hotel-reservation-header__content--open-filter {
            background-color: theme-color( 'primary' );
        }

        .base-button {
            padding: theme-spacing( 4 ) !important;
            border: 1px solid theme-color( 'primary' );
            margin: theme-spacing( 1, 2, 1, 0 );
            background-color: theme-color( 'white' );
            color: theme-color( 'primary' );
            font-size: font-size( 'xs' );
            letter-spacing: normal;
            text-transform: none;
            border-radius: 2px;
        }

        .calendar-icon {
            .gst-svg-icon {
                fill: theme-color( 'primary' );
            }
        }

        .calendar-icon--read-only {
            .gst-svg-icon {
                fill: theme-color( 'quaternary' );
            }
        }

        .chevron-down-icon {
            transition: 0.2s;

            .gst-svg-icon {
                fill: theme-color( 'primary' );
            }
        }

        .dot {
            height: 4px;
            width: 4px;
            margin: 0 8px;
            background-color: theme-color( 'quaternary' );
            border-radius: 50%;
        }
    }

    .gst-add-hotel-reservation-header.gst-add-hotel-reservation-header--show-filter {
        position: relative;
        z-index: $z-index-event-page-over-layer + 3;
    }

    @include mobile-only {
        .gst-add-hotel-reservation-header {
            .base-button {
                padding: theme-spacing( 4, 2 ) !important;
                border: 1px solid theme-color( 'white' );
                font-size: font-size( 'xxs' );
            }

            .gst-add-hotel-reservation-header__content-unknown-event {
                .gst-add-hotel-reservation-header__wrapper {
                    .gst-add-hotel-reservation-header__location-title {
                        font-size: font-size( 'xs' );
                    }
                }

                .gst-add-hotel-reservation-header__location-details {
                    font-size: font-size( 'xxs' );
                }
            }
        }
    }
</style>