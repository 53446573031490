<template>
    <div v-cookies="!$vuetify.breakpoint.mdAndUp" class="gst-event-sidebar__footer">
        <EventTotalsContainer
            :title="title"
            :event="event"
            :bundle-products="bundleProducts" />
    </div>
</template>

<script>
    import cookiesNudge from '@tenant/app/directives/cookiesButtonNudge.js';
    import EventTotalsContainer from '../EventTotalsContainer.vue';

    export default {
        name: 'EventSidebarFooter',
        components: {
            EventTotalsContainer
        },
        directives: {
            cookies: cookiesNudge
        },
        props: {
            title: {
                type: String,
                default: null
            },
            bundleProducts: {
                type: Object,
                default: null
            },
            event: {
                type: Object,
                required: true
            }
        }
    };
</script>