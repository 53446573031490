<template>
    <div class="gst-date-picker-tooltip-disabled-dates">
        <BaseDatePicker
            ref="picker"
            v-model="datesString"
            v-bind="$attrs"
            :events="datesStringWithEventsForReadOnly"
            :min="minString"
            :max="lastDateStringFromEventForReadOnly"
            :show-current="showCurrent"
            class="gst-date-picker-tooltip-disabled-dates__date-picker"
            :weekday-format="formatCalendarDay"
            :locale="appLocale"
            @mouseenter:date="onMouseEnterDateDo"
            @mouseleave:date="onMouseLeaveDateDo"
            @update:picker-date="onUpdatePickerDo" />
        <v-tooltip
            v-model="showTooltip"
            absolute
            left
            top
            :disabled="!targetTooltipPosition"
            :position-x="targetTooltipPositionX"
            :position-y="targetTooltipPositionY"
            content-class="gst-date-picker-tooltip-disabled-dates__tooltip-content">
            {{ $t('_common:messages.stayDateRangeExceed', { count: maxPeriod } ) }}
        </v-tooltip>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import dateConstants from '@core/utils/constants/date';
    import BaseDatePicker from '@core/shared/components/date/BaseDatePicker.vue';
    import { addDays, parseDateString, endOfMonth, eachDayOfInterval, isAfter, format, max } from '@tenant/app/utils/dateUtils';

    export default {
        name: 'DatePickerTooltipDisabledDates',
        components: {
            BaseDatePicker,
        },
        inheritAttrs: false,
        props: {
            value: {
                type: Array,
                default: null,
            },
            min: {
                type: Date,
                default: ( ) => { return new Date( ); }
            },
            maxPeriod: {
                type: Number,
                default: 30
            }
        },
        data( ) {
            return {
                targetTooltip: null,
                targetTooltipPositionX: null,
                targetTooltipPositionY: null,
                showTooltip: false
            };
        },
        computed: {
            ...mapGetters( {
                appLocale: 'appState/getLocale'
            } ),
            startDate( ) {
                return this.value[ 0 ];
            },
            endDate( ) {
                return this.value[ 1 ];
            },
            minString( ) {
                return format( this.min, 'yyyy-MM-dd' );
            },
            datesString: {
                get( ) {
                    const { startDate, endDate } = this;

                    return [
                        startDate ? this.$options.filters.date( startDate, dateConstants.FORMAT.API_DATE ) : '',
                        endDate ? this.$options.filters.date( endDate, dateConstants.FORMAT.API_DATE ) : ''
                    ].filter( item => item );
                },
                set( value ) {
                    const dates = [ ...new Set( value ) ]
                        .map( ( item ) => item ? parseDateString ( item ) : null  )
                        .sort( ( a, b )=> a.getTime( ) - b.getTime( ) );
                    const endDate = dates[ 1 ];
                    if ( endDate ) {
                        if ( isAfter ( endDate, this.maxAllowDate ) ) {
                            this.$emit( 'input', [ dates[ 0 ] ] );
                        } else {
                            this.$emit( 'input', dates );
                        }
                    } else {
                        this.$emit( 'input', dates );
                    }
                }
            },
            maxAllowDate( ) {
                const { startDate, endDate } = this;

                if ( startDate && !endDate ) {
                    return addDays ( startDate, this.maxPeriod - 1 );
                }

                return null;
            },
            lastDateStringFromEventForReadOnly( ) {
                const dates = this.datesStringWithEventsForReadOnly;

                return dates.length ? dates[ dates.length - 1 ] : null;
            },
            datesStringWithEventsForReadOnly( ) {
                if ( !this.maxAllowDate ) {
                    return [ ];
                }
                const endMonth = endOfMonth( addDays( this.maxAllowDate, 1 ) );
                return eachDayOfInterval( addDays( this.maxAllowDate, 1 ), endMonth )
                    .map( item => {
                        return this.$options.filters.date( item, dateConstants.FORMAT.API_DATE );
                    } );
            },
            targetTooltipPosition( ) {
                if ( !this.targetTooltip ) {
                    return { x: 0, y: 0 };
                }

                return this.targetTooltip.getBoundingClientRect( );
            },
            showCurrent() {
                return format( max( [ new Date( ), this.min ] ), 'yyyy-MM-dd' );
            }
        },
        watch: {
            'value': {
                handler( ) {
                    this.applyReadOnlyOnPicker( );
                },
                deep: true,
                immediate: true
            }
        },
        methods: {
            formatCalendarDay ( date ) {
                return format( parseDateString( date ), 'EEEEEE', this.appLocale  );
            },
            onMouseEnterDateDo( date, event ) {
                const { target } = event;
                const position = target.getBoundingClientRect( );

                if ( this.datesStringWithEventsForReadOnly.find( item => item === date ) ) {
                    this.targetTooltip = target;
                    this.targetTooltipPositionX = position.x;
                    this.targetTooltipPositionY = position.y;
                    this.showTooltip = true;
                }

            },
            onMouseLeaveDateDo( ) {
                this.showTooltip = false;
                this.targetTooltip = null;
            },
            onUpdatePickerDo( ) {
                this.applyReadOnlyOnPicker( );
            },
            applyReadOnlyOnPicker( ) {
                this.$nextTick( ( ) => {

                    const tableEl = this.$refs.picker.$refs.wrappedCmp.$refs.table.$el;
                    const daysButtons = tableEl.getElementsByClassName( 'v-btn' );

                    daysButtons.forEach( element => {
                        if ( element.getElementsByClassName( 'v-date-picker-table__events' ).length ) {
                            element.classList.add( 'v-btn-readonly' );
                        } else {
                            element.classList.remove( 'v-btn-readonly' );
                        }
                    } );
                } );
            }
        },
    };
</script>

<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-date-picker-tooltip-disabled-dates {
        @include font-size( 's' );
        @include font-weight( 'regular' );

        background-color: theme-color( 'white' );

        .gst-date-picker-tooltip-disabled-dates__date-picker {
            .v-date-picker-header {
                justify-content: space-evenly;

                .v-date-picker-header__value {
                    flex: unset;
                }
            }

            .v-date-picker-table {
                height: 195px;
            }

            .v-date-picker-table--date .v-btn {
                @include font-size( 's' );
                @include font-weight( 'large' );

                height: 20px;
                width: 26px;
                border-radius: 2px;
            }

            .v-date-picker-table--date .v-btn.v-btn-readonly {
                color: theme-color( 'tertiary' ) !important;
            }

            .v-date-picker-table--month td {
                @include font-size( 's' );
                @include font-weight( 'large' );

                height: 44px;
            }

            .v-date-picker-table__events {
                display: none;
            }
        }

        .gst-date-picker-tooltip-disabled-dates__tooltip-content {
            line-height: line-height( 'l' );
            padding: theme-spacing( 4 );
            background: theme-color( 'white' ) !important;
            color: theme-color( 'tertiary' );
            font-size: font-size( 's' );
        }

        @include mobile-only {
            .gst-add-hotel-reservation-header-date__picker {
                .v-date-picker-header__value {
                    font-size: font-size( 'm' );
                }
            }
        }
    }

    .gst-date-picker-tooltip-disabled-dates__tooltip-content {
        line-height: line-height( 'l' );
        padding: theme-spacing( 4 );
        background: theme-color( 'white' ) !important;
        color: theme-color( 'quaternary' );
        font-size: font-size( 's' );
        box-shadow: 0 2px 6px rgba( theme-color-hex( 'black' ), 0.25 );
        opacity: 1 !important;
    }
</style>
