<template>
    <div class="d-flex flex-column u-position-relative" :style="{ 'flex': 1, 'min-height': 0 }">
        <SelectedHotelCard
            v-if="displaySelectedHotelCard"
            class="ma-2"
            :selected-hotel-reservation="selectedHotelReservation"
            :event="event"
            @change="onChangeSelectedHotelDo" />
        <div v-if="$vuetify.breakpoint.smAndDown" class="gst-event-tickets-sidebar__venue-image-mobile">
            <TicketVenueImage
                :preview="true"
                class="gst-ticket-card-variant-2__image"
                :image-props="{
                    src: venueDetails.defaultMapUrl,
                    height: '120px',
                    width: '140px',
                    contain: true
                }"
                :loading="loadingTickets" />
        </div>
        <EventTicketsFilter
            v-model="ticketFilter"
            :options="filterOptions"
            :show-filters="showAdditionalFilters"
            @click-filter-btn="$emit( 'open-additional-filters' )" />
        <div v-if="!showTicketDetails && !tempSelectedTicket" :style="{'flex': 1, display: 'flex', 'min-height':0}" class="u-position-relative flex-grow-1">
            <v-slide-x-reverse-transition>
                <EventTicketAdditionalFilters
                    v-if="showAdditionalFilters"
                    :value="filter"
                    :options="filterOptions"
                    class="u-width-100 u-height-100 u-pull-front u-position-absolute"
                    @apply="updateFilter"
                    @reset="$emit( 'reset-filter' )"
                    @close="$emit( 'close-additional-filters' )" />
            </v-slide-x-reverse-transition>
            <EventTicketsList
                :event="event"
                :tickets="tickets"
                :total-tickets="totalTickets"
                :tickets-with-offers="ticketsWithOffers"
                :loading-tickets="loadingTickets"
                :selected-ticket="selectedTicket"
                :selected-ticket-detail-info="selectedTicketDetailInfo"
                :sort="sort"
                :bus-event-parent="busEventParent"
                @highlight-ticket="handleHighlightTicket"
                @click-item="onClickItemDo"
                @load-more="onLoadMoreDo" />
        </div>
        <v-slide-x-reverse-transition v-else>
            <EventTicketDetailSidebar
                :ticket="tempSelectedTicket"
                :offers="selectedTicketOffers"
                :event="selectedTicketEvent"
                :quantity="selectedTicketQuantity"
                :bus-event-parent="busEventParent"
                :selected-offers="tempSelectedOffers"
                class="u-width-100 u-height-100 u-pull-front u-position-absolute"
                @update-selected-offers="onUpdateSelectedOffersDo"
                @reached-ticket-offers-quantity="onReachedTicketOffersQuantity"
                @close-ticket-details="$emit( 'close-ticket-details' )" />
        </v-slide-x-reverse-transition>
    </div>
</template>

<script>
    import TicketVenueImage from '@tenant/app/components/images/TicketVenueImage';
    import EventTicketsFilter from './EventTickets/EventTicketsFilter.vue';
    import EventTicketsList from './EventTickets/EventTicketsList.vue';
    import SelectedHotelCard from './_components/SelectedHotelCard.vue';

    const EVENT_TABS = {
        HOTELS: 'hotels',
        TICKETS: 'tickets',
    };

    export default {
        name: 'EventTickets',
        components: {
            EventTicketsFilter,
            EventTicketsList,
            SelectedHotelCard,
            TicketVenueImage,
            EventTicketAdditionalFilters: () => import( './EventTickets/EventTicketAdditionalFilters' ),
            EventTicketDetailSidebar: () => import( './EventTickets/EventTicketDetailSidebar' ),
        },
        props: {
            event: {
                type: Object,
                required: true
            },
            tickets: {
                type: Array,
                default: null,
            },
            totalTickets: {
                type:Number,
                default: 0
            },
            ticketsWithOffers: {
                type: Array,
                default: null
            },
            loadingTickets: {
                type: Boolean,
                default: false
            },
            selectedTicket: {
                type: Object,
                default: null,
            },
            sort: {
                type: Object,
                default: null
            },
            busEventParent: {
                type: Object,
                required: true
            },
            filter: {
                type: Object,
                default: () => {}
            },
            filterOptions: {
                type: Object,
                default: () => {}
            },
            showAdditionalFilters: {
                type: Boolean,
                default: false
            },
            venueDetails: {
                type: Object,
                default: () => {}
            },
            activeTab: {
                type: String,
                default: EVENT_TABS.TICKETS
            },
            selectedHotelReservation: {
                type: Object,
                default: null
            },
            selectedOffers: {
                type: Array,
                default: () => []
            },
            showTicketDetails: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                tempSelectedTicket: null,
                tempSelectedOffers: [ ]
            };
        },
        computed: {
            ticketFilter: {
                get( ) {
                    return this.filter;
                },
                set( value ) {
                    const newFilter = {
                        ...this.filter,
                        ...value
                    };

                    this.updateFilter( newFilter );
                }
            },
            displaySelectedHotelCard() {
                return this.activeTab === EVENT_TABS.TICKETS && !!this.selectedHotelReservation;
            },
            sumQuantityOfferItems( ) {
                return this.tempSelectedOffers.reduce( ( acc, { quantity } ) => {
                    return acc + quantity;
                }, 0 );
            },
            ticketsTotalBasePrice( ) {
                return this.tempSelectedOffers.reduce( ( acc, { offer, quantity } ) => {
                    return acc + offer.basePrice * quantity;
                }, 0 );
            },
            ticketsTotalPrice( ) {
                return this.tempSelectedOffers.reduce( ( acc, { offer, quantity } ) => {
                    return acc + offer.totalPrice * quantity;
                }, 0 );
            },
            isReachedTicketOffersQuantity( ) {
                return this.sumQuantityOfferItems === this.quantity;
            },
            selectedTicketOffers() {
                return this.selectedTicketDetailInfo?.offers || [];
            },
            selectedTicketEvent() {
                return this.selectedTicketDetailInfo?.event || {};
            },
            selectedTicketQuantity() {
                return this.selectedTicketDetailInfo?.quantity || 0;
            },
            quantity( ) {
                return this.filter.quantity;
            },
            selectedTicketDetailInfo( ) {
                const ticket = this.tempSelectedTicket || this.selectedTicket;

                return {
                    ticket: ticket,
                    offers: ticket ? ticket.offers : [],
                    event: this.event,
                    quantity: this.quantity
                };
            },

        },
        watch: {
            isReachedTicketOffersQuantity: {
                handler: function ( value ) {
                    if ( !this.showTicketDetails ) {
                        return;
                    }
                    if ( value ) {
                        this.$emit( 'update-ticket-offers', {
                            ticket: this.tempSelectedTicket,
                            offers: this.tempSelectedOffers,
                            quantity: this.quantity,
                            totalBasePrice: this.ticketsTotalBasePrice,
                            totalPrice: this.ticketsTotalPrice
                        } );
                    } else {
                        this.$emit( 'update-ticket-offers', {
                            ticket: null,
                        } );
                    }

                }
            },
            showTicketDetails: {
                handler: function ( value ) {
                    if ( !value ) {
                        this.tempSelectedTicket = null;
                        this.tempSelectedOffers = [ ];
                        this.$emit( 'highlight-ticket', null );
                    }
                }
            }
        },
        methods: {
            handleHighlightTicket( ticket ) {
                this.$emit( 'highlight-ticket', ticket || this.tempSelectedTicket );
            },
            onClickItemDo( ticket ) {
                const hasTicketChange = ticket !== this.selectedTicket;

                this.$emit( 'highlight-ticket', ticket );
                if ( hasTicketChange ) {
                    this.$emit( 'update-ticket-offers', { ticket: null } );
                    this.tempSelectedTicket = ticket;
                    this.tempSelectedOffers = [ ];
                } else {
                    this.tempSelectedTicket = this.selectedTicket;
                    this.tempSelectedOffers = [ ...this.selectedOffers ];
                }
                this.$emit( 'open-ticket-details' );
            },
            onUpdateSelectedOffersDo( value ) {
                this.tempSelectedOffers = value;
            },
            onLoadMoreDo() {
                this.$emit( 'load-more' );
            },
            updateFilter( value ) {
                this.$emit( 'update-filter', value );
            },
            onChangeSelectedHotelDo() {
                this.busEventParent.$emit( 'change-selected-hotel' );
                this.$emit( 'highlight-hotel-reservation', null );
            },
            onReachedTicketOffersQuantity( value ) {
                this.$emit( 'reached-ticket-offers-quantity', value );
            },
        },
    };
</script>

<style lang="scss">
@import '@scssVariables';
@import '@scssMixins';

.gst-event-tickets-sidebar__venue-image-mobile {
    background: theme-color( 'quinary' );
}
</style>
