<template>
    <component
        :is="config.card"
        ref="hotelCard"
        v-cookies="!$vuetify.breakpoint.mdAndUp && popUpTop"
        :class="config.cardClasses && config.cardClasses"
        v-bind="propsToBind( hotel )" />
</template>

<script>
    import cookiesButtonNudge from '@tenant/app/directives/cookiesButtonNudge.js';

    export default {
        name: 'MapPopupHotelCard',
        directives:{
            cookies: cookiesButtonNudge
        },
        props: {
            mapsApi: {
                type: Object,
                required: true
            },
            map: {
                type: Object,
                required: true
            },
            config: {
                type: Object,
                required: true
            },
            hotel: {
                type: Object,
                required: true
            }
        },
        data( ) {
            return {
                popupInstance: null,
                popUpTop: 0
            };
        },
        watch: {
            'hotel': {
                handler: function ( ) {
                    if ( this.popupInstance ) {
                        this.popupInstance.setLngLat( [ this.hotel.longitude, this.hotel.latitude ] );
                        this.sendPopupBounds( );
                    }
                },
                deep: true
            }
        },
        methods: {
            propsToBind( item ) {
                return this.config.propsToBind ? this.config.propsToBind( item ) : item;
            },
            sendPopupBounds( ) {
                this.$nextTick( ( ) => {
                    if ( this.$refs.hotelCard ) {
                        this.popUpTop = this.$refs.hotelCard.$el.getBoundingClientRect( ).top;
                        this.$emit( 'open', this.$refs.hotelCard.$el.getBoundingClientRect( ) );
                    }
                } );
            }
        },
        mounted( ) {
            const markerHeight = 40;
            const markerRadius = 10;
            const linearOffset = 25;
            const popupOffsets = {
                'top': [ 0, 0 ],
                'top-left': [ 0, 0 ],
                'top-right': [ 0, 0 ],
                'bottom': [ 0, -markerHeight ],
                'bottom-left': [ linearOffset, ( markerHeight - markerRadius + linearOffset ) * -1 ],
                'bottom-right': [ -linearOffset, ( markerHeight - markerRadius + linearOffset ) * -1 ],
                'left': [ markerRadius, ( markerHeight - markerRadius ) * -1 ],
                'right': [ -markerRadius, ( markerHeight - markerRadius ) * -1 ]
            };
            this.popupInstance = new this.mapsApi.Popup( { offset: popupOffsets, maxWidth: '420px', className: 'gst-map-hotel-popup' } )
                .setLngLat( [ this.hotel.longitude, this.hotel.latitude ] )
                .setDOMContent( this.$refs.hotelCard.$el )
                .addTo( this.map )
                .once( 'close', ( ) => {
                    this.$emit( 'closed' );
                } );
            this.sendPopupBounds( );
        },
        beforeDestroy( ) {
            if ( this.popupInstance ) {
                this.popupInstance.remove( );
            }
        }
    };
</script>

<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-map-hotel-popup {
        min-width: 400px;
        z-index: $z-index-map-hotel-popup;

        .mapboxgl-popup-tip {
            display: none;
        }

        .mapboxgl-popup-content {
            padding: 0;
            border-radius: border-radius( 'm' );
        }

        .mapboxgl-popup-close-button {
            top: 0;
            right: 0;
            height: 44px;
            width: 44px;
            color: theme-color( 'tertiary' );
            font-size: font-size( 'xxxl' );
            font-weight: bold;

            &:hover {
                background: none;
            }
        }
    }

    @include mobile-only {
        .gst-map-hotel-popup {
            position: absolute;
            top: auto;
            left: 10px;
            bottom: 10px;
            width: calc( 100% - 20px );
            max-width: 100% !important;
            min-width: auto;
            transform: none !important;

            .mapboxgl-popup-content {
                width: 100%;
            }
        }
    }
</style>