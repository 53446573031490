<template>
    <BaseButton
        text
        color="white"
        :placeholder="placeholder"
        :class="[isAnimated && 'gst-pulse-animation']"
        @click="$emit('click')" />
</template>

<script>
    import BaseButton from '@core/shared/components/buttons/BaseButton.vue';

    export default {
        name: 'PulseButton',
        components: {
            BaseButton
        },
        props: {
            isAnimated: {
                type: Boolean,
                default: false
            },
            placeholder: {
                type: String,
                default: ''
            }
        },
        emits: [ 'click' ]
    };
</script>

<style lang="scss" scoped>
    @import "@scssVariables";

    .gst-pulse-animation {
        animation: 0.75s pulseAnimation ease-out infinite;
    }
</style>
